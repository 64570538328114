import React,{Component} from 'react';
import {
    ActivityIndicator
} from 'react-native';
import {themeColor} from "../styles/Styles";
export default class AppLoading extends Component{
    render(){
        const {size,color,animating}=this.props;
        return(
            <ActivityIndicator animating={animating}  size={size||'large'} color={color||themeColor} {...this.props}/>
        )
    }
}