
import React,{Component}from 'react';
import { 
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Platform
} from 'react-native';
import globalCss from '../styles/Styles';
import CustomText from "../components/CustomText";
// import ImageLoader from '../components/ImageLoader';
class WebFooter extends Component{
    state={
        inpuxBoxWidth:0,
        email:'',
        signUpButtonWidth:0
    }
    render(){
        const {textStyle}=this.props;
        return(
            <View style={{
                flex:1.5,
                backgroundColor:'#323380',
                paddingTop:15,
                alignItems:'center',justifyContent:'center'
            }}>
                {/* <View style={{
                justifyContent:'center',
                alignItems:'center',
                }}>
                <Text style={{
                    fontWeight:'bold',
                    color:'#fff',
                    fontSize:16
                }}>NEWSLETTER</Text>
                </View>
                <View style={{
                justifyContent:'center',
                alignItems:'center',
                paddingVertical:5
                }}>
                <Text style={{
                    //fontWeight:'bold',
                    color:'#fff',
                    fontSize:9,
                }}>GET  LATEST  NEWS  </Text>
                <Text style={{
                    //fontWeight:'bold',
                    color:'#fff',
                    fontSize:9
                }}>NO  SPAM.  MUCH  INFO.  WOW</Text>
                </View>
                <View style={{
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'row',
                }}>
                <View onLayout={(e)=>{this.setState({inpuxBoxWidth:e.nativeEvent.layout.width})}} style={{
                    borderRadius:0,
                    borderColor:'#978CBA',
                    borderWidth:1,
                    paddingVertical:4,
                    paddingHorizontal:5,
                    //elevation:5
                }}>
                    <TextInput 
                    value={this.state.email}
                    onChangeText={email=>this.setState({email})}
                    placeholder={'Type your email address'}
                    
                    style={{
                        ...Platform.select({
                        web: {
                            outlineColor:"#ffffff00"
                        }
                        }),
                        color:'#fff',
                        fontStyle:'italic'
                    }}
                    />
                </View>
                <TouchableOpacity onLayout={(e)=>{this.setState({signUpButtonWidth:e.nativeEvent.layout.width})}} style={{
                    marginLeft:30,
                    backgroundColor:'#ffffff',
                    paddingVertical:5,
                    paddingHorizontal:5,
                    flexDirection:'row',
                    justifyContent:'center',
                    alignItems:'center',
                    // /backgroundColor:'red'
                }}>
                    <Text style={{
                    fontSize:12
                    }}>
                    Signup
                    </Text>
                    <Image style={{
                    width:12,
                    height:12,
                    resizeMode:'contain',
                    marginLeft:15
                    }}
                    source={require('../images/next.png')}/>
                </TouchableOpacity>
                </View> */}
                <View style={{
                //flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
                paddingVertical:6,
                }}>
                <View style={{
                    flexDirection:'row',
                    width:250,
                    //width:this.state.inpuxBoxWidth+this.state.signUpButtonWidth+30,
                    justifyContent:'space-between',
                }}>
                    <CustomText style={textStyle} name={'home'}/>
                    <CustomText style={textStyle} name={'about'}/>
                    <CustomText style={textStyle} name={'faq'}/>
                    <CustomText style={textStyle} name={'blog'}/>
                    <CustomText style={textStyle} name={'privacy_policy'}/>
                </View>
                </View>
                <View style={{
                justifyContent:'center',
                alignItems:'center',
                paddingVertical:8
                }}>
                <View style={{
                    flexDirection:'row',
                    justifyContent:'space-between',
                    width:200
                    //width:(this.state.inpuxBoxWidth+this.state.signUpButtonWidth)-((this.state.inpuxBoxWidth+this.state.signUpButtonWidth)/4)
                }}>
                    <TouchableOpacity>
                    <Image style={{width:15,height:15,resizeMode:'contain'}} source={require('../images/instagram.png')}/>
                    </TouchableOpacity>
                    <TouchableOpacity>
                    <Image style={{width:15,height:15,resizeMode:'contain'}} source={require('../images/facebook.png')}/>
                    </TouchableOpacity>
                    <TouchableOpacity>
                    <Image style={{width:15,height:15,resizeMode:'contain'}} source={require('../images/twitter.png')}/>
                    </TouchableOpacity>
                    <TouchableOpacity>
                    <Image style={{width:15,height:15,resizeMode:'contain'}} source={require('../images/pinterest.png')}/>
                    </TouchableOpacity>
                </View>
                </View>
                <View style={{
                justifyContent:'center',
                alignItems:'center',
                paddingVertical:5
                }}>
                <CustomText style={{
                    fontSize:10,
                    color:'#fff',
                    ...globalCss.bodyFontFamily
                }}
                name={'kuwait_oil_company_workers_syndicate'}
                />
                <CustomText style={{
                    fontSize:10,
                    color:'#fff',
                    ...globalCss.bodyFontFamily
                }}
                 name={'_kocunion_2020_all_rights'}   
                />
                </View>
                
            </View>
        )
    }
  }

export {WebFooter}