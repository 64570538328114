
import React,{Component}from 'react';
import { 
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Platform
} from 'react-native';
import {connect} from 'react-redux';
import {actions} from '../store';
import {localStore,_checkStatus, _goto, _parseDate} from '../service';
import {_setPreviousRoute, _setPreviousProps, _setIfSomeThingToVerifyAfterLoggedIn} from '../constants';
import { Actions } from 'react-native-router-flux';
import Logo from '../images/logo.js';
import { _getUserJobType } from '../DrawerMenu';
// import ImageLoader from '../components/ImageLoader';
//import {DrawerMenu} from "../DrawerMenu";
class WebHeader extends Component{
    _goto=(scene)=>{
        let state='/'+scene;
        if((this.props.location&&this.props.location.pathname==state)||(this.props.history&&this.props.history.location&&this.props.history.location.pathname==state)){
            return;
        }else{
            this.props.history.push(state);
        }
    }
    _checkUserStatus=async(route)=>{
        let resp=await localStore.get('accessToken');
        if(_checkStatus(resp)){
            this._goto(route);
        }else{
            _setPreviousRoute(route);
            this._goto('login');
            //this.props.history.push('/login');
        }
    }
    _checkUserStatusForCalender=async(route)=>{
        let resp=await localStore.get('accessToken');
        if(_checkStatus(resp)){
                let resp=await _getUserJobType();
                if(resp===true){
                    _goto(this.props,'calenderdetails',null,{id:_parseDate(new Date())})
                   // Actions['calenderdetails'].call();
                }else{
                    _goto(this.props,route);
                }
        }else{
            // let resp=await _getUserJobType();
            // if(resp===true){
            //     _setPreviousProps({id:_parseDate(new Date())})
            //     _setPreviousRoute('calenderdetails');
            // }else{
            //     _setPreviousRoute(route);
            // }
            _setIfSomeThingToVerifyAfterLoggedIn(_getUserJobType,{scene:'calenderdetails',props:{id:_parseDate(new Date())}},{scene:route,props:{}});
            _goto(this.props,'login');
        }
    }
    render(){
        const {height}=this.props;
        return(
        <View style={{
            flex:1,
            backgroundColor:'#fff',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <View style={{
                marginRight:'auto'
            }}>
            <TouchableOpacity onPress={()=>this._goto('home')} style={{marginLeft:20}}>
                {/* <Image style={{
                height:height/16,
                width:70,
                resizeMode:'contain'
                }} source={require('../images/finelogo.png')}/> */}

                <Logo
                    height={height/16}
                    width={70} 
                />
            </TouchableOpacity>
            </View>
            <View style={{
                marginLeft:'auto',
                flexDirection:'row',
                alignItems:'center'
            }}>
            {/* <TouchableOpacity onPress={()=>this._checkUserStatusForCalender('calender')} style={{
                marginHorizontal:8
            }}>
                <Image style={{
                height:height/24,
                width:20,
                resizeMode:'contain'
                }} source={require('../images/Date-of-Joining.png')}/>
            </TouchableOpacity> */}
            <TouchableOpacity onPress={()=>this._checkUserStatus('profile')} style={{
                marginHorizontal:8
            }}>
                <Image style={{
                height:height/24,
                width:20,
                resizeMode:'contain'
                }} source={require('../images/user_web.png')}/>
            </TouchableOpacity>
            <TouchableOpacity style={{
                marginHorizontal:8
            }}
                onPress={this.props.changeVisibility}
            >
                <Image style={{
                height:height/60,
                width:20
                }} source={require('../images/menu.png')}/>
            </TouchableOpacity>
            </View>
        </View>
        )
    }
  }
// function mapStatsToProps(state){
//     return {
//         loginPopUp:state.loginPopUp,
//     }
// }
// function mapDispatchToProps(dispatch){
//     return{
//         toggleLoginUp:()=>dispatch(actions.showLogin())
//     }
// }
//const WebHeader=connect(mapStatsToProps,mapDispatchToProps)(WebHeaders);
export {WebHeader};