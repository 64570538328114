import React,{Component } from "react";
import { View,Text,Image,ScrollView,StyleSheet,Platform,TouchableOpacity,FlatList} from "react-native";
import globalCss,{winWidth} from "./styles/Styles";
import {actions} from './store';
import {connect} from 'react-redux';
import { _parseDate, api } from "./service";
import AppLoading from "./components/AppLoading";
import CustomText from "./components/CustomText";
import { t } from "./langs";
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
const css=StyleSheet.create({
    main:{
        flex:1,
        backgroundColor:'#EEEDF0'
    },tabParentBar:{
        height:45,
        width:'100%',
        backgroundColor:'#24255E',
        justifyContent:'space-evenly',
    },tabBar:{
        flex:1,
        //backgroundColor:'red',
        flexDirection:'row',
    },tabButton:{
        flex:1,
        justifyContent:'center',
        alignItems:'center'
    },tabButtonText:{
        color:'#fff'
    },tabPage:{
        width:winWidth
    },tabActive:{
        backgroundColor:'#EEEDF0'
    },tabTextActive:{
        color:'#24255E'
    },card:{
        width:winWidth-20,
        height:140,
        backgroundColor:'#fff',
        ...globalCss.shadow,
        borderRadius:10,
        margin:10,
        padding:10,
        justifyContent:'center'
    }
})
class VacanciesPage extends Component{
    constructor(props){
        super(props);
        this.state={
            //vacancies:[],
            vacancies:this.props.allVacancies.data,
            loading:this.props.allVacancies.data.length==0,
            tabPages:[
                {title:'status_update',active:true,showName:t('status')},
                {title:'transfer',active:false,showName:t('transfer')}
            ],initLoader:this.props.allVacancies.data.length==0
        }
        this.scrollItemRef=[];
        this.callLoadMore=false;
        this.current_page=1;
    }
    componentDidMount(){
        this.props._getAllVacancies();
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.allVacancies!==prevState.vacancies){
            return { allVacancies: nextProps.allVacancies};
        }else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.allVacancies!==this.props.allVacancies){
            const {allVacancies}=this.props;
            this.setState({vacancies:allVacancies.data,loading:false,initLoader:false},()=>{
                this._checkIfToRenderNextPage(allVacancies);
            });
          }
    }
    _loadMoreVacancies=async(v)=>{
        console.log('load more called vacancies',v,this.callLoadMore,this.current_page);
        const {vacancies}=this.state;
        if(this.callLoadMore){
            this.setState({loading:true});
            let resp=await api.get('vacancies?page='+(this.current_page+1));
            if(_checkStatus(resp)){
                resp=resp.value.vacancies;
                resp.data=resp.data.map((item)=>{
                    return{
                        id:item.id,
                        expiry_date:_parseDate(item.expiry_date),
                        created_at:_parseDate(item.created_at),
                        number_of_vacancies:item.number_of_vacancies,
                        from_department:item.from_department,
                        to_department:item.to_department,
                        type:item.type
                    }
                });
                this.setState({vacancies:[...vacancies,...resp.data],loading:false},()=>{
                    this._checkIfToRenderNextPage(resp);
                });
            }
        }else return;

    }
    _checkIfToRenderNextPage=(dataObj)=>{
        if(dataObj.next_page_url!=null){
            this.callLoadMore=true;
            this.current_page=dataObj.current_page;
        }else{
            this.callLoadMore=false;
        }
    }
    _scrollEnd=(e,index)=>{
        let tabPages;
        if(e!=undefined){
            this.currentItemIndex=this.scrollItemRef.indexOf(e.nativeEvent.contentOffset.x);
        }else if(index!=undefined){
            this.currentItemIndex=index;
            this._handleScroll();
        }else{
            return;
        }
        tabPages=this.state.tabPages;
        for(let i=0;i<tabPages.length;i++){
            if(i==this.currentItemIndex){
                tabPages[i].active=true;
            }else{
                tabPages[i].active=false;
            }
        }
        this.setState({tabPages});
    }
    _handleScroll=()=>{
        this.scroller.scrollTo({
            x: this.scrollItemRef[this.currentItemIndex],
            y: 0,
            animated: true,
        });
        this.xPositionOfScroll=this.scrollItemRef[this.currentItemIndex];
    }
    _renderVacancyCard=(card,index)=>{
        return(
           <View key={index+''} style={css.card}>
               <View style={{flexDirection:'row'}}>
                    <CustomText style={{marginRight:20,color:'#24255E',paddingVertical:4,...globalCss.headingFontFamily}}
                        name={'number_of_vacancies'}
                    />
                    <CustomText 
                        style={{backgroundColor:'#EEEDF0',...globalCss.bodyFontFamily,color:'#24255E',paddingVertical:0,paddingHorizontal:8,borderRadius:5,marginTop:1}}
                        value={card.number_of_vacancies}
                    />
               </View>
               <View style={{flexDirection:'row',paddingBottom:4}}>
                    <CustomText style={{marginRight:20,...globalCss.bodyFont}}
                        name={'expiry_date'}
                    />
                    <CustomText style={globalCss.bodyFont} value={card.expiry_date}/>
               </View>
               <CustomText style={{color:'#24255E',...globalCss.headingFontFamily}}
                    name={'from_department'}
                />
                <CustomText style={{...globalCss.bodyFont,fontSize:12,paddingBottom:4}}
                   value= {card.from_department}
                />
                <CustomText style={{color:'#24255E',...globalCss.headingFontFamily}}
                    name={'to_department'}
                />
                <CustomText style={{fontSize:12,...globalCss.bodyFont}}
                    value={card.to_department}
                />
           </View>
        )
    }
    _renderTabPage=(item,index)=>{
        const {vacancies,loading}=this.state;
        const vacancy=vacancies.filter((v)=>{return v.type==item.title});
        // return(
        //     <ScrollView
        //         horizontal={false}
        //         key={index+''}
        //         onLayout={event => {
        //             this.scrollItemRef[index] = Platform.OS=='web'?index*(event.nativeEvent.layout.width):event.nativeEvent.layout.x
        //         }}
        //         contentContainerStyle={css.tabPage}
        //         showsVerticalScrollIndicator={false}
        //     >
        //         {
        //            vacancy.map((card,index)=>{
        //                return this._renderVacancyCard(card,index)
        //            })
        //         }
        //     </ScrollView>
        // )
        return(
            <FlatList
                showsVerticalScrollIndicator={true}
                onLayout={event => {
                    this.scrollItemRef[index] = Platform.OS=='web'?index*(event.nativeEvent.layout.width):event.nativeEvent.layout.x
                }}
                data={vacancy}
                onEndReached={(v)=>{this._loadMoreVacancies(v)}}
                onEndReachedThreshold={0.5}
                contentContainerStyle={{flexGrow:1}}
                ListFooterComponent={loading?<AppLoading />:null}
                renderItem={({item})=>{
                    return this._renderVacancyCard(item,index)
                }}
                keyExtractor={(item)=>{item.id+''}}
            />
        )
    }
    render(){
        const {tabPages,initLoader}=this.state;
        if(initLoader){
            return(
                <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
                    <AppLoading/>
                </View>
            )
        }else return(
            <View style={css.main}>
                <View style={css.tabParentBar}>
                   <View style={css.tabBar}>
                       {
                           tabPages.map((tab,index)=>{
                            return (
                                <TouchableOpacity disabled={tab.active} key={index+''} onPress={()=>{this._scrollEnd(undefined,index)}} style={[css.tabButton,tab.active?css.tabActive:{}]}>
                                    <Text style={[css.tabButtonText,tab.active?css.tabTextActive:{},globalCss.headingFontFamily]}>{tab.showName}</Text>
                                </TouchableOpacity>
                            )
                           })
                       }
                   </View>
                </View>
                <ScrollView
                    contentContainerStyle={{flexGrow:1}}
                    ref={scroller => { this.scroller = scroller}}
                    horizontal={true}
                    pagingEnabled={true}
                    onMomentumScrollEnd={(e)=>this._scrollEnd(e)}
                    showsHorizontalScrollIndicator={false}
                >
                    {
                        tabPages.map((item,index)=>{
                            return this._renderTabPage(item,index)
                        })
                    }
                </ScrollView>
            </View>
        )
    }
}
function mapStateToProps(state){
    const {baseReducer}=state
    return {
        allVacancies:baseReducer.allVacancies,
    }
}
function mapDispatchToProps(dispatch){
    return{
        _getAllVacancies:()=>dispatch(actions._getAllVacancies())
    }
}
const VacanciesView=connect(mapStateToProps,mapDispatchToProps)(VacanciesPage);
export default class Vacancies extends Component{
    render(){
        if(Platform.OS!='web')
            return (<VacanciesView/>)
        else return (<Page {...this.props} DynamicComponent={VacanciesView}/>)
    }
}