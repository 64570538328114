import React,{Component } from "react";
import { View,Text,Image,ScrollView,StyleSheet,Platform,FlatList} from "react-native";
import globalCss from "./styles/Styles";
//import {api,_checkStatus,_parseDate,_parseHtml} from './service';
//import LoginPopUp from './components/LoginPopUp';
import {actions} from './store';
import {connect} from 'react-redux';
import AppLoading from "./components/AppLoading";
import { api, _parseDate, _parseHtml } from "./service";
import ImageLoader from "./components/ImageLoader";
import CustomText from "./components/CustomText";
import { _appCurrentLang } from "./constants";
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
const css=StyleSheet.create({
    main:{
        flex:1,
        backgroundColor:'#EEEDF0',
    },itemCont:{
        margin:10,
        backgroundColor:'#fff',
        borderRadius:10,
        padding:10,
        flexDirection:'row',
        ...globalCss.shadow,
        //flex:1,
        justifyContent:'center',
        alignItems:'center'
    },notiIcon:{
        height:20,
        width:20,
        resizeMode:'contain',
    },imgCont:{
        justifyContent:'center',
        alignItems:'center',
        flex:1
    },textCont:{
        flex:2.8,
        paddingHorizontal:5
    },title:{
        fontSize:16,
        textAlign:'left',
        ...globalCss.headingFont
    },description:{
        //opacity:0.5,
        fontSize:12,
        textAlign:'left',
        ...globalCss.bodyFont
    },dateCont:{
        //opacity:0.5,
        flex:1.2,
        marginBottom:'auto',
        ...globalCss.bodyFont
    },date:{
        fontSize:12
    },imgRound:{
        height:40,
        width:40,
        backgroundColor:'#EEEDF0',
        borderRadius:20,
        justifyContent:'center',
        alignItems:'center'
    }
})
class NotificationPage extends Component{
    constructor(props){
        super(props);
        this.state={
            loading:this.props.allNotifications.data.length==0,
            notifications:this.props.allNotifications.data
        }
        this.callLoadMore=false;
        this.current_page=1;
    }
    async componentDidMount(){
        this.props._getAllNotifications();
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.allNotifications!==prevState.notifications){
            return { allNotifications: nextProps.allNotifications};
        }else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.allNotifications!==this.props.allNotifications){
            const {allNotifications}=this.props;
            this.setState({notifications:allNotifications.data,loading:false},()=>{
                this._checkIfToRenderNextPage(allNotifications);
            });
          }
    }
    _loadMoreNotifications=async(v)=>{
        console.log('load more called notifications',v,this.callLoadMore,this.current_page);
        const {notifications}=this.state;
        if(this.callLoadMore){
            this.setState({loading:true});
            let resp=await api.get('notifications?page='+(this.current_page+1));
            if(_checkStatus(resp)){
                resp=resp.value.notifications;
                resp.data=resp.data.map((item)=>{
                    return{
                        title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                        date:_parseDate(item.created_at),
                        image:item.image||_testImage,
                        description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                        id:item.id
                    }
                });
                this.setState({notifications:[...notifications,...resp.data],loading:false},()=>{
                    this._checkIfToRenderNextPage(resp);
                });
            }
        }else return;

    }
    _checkIfToRenderNextPage=(dataObj)=>{
        if(dataObj.next_page_url!=null){
            this.callLoadMore=true;
            this.current_page=dataObj.current_page;
        }else{
            this.callLoadMore=false;
        }
    }
    render(){
        const {notifications,loading}=this.state;
        return(
            <View style={css.main}>
                <FlatList
                    showsVerticalScrollIndicator={true}
                    data={notifications}
                    onEndReached={(v)=>{this._loadMoreNotifications(v)}}
                    onEndReachedThreshold={0.5}
                    contentContainerStyle={{flexGrow:1}}
                    ListFooterComponent={loading?<AppLoading/>:null}
                    keyExtractor={item=>item.id+''}
                    renderItem={({item})=>{
                        return(
                            <View  style={css.itemCont}>
                                <View style={css.imgCont}>
                                    <View style={css.imgRound}>
                                        {item.image==undefined||item.image.length==0
                                            ?<Image style={css.notiIcon} source={require('./images/notifactionbell.png')}/>
                                            :<ImageLoader style={css.notiIcon} source={{uri:item.image}}/>
                                        }
                                    </View>
                                </View>
                                <View style={css.textCont}>
                                    <CustomText style={css.title} value={item.title}/>
                                    <CustomText style={css.description} value={item.description}/>
                                </View>
                                <View style={css.dateCont}>
                                    <CustomText style={css.date} value={item.date}/>
                                </View>
                            </View>
                        )
                    }}
                    keyExtractor={(item)=>{item.id+''}}
                />
            </View>
        )
    }
}
function mapStateToProps(state){
    const {baseReducer}=state
    return {
        //loginPopUp:state.loginPopUp,
        allNotifications:baseReducer.allNotifications
    }
}
function mapDispatchToProps(dispatch){
    return{
        //toggleLoginUp:()=>dispatch({'type':'toggleLoginUp'})
        _getAllNotifications:()=>dispatch(actions._getAllNotifications()),
    }
}
const NotificationView=connect(mapStateToProps,mapDispatchToProps)(NotificationPage);
export default class Notification extends Component{
    render(){
        if(Platform.OS!='web')
            return (<NotificationView/>)
        else return (<Page {...this.props} DynamicComponent={NotificationView}/>)
    }
}