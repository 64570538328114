import React,{Component}from 'react';
import { 
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Platform,
  ImageBackground,
  ActivityIndicator
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';
import  OtpInputs  from "./components/OtpInputs";
import  globalCss,{winHeight,winWidth} from './styles/Styles';
import {api,_checkStatus, localStore} from './service';
import CustomText from './components/CustomText';
import { t } from './langs';
import  AppLoading from './components/AppLoading';
import MobileNav from './components/mobileNav';
const css=StyleSheet.create({
    backGrd:{
        flex:1
    },top:{
        flex:2
    },bottom:{
        flex:5,
        ...Platform.select({
            web:{
                paddingHorizontal:30,
                paddingBottom:30,
                paddingTop:20
            },default:{
                paddingTop:15,
                paddingHorizontal:15,
                paddingBottom:20
            }
        }),
    },formCard:{
        width:'100%',
        height:'100%',
        borderRadius:20,
        backgroundColor:'#ffffff',
        paddingTop:50,
        paddingHorizontal:50,
        paddingBottom:50
    },nxtBtn:{
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#2A296A',
        width:'100%',
        height:(((winHeight*3)/5)-35)/8,
        //marginTop:20
    },btnText:{
        color:'#fff',
        ...globalCss.bodyFontFamily
    },inputBoxCont:{
        borderBottomColor:'#BFBCCA',
        borderBottomWidth:2,
        width:'100%',
        height:(((winHeight*3)/5)-35)/6,
        marginBottom:20
    },input:{
        ...globalCss.bodyFont,
        ...Platform.select({
            web: {
                outlineColor:"#ffffff00",
                marginTop:'auto',
                marginBottom:8,
            }
        }),
        
    },lable:{
        //color:'#BFBCCA',
        ...globalCss.bodyFont,
        fontSize:14
    },titleCont:{
        flex:1,
        justifyContent:'flex-end',
        alignItems:'center'
    },title:{
        color:'#2A296A',
        fontWeight:'bold',
        fontSize:24,
        ...globalCss.bodyFontFamily
    },iconsCont:{
        flex:Platform.select({web:3,default:1}),
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'row',
    },icon:{
        height:Platform.select({
            web:((((winHeight*2)/5)/2)/2),default:((((winHeight*2)/5)/2)/3)
        }),
        width:(winWidth/10),
        resizeMode:'contain'
    },iconImgCont:{
        height:Platform.select({
            web:((((winHeight*2)/5)/2)/2),default:((((winHeight*2)/5)/2)/3)
        }),
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row',
    },line:{
        width:winWidth/6,
        height:1,
        backgroundColor:'#BFBCCA'
    },iconTitle:{
        fontSize:12,
        marginLeft:Platform.OS=='web'?10:0,
        marginTop:Platform.OS=='web'?5:0
    },emailVerificationIcon:{
        height:78,
        width:78,
        resizeMode:'contain',
        marginBottom:30
    },verificationText:{
        textAlign:'center',
        fontSize:20,
        ...globalCss.bodyFont
    },verificationCont:{
        alignItems:'center'
    },scrollCont:{
        flexGrow:1
    },emailVerifiedIcon:{
        height:78,
        width:78,
        resizeMode:'contain',
        alignSelf:'center'
    },verifiedText:{
        //fontWeight:'bold',
        ...globalCss.headingFont,
        fontSize:18,textAlign:'center'
    },verifiedIconCont:{
        marginBottom:30
    },inputPasswordTxt:{
        marginBottom:30,
        textAlign:'center',
        fontSize:12,
        ...globalCss.bodyFont
    },uploadDefCont:{
        width:120,
        height:120,
        margin:12,
        alignSelf:'center'
    },uploadDefaultIcon:{
        width:'100%',
        height:'100%',
        borderRadius:60,
        backgroundColor:'#F1F1F3'
    },addPhotoIconCont:{
        position:'absolute',
        right:-10,
        top:20,
    },addPhotoIcon:{
        width:40,
        height:40,
        resizeMode:'contain',
        borderRadius:20
    },soNiceCont:{
        marginBottom:20,
    },soNiceText:{
        fontSize:12,
        textAlign:'center',
        ...globalCss.bodyFont
    },otpIcon:{
        height:78,
        width:78,
        resizeMode:'contain',
        margin:10,
        alignSelf:'center'
    },verifyOtpTxt:{
        textAlign:'center',
        fontSize:16,
        //fontWeight:'bold',
        marginBottom:5,
        ...globalCss.headingFont
    },entermobNumTxt:{
        fontSize:12,
        textAlign:'center',
        color:'#98A1A8',
        ...globalCss.bodyFontFamily
        //marginBottom:20
    },countryIcon:{
        height:20,
        width:20,
        resizeMode:'contain',
        marginHorizontal:3
    },codeText:{
        //color:'#C6CACF',
        //fontWeight:'bold',
        ...globalCss.bodyFont
    },countryCodeCont:{
        borderBottomColor:'#BFBCCA',
        borderBottomWidth:2,
        flexDirection:'row',
        flex:1.5,
        marginRight:10,
        alignSelf:'flex-end',
        paddingBottom:5,
        //backgroundColor:'blue'
    },mobileNumberInput:{
        ...globalCss.bodyFont,
        ...Platform.select({
            web: {
                outlineColor:"#ffffff00",
                marginTop:'auto',
                //marginBottom:8,
            }
        }),
        width:'100%',
        //borderBottomColor:'#BFBCCA',
        //borderBottomWidth:2,
        //flex:3,
        alignSelf:'flex-end',
        //color:'#C6CACF',
        //fontWeight:'bold',
        //backgroundColor:'red'
    },mobileNumberWholeCont:{
        flexDirection:'row',
       // alignItems:'center',
       // borderBottomColor:'',
        borderBottomWidth:0,
        //justifyContent:'center',
    },mobileNumAtOtp:{
        color:'#2A296A',
        //fontWeight:'bold',
        fontSize:12,
        ...globalCss.headingFont
    },resendOtpCont:{
        flexDirection:'row',
        paddingVertical:5,
        //alignItems:'center'
        justifyContent:'center'
    },didNotText:{
        color:'#98A1A8',
        fontSize:12,
        ...globalCss.bodyFontFamily
    },sendAgainText:{
        color:'#2A296A',
        fontSize:12,
        ...globalCss.bodyFontFamily
    },congratsView:{
        backgroundColor:'rgba(0,0,0,0.5)',
        flex:1,
        position:'absolute',
        top:0,
        bottom:0,
        right:0,
        left:0,
        zIndex:2,
        paddingHorizontal:winWidth/8,
        paddingVertical:(winHeight/4)
    },congratsModal:{
        width:'100%',
        height:'100%',
        backgroundColor:'#fff',
        borderRadius:20
    },congratsImgCont:{flex:5,justifyContent:'center',alignItems:'center'},
    congratsImg:{
        width:'100%',
        height:'100%',
        resizeMode:'contain'
    },congratsText:{
        color:'#2A296A',
        padding:20,
        textAlign:'center',
        //fontWeight:'bold',
        flex:1.5,
        fontSize:18,
        ...globalCss.headingFontFamily
    },congratsButton:{
        flex:1.5,
        backgroundColor:'#2A296A',
        justifyContent:'center',
        alignItems:'center',
        borderBottomLeftRadius:20,
        borderBottomRightRadius:20
    },congratsButtonText:{
        color:'#fff',
        ...globalCss.bodyFontFamily
    },navBar:{
        position:'absolute',
        top:0,
        left:0,
        flex:1
    }
})
export default class Registraton extends Component{
    constructor(){
        super();
    }
    componentDidMount(){
        (Platform.OS=='ios'||Platform.OS=='android')&&this.getPermissionAsync();
    }
    
    state={
        icons:[
            {
                activeIcon:require('./images/verification.png'),
                inactiveIcon:require('./images/verification_inactive.png'),
                active:true,
                title:t('verification')
            },{
                activeIcon:require('./images/email_active.png'),
                inactiveIcon:require('./images/email_inactive.png'),
                active:false,
                title:t('email_id')
            },{
                activeIcon:require('./images/photo_active.png'),
                inactiveIcon:require('./images/photo_inactive.png'),
                active:false,
                title:t('photo')
            },{
                activeIcon:require('./images/phone_active.png'),
                inactiveIcon:require('./images/phone_inactive.png'),
                active:false,
                title:t('phone')
            }
        ],views:{
            initial:true,
            kocIdSubmission:false,
            password:false,
            photo:false,
            phoneVerification:false,
            otpScreen:false,
            congratsView:false
        },
        kocId:'',
        civilId:'',
        password:'',
        confirmPassword:'',
        image:undefined,
        mobileNumber:'',
        otp:'',
        appLoader:false,
        permission:false
    }
    _user={};
    whichIconToshow=(icon)=>{
        let temp=this.state.icons;
        this.state.icons.forEach((v,index)=>{
            if(v.title==icon){
                temp[index].active=true
            }else{
                temp[index].active=false
            }
        });
        this.setState({icons:temp});
    }
    goIfUserVerifiedTheMail=(view)=>{
        setTimeout(()=>{
            this.goNext(view);
            this.whichIconToshow('Email ID');
        },2000)
    }
    _sentMail=async(id)=>{
        let result=await api.get('users/'+id+'/email_token');
        //console.log(result);
        if(result.status){
            result=result.value;
            if(Object.keys(result).indexOf('error')==-1){
                this._checkIsMailVerified(result.email_verification_token);
            }else{

            }
        }
    }
    _checkIsMailVerified=async()=>{
        const {civilId,kocId}=this.state;
        let result=await api.post('users/verification',{
            civil_id:civilId,
            koc_id:kocId
        });
        //console.log(result);
        if(result.status){
            result=result.value;
            this._user=result.user;
            if(Object.keys(result).indexOf('error')==-1){
                if(this._user.email_verified==0){
                    setTimeout(()=>{
                        this._checkIsMailVerified();
                    },5000);
                }else if(this._user.email_verified==1){
                    this.goNext('password');
                    this.whichIconToshow('Email ID');
                    return;
                }
            }else{
                alert(result.error);
                return;
            }
        }
    }
    _sendOTP=async(view)=>{
        const {civilId,kocId,mobileNumber}=this.state;
        console.log(mobileNumber)
        let result=await api.post('users/verification/send_mobile_otp',{
            civil_id:civilId,
            koc_id:kocId,
            mobile_number:mobileNumber
        });
        console.log(result);
        // debugger;
        // if(_checkStatus(result)){
        //     debugger;
        //     if(view!=undefined){
        //         this.setState({appLoader:false},()=>{
        //             this.goNext(view);
        //         });
        //     }
        // }else{
        //     debugger;
        //     if(view!=undefined){
        //         debugger;
                if(result.value.error==0){
                    this.setState({appLoader:false},()=>{
                        if(view!=undefined){
                            this.goNext(view);
                        }
                    });
                }
                this.setState({appLoader:false});
        //     }
        // }
    }
    _verifyOTP=async(view)=>{
        const {civilId,kocId,otp}=this.state;
        this.setState({appLoader:true});
        let result=await api.post('users/verification/verify_otp',{
            civil_id:civilId,
            koc_id:kocId,
            otp:otp
        });
        console.log(result);
        if(_checkStatus(result)){
            this.setState({appLoader:false},async()=>{
                let setToken=await localStore.set('accessToken',result.value.accessToken);
                let setUserId=await localStore.set('userId',result.value.user.id+'');
                if(_checkStatus(setToken)&&_checkStatus(setUserId)){
                    this.goNext(view);
                }else{
                    alert(t('error'));
                }
            }); 
        }else{
            this.setState({appLoader:false});
        }
    }
    goNext=async (view,check)=>{
        const {civilId,kocId}=this.state;
        if(view=='kocIdSubmission'&&check===true){
            let result=await api.post('users/verification',{
                    civil_id:civilId,
                    koc_id:kocId
            });
            console.log(result);
            this.setState({appLoader:false});
            if(result.status){
                result=result.value;
                this._user=result.user;
                if(Object.keys(result).indexOf('error')==-1){
                    if(this._user.email_verified==1&&this._user.mob_no_verified==1){
                        this.goNextScene();
                        return;
                    }else if(this._user.email_verified==1&&this._user.mob_no_verified==0){
                        this.goNext('password');
                        this.whichIconToshow('Email ID');
                        return;
                    }else if (this._user.email_verified==0&&this._user.mob_no_verified==0){
                        this.goNext('kocIdSubmission',false);
                        this._sentMail(this._user.id);
                        return;
                    }
                }else {
                    alert(result.message||result.error);
                     return;
                };
            }else return;
        }
        let temp=this.state.views;
        Object.keys(temp).forEach((key)=>{
            if(key==view){
                temp[key]=true
            }else temp[key]=false
        })
        this.setState({views:temp},()=>{
            if(view=='otpScreen'){
                setTimeout(()=>{
                    this.refs.cardScrollRef.scrollToEnd({animated: true});
                },400)
            }
        });
        if(view=='photo'){
            this.whichIconToshow('Photo')
        }
        if(view=='phoneVerification'){
            this.whichIconToshow('Phone')
        }
        
    }
    _savePassAndPhoto=async()=>{
        const {image,password,kocId,civilId}=this.state;
        let formData;
        if(Platform.OS=='web'){
            formData=this._creatFormData(image,{
                password:password,
                koc_id:kocId,
                civil_id:civilId
            });
        }else{
            formData=this._creteMobFormData(image,{
                password:password,
                koc_id:kocId,
                civil_id:civilId
            });
        }
        // let formData=this._creatFormData(image,{
        //     password:password,
        //     koc_id:kocId,
        //     civil_id:civilId
        // });
        let result=await api.formdata('users/verification/set_password_and_photo',formData);
        this.setState({appLoader:false});
        if(_checkStatus(result)){
            result=result.value.user;
            console.log(result);
            this.setState({mobileNumber:result.mobile_number},()=>{
                this.goNext('phoneVerification');
            });
            //console.log(result.value.user);
        }
    }
    
    b64toBlob = (b64Data, contentType, sliceSize) => {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }
    
    _creatFormData=(file, body)=>{
        const data = new FormData();
        if(Object.keys(file).length>=4){
            const mimeType = file.uri.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
            data.append("image", this.b64toBlob(file.uri.replace(`data:${mimeType};base64,`, ''), mimeType)); 
        }
        Object.keys(body).forEach(key => {
          data.append(key, body[key]);
        });    
        return data;
    }
    _creteMobFormData(result,body) {
        let localUri = result.uri;
        let filename = localUri.split('/').pop();
      
        // Infer the type of the image
        let match = /\.(\w+)$/.exec(filename);
        let type = match ? `image/${match[1]}` : `image`;
      
        // Upload the image using the fetch and FormData APIs
        let formData = new FormData();
        // Assume "photo" is the name of the form field the server expects
        formData.append('image', { uri: localUri, name: filename, type });
        Object.keys(body).forEach(key => {
            formData.append(key, body[key]);
        }); 
        return formData;
    }
    getPermissionAsync = async () => {
        // if (Constants.platform.ios) {
          const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
          this.setState({permission:status==='granted'});
          if (status !== 'granted') {
            alert(t('sorry_camera_permission'));
          }
        //}
    };
    _pickImage = async () => {
        try {
            if(this.state.permission||Platform.OS=='web'){
                let result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: true,
                    aspect: [4, 3],
                    quality: 0,
                    base64:true
                  });
                  if (!result.cancelled) {
                      debugger;
                    if(Platform.OS!='web'&&(this._calculateImageSize(result.base64)>2048)){
                        alert(t('image_size'));
                    }else if(Platform.OS=='web'){
                        let image = result.uri;
                        //console.log(this.image);
                        let base64Str = image.split(',');
                        if (this._calculateImageSize(base64Str[1]) > 2048 ){
                            alert(t('image_size'));
                        }else{
                            this.setState({ image: result });
                        }
                    }else{
                        this.setState({ image: result });
                    }
                    
                  }
            
                  console.log(result);
            }else{
                this.getPermissionAsync();
            }
          
        } catch (E) {
          console.log(E);
        }
    };
    _calculateImageSize(base64String){
        let padding, inBytes, base64StringLength;
        if(base64String.endsWith("==")) padding = 2;
        else if (base64String.endsWith("=")) padding = 1;
        else padding = 0;
    
        base64StringLength = base64String.length;
        console.log(base64StringLength)
        inBytes =(base64StringLength / 4 ) * 3 - padding;
        console.log(inBytes);
        this.kbytes = inBytes / 1000;
        return this.kbytes;
    }
    _checkKocIdPageStatus=()=>{
        const {kocId,civilId}=this.state;
        if(kocId.length>0&&civilId.length>0){
            return false
        }else return true;
    }
    // _verifyOTPScreen=()=>{
    //     const {mobileNumber}=this.state;
    //     if(mobileNumber==null || mobileNumber .length!=8){
    //         return {
    //             opacity:0.5
    //         }
    //     }else{
    //         return{
    //             opacity:1
    //         }
    //     }
    // }
    // _checkSentOtpStyles=()=>{
    //     const {mobileNumber}=this.state;
    //     debugger;
    //     if(mobileNumber==null || (mobileNumber+'').length!=8){
    //         this.sentOtpButton.setOpacityTo(0.5)
    //     }else{
    //         this.sentOtpButton.setOpacityTo(1)
    //     }
    //     console.log(this.sentOtpButton)
    // }
    renderCurrentActiveView=()=>{
        const {kocId,civilId,password,confirmPassword,image,mobileNumber,appLoader,otp}=this.state;
        if(this.state.views.initial){
            return(
                <View>
                    <View style={css.inputBoxCont}>
                        <CustomText style={css.lable} name={'koc_id'}/>
                        <TextInput
                            value={kocId}
                            onChangeText={(kocId)=>{this.setState({kocId})}}
                            //keyboardType={'numeric'}
                            style={css.input}
                        />
                    </View>
                    <View style={css.inputBoxCont}>
                    <CustomText style={css.lable} name={'civil_id'}/>
                        <TextInput
                            value={civilId}
                            onChangeText={(civilId)=>{this.setState({civilId})}}
                            //keyboardType={'numeric'}
                            style={css.input}
                        />
                    </View>{
                        
                    }
                    <TouchableOpacity disabled={(!(kocId.length>0&&civilId.length>0)||appLoader)} style={[
                        css.nxtBtn,globalCss.shadow,
                        !(kocId.length>0&&civilId.length>0)?{opacity:0.5}:{opacity:1}
                    ]} onPress={()=>{this.goNext('kocIdSubmission',true);this.setState({appLoader:true});}}>
                        {appLoader?
                            <AppLoading color={'#fff'}/>
                            :<CustomText style={css.btnText} name={'next'}/>
                        }
                    </TouchableOpacity>
                </View>
            )
        }else if(this.state.views.kocIdSubmission){
            return(
                <View style={css.verificationCont}>
                    <Image style={css.emailVerificationIcon} source={require('./images/verification_icon.png')}/>
                    <CustomText style={[css.verificationText,{marginBottom:10}]} name={'sent_verification_link_on_mail'}/>
                    <ActivityIndicator  size={'large'} color={'#2A296A'}/>
                </View>
            )
        }else if(this.state.views.password){
            return(
                <View>
                    <View style={css.verifiedIconCont}>
                        <Image style={css.emailVerifiedIcon} source={require('./images/verified-successfully.png')}/>
                        <CustomText style={css.verifiedText} name={'email_verified'}/>
                    </View>
                    <CustomText style={css.inputPasswordTxt} name={'provide_profile_password'}/>
                    <View style={css.inputBoxCont}>
                        <CustomText style={css.lable} name={'password'}/>
                        <TextInput
                            value={password}
                            onChangeText={(password)=>{this.setState({password})}}
                            secureTextEntry={true}
                            style={css.input}
                        />
                    </View>
                    <View style={css.inputBoxCont}>
                        <CustomText style={css.lable} name={'confirm_password'}/>
                        <TextInput
                            value={confirmPassword}
                            onChangeText={(confirmPassword)=>{this.setState({confirmPassword})}}
                            secureTextEntry={true}
                            style={css.input}
                        />
                    </View>
                    <TouchableOpacity 
                        disabled={(!(password.length>0&&confirmPassword.length>0&&password==confirmPassword))||appLoader}
                        style={[css.nxtBtn,globalCss.shadow,(password.length>0&&confirmPassword.length>0&&password==confirmPassword)?{opacity:1}:{opacity:0.5}]} 
                        onPress={()=>{this.goNext('photo')}}
                    >
                        {appLoader?
                            <AppLoading color={'#fff'}/>
                            :<CustomText style={css.btnText} name={'next'}/>
                        }
                    </TouchableOpacity>
                </View>
            )
        }else if(this.state.views.photo){
            return(
                <View>
                   <TouchableOpacity style={css.uploadDefCont} onPress={this._pickImage}>
                        {this.state.image==undefined
                            ?<Image style={css.uploadDefaultIcon} source={require('./images/upload_photo_defulticon.png')} />
                            :<Image style={css.uploadDefaultIcon} source={{ uri: image.uri }} />
                        }
                        <View style={css.addPhotoIconCont} >
                            <Image style={css.addPhotoIcon} source={require('./images/upload_photo.png')}/>
                        </View>
                   </TouchableOpacity>
                   <View style={css.soNiceCont}>
                        <CustomText style={css.soNiceText} name={'so_nice'}/>
                        {/* <Text style={css.soNiceText}>You can change the photo later on</Text> */}
                   </View>
                   <TouchableOpacity 
                        disabled={(!(image!=undefined&&image.uri.length>0))||appLoader}
                        style={[css.nxtBtn,globalCss.shadow,image!=undefined&&image.uri.length>0?{opacity:1}:{opacity:0.5}]}
                        onPress={()=>{
                            this._savePassAndPhoto();this.setState({appLoader:true});
                        }}
                    >
                        {appLoader?
                            <AppLoading color={'#fff'}/>
                            :<CustomText style={css.btnText} name={'next'}/>
                        }
                    </TouchableOpacity>
                </View>
            )
        }else if(this.state.views.phoneVerification){
            return(
                <View>
                    <Image style={css.otpIcon} source={require('./images/otp.png')}/>
                    <CustomText style={css.verifyOtpTxt} name={'verify_mob_number'}/>
                    <CustomText style={css.entermobNumTxt} name={'will_send_otp'}/>
                    <View style={[css.inputBoxCont,css.mobileNumberWholeCont]}>
                        <View style={css.countryCodeCont}>
                            <Image style={css.countryIcon} source={require('./images/kuwait-flag.png')} />
                            <TextInput
                                editable={false}
                                value={'+965'}
                                style={[css.mobileNumberInput]}
                            />
                        </View>
                        <View style={[css.countryCodeCont,{flex:3}]}>
                            <TextInput
                                value={this.state.mobileNumber+''}
                                onChangeText={(mobileNumber)=>{this.setState({mobileNumber:mobileNumber+''})}}
                                keyboardType={'numeric'}
                                style={[css.mobileNumberInput]}
                            />
                        </View>
                    </View>
                    <TouchableOpacity 
                        disabled={(mobileNumber==''||(mobileNumber+'').length!=8)||(appLoader)}
                        style={[css.nxtBtn,globalCss.shadow,(mobileNumber==''||(mobileNumber+'').length!=8)||(appLoader)?{opacity:0.5}:{opacity:1}]}
                        onPress={()=>{this._sendOTP('otpScreen');this.setState({appLoader:true});}}
                    >
                        {appLoader?
                            <AppLoading color={'#fff'}/>
                            :<CustomText style={css.btnText} name={'next'}/>
                        }
                    </TouchableOpacity>
                </View>
            )
        }else if(this.state.views.otpScreen){
            return(
                <View>
                    <Image style={css.otpIcon} source={require('./images/otp.png')}/>
                    <CustomText style={css.verifyOtpTxt} name={'enter_6_digit_otp'}/>
                    <Text style={css.entermobNumTxt}>{t('enter_6_digit_otp_that_was_sent')}
                        <Text style={css.mobileNumAtOtp}>
                            {'(+965) '+this.state.mobileNumber}
                        </Text>
                    </Text>
                    <OtpInputs getOtp={(otp) => {this.setState({otp})}} />
                    <TouchableOpacity disabled={otp.length!=6} style={[css.nxtBtn,globalCss.shadow,otp.length!=6?{opacity:0.5}:{opacity:1}]} onPress={()=>this._verifyOTP('congratsView')}>
                        {appLoader?
                            <AppLoading color={'#fff'}/>
                            :<CustomText style={css.btnText} name={'verify'}/>
                        }
                    </TouchableOpacity> 
                    <View style={css.resendOtpCont}>
                        <CustomText style={css.didNotText} name={'didnt_recieve_otp'}/>
                        <TouchableOpacity onPress={()=>this._sendOTP(undefined)}>
                            <CustomText style={css.sendAgainText}
                                name={'send_it_again'}
                           />
                        </TouchableOpacity>
                    </View>
                </View>
            )
        }    
    }
    goNextScene=()=>{
        if(Platform.OS=='web'){
            this.props.history.replace('/home');
        }else this.props.Actions.reset('drawermenu',{Actions:this.props.Actions})
    }
    render(){
        return(
            <View style={globalCss.parentCont}>
                <View style={globalCss.mainCont}>
                {
                    this.state.views.congratsView
                    ?<View style={css.congratsView}>
                        <View style={css.congratsModal}>
                            <View style={css.congratsImgCont}>
                                <Image style={css.congratsImg} source={require('./images/Congratulations.png')}/>
                            </View>
                            <CustomText style={css.congratsText}  name={'registration_succesful'}/>
                            <TouchableOpacity style={css.congratsButton} onPress={this.goNextScene}>
                                <CustomText style={css.congratsButtonText} name={'go_to_home'}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    :null
                }
                    <ImageBackground style={css.backGrd} resizeMode={Platform.OS!='web'?'cover':'stretch'}    source={require('./images/registration_bg.png')}>
                        <ImageBackground style={css.top} resizeMode={Platform.OS!='web'?'cover':'stretch'}  source={require('./images/registration_top_bg.png')}>
                            {Platform.OS!='web'?
                                <View  style={[Platform.OS=='android'?css.navBar:{minHeight:45}]}>
                                    <MobileNav title={null} color={'transparent'} icon={Platform.OS=='ios'?require('./images/Calendar_2_arrow.png'):require('./images/next.png')} style={[{flex:1,paddingTop:30},Platform.OS=='ios'?{alignItems:'center'}:{}]} backButtonStyles={[{marginHorizontal:20},Platform.OS=='android'?{transform: [{ rotate: '180deg' }]}:{}]} hideButton={true}/>
                                </View>
                                :null
                            }
                            <View style={css.titleCont}>
                                <CustomText style={css.title} name={'registration'}/>
                            </View >
                            <View style={[css.iconsCont]}>
                                {this.state.icons.map((img,index)=>{
                                    return(
                                        <View key={index.toString()} >
                                            <View style={css.iconImgCont} >
                                                {img.active?
                                                    <Image style={css.icon} source={img.activeIcon}/>
                                                    :<Image style={css.icon} source={img.inactiveIcon}/>
                                                }
                                                {index<this.state.icons.length-1
                                                    ?<View style={css.line}></View>
                                                    :null
                                                }
                                            </View>
                                            <CustomText style={[css.iconTitle,globalCss.bodyFontFamily,img.active?{color:'#2A296A'}:{color:'#BFBCCA'}]}value={img.title}/>
                                        </View>
                                    )
                                })}
                            </View>
                        </ImageBackground>
                        <View style={css.bottom}>
                            <View style={[
                                css.formCard,globalCss.shadow,
                                this.state.views.password?{paddingTop:20}:{},
                                this.state.views.otpScreen?{paddingVertical:20}:{}
                            ]}>
                                <ScrollView 
                                ref={'cardScrollRef'}
                                contentContainerStyle={css.scrollCont}
                                showsVerticalScrollIndicator={false}
                                >
                                    {this.renderCurrentActiveView()}
                                </ScrollView>
                            </View>
                        </View>
                    </ImageBackground>
                </View>
            </View>
        )
    }
}