import React,{Component  } from "react";
import {View,FlatList,TouchableOpacity,Text} from "react-native";
import ImageLoader from "./ImageLoader";
import { vh, vw } from "../styles/Styles";
import { _setStyle } from "../service";
import CustomText from "./CustomText";
import { Platform } from "@unimodules/core";
class CustomSlider extends Component{
    constructor(props){
        super(props);
        this.sliderItemReferences=[];
        this.state={
            currentItemIndex:0
        }
        this.handleViewableItemsChanged = this.handleViewableItemsChanged.bind(this)
        this.viewabilityConfig = {itemVisiblePercentThreshold: 100}
    }
    componentDidMount(){
        if(this.props.autoPlay){
            setTimeout(()=>{
                this._callAutoPlay(this.props.autoPlayTimeGap);
            },500)
        }
    }
    componentWillUnmount(){
        this.intervalRef.clearInterval();
    }
    _callAutoPlay=(gap)=>{
        this.intervalRef= new IntervalTimer( ()=> {
            this.setState({currentItemIndex:this.state.currentItemIndex==this.props.images.length-1?0:this.state.currentItemIndex+1},()=>{
                if(Platform.OS=='android'||Platform.OS=='ios'){
                    this.scroller.scrollToIndex({
                        animated:!this.state.currentItemIndex==0,
                        index:this.state.currentItemIndex
                    })
                }else{
                    this.scroller.scrollToOffset({
                        animated:!this.state.currentItemIndex==0,
                        offset:this.sliderItemReferences[this.state.currentItemIndex]
                    });
                }
            })
        }, gap||2000);
    }
    _onLayout=(event,index)=>{
        this.sliderItemReferences[index]=index*(event.nativeEvent.layout.width);
    }
    _onScrollEnd=(e)=>{
        console.log(e.nativeEvent);
    }
    _paginationTabPressed=(index)=>{
        this.intervalRef.pause();
        this.setState({currentItemIndex:index},()=>{
            // this.scroller.scrollToOffset({
            //     animated:true,
            //     offset:this.sliderItemReferences[this.state.currentItemIndex]
            // });
            if(Platform.OS=='android'||Platform.OS=='ios'){
                this.scroller.scrollToIndex({
                    animated:true,
                    index:this.state.currentItemIndex
                })
            }else{
                this.scroller.scrollToOffset({
                    animated:true,
                    offset:this.sliderItemReferences[this.state.currentItemIndex]
                });
            }
            setTimeout(()=>{
                this.intervalRef.resume();
            },this.props.autoPlayTimeGap)
        })
        
    }
    handleViewableItemsChanged = ({ viewableItems, changed }) => {
        if(viewableItems.length>0){
            if(viewableItems[0].index==this.state.currentItemIndex){
                return
            }else this.setState({currentItemIndex:viewableItems[0].index})
        }
    }
    _renderPagination=()=>{
        const {
            images,activeDotColor,inactiveDotColor
        }=this.props;
        const {
            currentItemIndex
        }=this.state;
        return(
            <Pagination 
                items={images}
                activeDotColor={activeDotColor||'red'}
                inactiveDotColor={inactiveDotColor||'blue'}
                activeIndex={currentItemIndex}
                _onPress={this._paginationTabPressed}
            />
        )
    }
    render(){
        const {
            images,
            sliderBoxHeight,
            sliderBoxWidth,
            _onPress,
            resizeMethod,
            resizeMode,
            imageStyle,
            parentContainerStyle,
            autoPlay,
            autoPlayTimeGap,
            inactiveDotColor,
            activeDotColor,
            metadata,
            metadataContainerStyles,
            metadataHeadingStyles,
            metadataDescriptionStyles,
            metadataInnerContainerStyles
        }=this.props;
        const {
            currentItemIndex
        }=this.state;
       return(
           <View style={{flex:1}}>
               <FlatList
                    ref={scroller => { this.scroller = scroller}}
                    contentContainerStyle={{flexGrow:1,alignItems:'center'}}
                    pagingEnabled={true}
                    //onMomentumScrollBegin={()=>this.intervalRef.pause()}
                    //onMomentumScrollEnd={()=>setTimeout(this.intervalRef.resume,autoPlayTimeGap)}
                    onViewableItemsChanged={this.handleViewableItemsChanged }
                    viewabilityConfig={this.viewabilityConfig}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    data={images}
                    keyExtractor={(item,index)=>index.toString()}
                    //ListFooterComponent={this._renderPagination()}
                    renderItem={({item,index})=>{
                        return(
                            <TouchableOpacity
                                onLayout={(e)=>this._onLayout(e,index)}
                                disabled={typeof _onPress!=='function'}
                                style={{height:sliderBoxHeight||200,width:sliderBoxWidth||'100%',...parentContainerStyle!=undefined&&parentContainerStyle}}
                                onPress={()=>{_onPress(index,item)}}
                            >
                                <ImageLoader 
                                    source={typeof item.image==='string'?{uri:item.image}:item.image}
                                    style={{height:'100%',width:'100%',...imageStyle!==undefined&&imageStyle}}
                                    resizeMethod={resizeMethod || "resize"}
                                    resizeMode={resizeMode || "cover"}
                                />
                                {metadata?
                                    <View style={{position:'absolute',width:sliderBoxWidth||'100%',bottom:0,left:0,...metadataContainerStyles&&metadataContainerStyles,...parentContainerStyle!=undefined&&parentContainerStyle}}>
                                        <View style={{backgroundColor:'rgba(0,0,0,0.3)',padding:10,...metadataInnerContainerStyles!=undefined&&metadataInnerContainerStyles}}>
                                            <CustomText numberOfLines={1} style={{...metadataHeadingStyles&&metadataHeadingStyles}} value={item.title}/>
                                            <CustomText numberOfLines={2} style={{...metadataDescriptionStyles&&metadataDescriptionStyles}} numberOfLines={2} value={item.description}/>
                                        </View>
                                    </View>
                                    :null
                                }
                            </TouchableOpacity>
                        )
                    }}
                />
                <Pagination 
                    items={images}
                    activeDotColor={activeDotColor||'red'}
                    inactiveDotColor={inactiveDotColor||'blue'}
                    activeIndex={currentItemIndex}
                    _onPress={this._paginationTabPressed}
                />
           </View>
       )
    }
}
class Pagination extends Component{
    constructor(props){
        super(props);
    }
    render(){
        const {
            items,
            activeIndex,
            activeDotColor,
            inactiveDotColor,
            _onPress
        }=this.props;
        if(items.length>1){
            return(
                <View style={{alignItems:'center',flexDirection:'row',justifyContent:'center'}}>
                    {items.map((v,index)=>{
                        return(
                            <TouchableOpacity
                                key={index+''}
                                style={{
                                    height:1*vh,
                                    width:_setStyle(4*vw,15),
                                    backgroundColor:index==activeIndex?activeDotColor:inactiveDotColor,
                                    marginHorizontal:4,
                                    borderRadius:5
                                }}
                                onPress={()=>{_onPress(index)}}
                            >
                            </TouchableOpacity>
                        )
                    })}
                </View>
            )
        }else return null;
    }
}
//Interval with pause and resume ability.
function IntervalTimer(callback, interval) {
    var timerId, startTime, remaining = 0;
    var state = 0; //  0 = idle, 1 = running, 2 = paused, 3= resumed

    this.pause = function () {
        if (state != 1) return;

        remaining = interval - (new Date() - startTime);
        window.clearInterval(timerId);
        state = 2;
    };

    this.resume = function () {
        if (state != 2) return;

        state = 3;
        window.setTimeout(this.timeoutCallback, remaining);
    };

    this.timeoutCallback = function () {
        if (state != 3) return;

        callback();

        startTime = new Date();
        timerId = window.setInterval(callback, interval);
        state = 1;
    };
    this.clearInterval=function(){
        window.clearInterval(timerId);
    }

    startTime = new Date();
    timerId = window.setInterval(callback, interval);
    state = 1;
}
export default CustomSlider;