import React,{Component  } from "react";
import {View,TouchableOpacity,StyleSheet,Image  } from "react-native";
import CustomText from "./components/CustomText";
import globalCss, { winHeight, winWidth } from "./styles/Styles";
const css=StyleSheet.create({
    backImg:{
        flex:1,
        justifyContent:'center',
        alignItems:'center',
    },logoImg:{
        width:winWidth-50,
        height:winHeight/3,
        resizeMode:'contain'
    },langSelectImg:{
        height:(((winHeight*4)/10)/2)-40,
        width:(winWidth/2),
        resizeMode:'contain'
    },selectLangText:{
        textAlign:'center',
        fontSize:16
    },langButton:{
        width:(winWidth/2)-20,
        height:(((winHeight*4)/10)/4)-20,
        justifyContent:'center',
        alignItems:'center',
        elevation:5,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.37,
        shadowRadius: 7.49,
    },langContBack:{
        position:'absolute',
        backgroundColor:'rgba(0,0,0,0.5)',
        width:'100%',
        height:'100%',
        left:0,
        right:0,
        bottom:0,
        top:0,    
    },langCont:{
        height:'40%',
        width:'100%',
        backgroundColor:'#fff',
        marginTop:'auto' 
    },nextImg:{
        height:(winHeight/10)/2,
        width:(winWidth/6),
        fontSize:18,
        color:'#fff'
    },nextIconCont:{
        height:'10%',
        width:'100%',
        marginBottom:'auto',
    },nextIconInnerCont:{
        marginLeft:'auto',
        marginTop:'auto',
    }
})
export default class ChangeLanguage extends Component{
    state={
        lang:undefined
    }
    render(){
        const {lang}=this.state;
        return(
            <View style={globalCss.parentCont}>
                <View style={globalCss.mainCont}>
            <View style={css.langContBack}>
                                <View style={css.nextIconCont}>
                                    <TouchableOpacity disabled={lang==undefined} style={css.nextIconInnerCont} onPress={()=>this._gotoNext('/intro','intro')}>
                                        {/* <Image source={require('./images/next.png')} style={css.nextImg}/> */}
                                        <View style={[{flexDirection:'row'},lang==undefined?{opacity:0.5}:{opacity:1}]}>
                                            <CustomText style={[css.nextImg,globalCss.bodyFontFamily]} value={'Done'}/>
                                            <CustomText style={[css.nextImg,globalCss.bodyFontFamily]} value={'منجز /'}/>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                <View style={css.langCont}>
                                    <View style={{flex:1}}>
                                        <View style={{flex:1,paddingTop:20}}>
                                            <CustomText style={css.selectLangText} value={'اختار اللغة'}/>
                                            <CustomText style={css.selectLangText} value={'Select Language'}/>
                                        </View>
                                        <View style={{flex:2,justifyContent:'center',alignItems:'center'}}>
                                            <Image source={require('./images/language.png')} style={css.langSelectImg}/>
                                        </View>
                                        <View style={{flex:1,flexDirection:'row',justifyContent:'space-around'}}>
                                            <TouchableOpacity style={[{
                                                backgroundColor:lang=='ar'?'#282867':'#fff'
                                            },css.langButton]}
                                            onPress={()=>{this._setLang('ar')}}
                                            >
                                                <CustomText style={{
                                                        ...globalCss.bodyFontFamily,
                                                        color:lang=='ar'?'#fff':'#000000'
                                                    }} 
                                                    value={'عربى'}
                                                />
                                            </TouchableOpacity>
                                            <TouchableOpacity style={[css.langButton,{
                                                backgroundColor:lang=='en'?'#282867':'#fff'
                                            }]}
                                            onPress={()=>{this._setLang('en')}}
                                            >
                                                <CustomText style={{
                                                        ...globalCss.bodyFontFamily,
                                                        color:lang=='en'?'#fff':'#000000'
                                                    }}
                                                    value={'English'}
                                                />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            </View>
                            </View>
        )
    }
}