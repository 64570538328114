import React, { Component } from "react";
import { Scene, Router, Tabs, Drawer, Actions } from "react-native-router-flux";
import { View } from "react-native";
import { connect } from "react-redux";
import { actions } from "../store";
import { _getTabItems } from "../constants";

//Scenes
import Home from "../Home";
import TabComponent from "../Tabs";
import Intro from "../Intro";
import Splash from "../Splash";
import Registration from "../Registration";
import Directors, { Detail } from "../BoardDirectors";
import {
  NavRightButtonForDirectors,
  NavRightButtonForCalender,
  DrawerMenu,
  NavLeftButtonForDrawer,
} from "../DrawerMenu";
import Syndicate from "../SyndicateData";
import Notification from "../Notifications";
import PhotoAlbum, { PhotoAlbumDetail } from "../PhotoAlbum";
import Events, { EventsDetail } from "../Events";
import News, { NewsDetail } from "../News";
import Offers, { OffersDetail } from "../Offers";
import IdCard from "../CompanyIdCard";
import Profile from "../Profile";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import Calender, { CalenderDetails } from "../Calender";
import Vacancies from "../Transfer_Adjustment";
import Login from "../Login";
import FAQ from "../FAQ";
import About from "../About";
import KOCLocation from "../Location";
import ContactUs from "../ContactUs";
import globalCss from "../styles/Styles";
import { t, _setLocaleForApp } from "../langs";
import ChangeLanguage from "../ChangeLanguage";
import { Platform } from "@unimodules/core";
import AppLoading from "../components/AppLoading";
class RouterComView extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    // _setLocaleForApp();
    // setTimeout(()=>{
    //     this.setState({loading:false});
    // },200)
  }
  _onBack = () => {
    const { _setTabItems } = this.props;
    Actions.pop();
    _setTabItems(_getTabItems(), null, false);
  };
  render() {
    const { loading } = this.state;
    // if(loading){
    //     return (
    //         <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
    //             <AppLoading/>
    //         </View>
    //     )
    // }else
    return (
      <Router>
        <Scene key="root" Actions={Actions}>
          <Scene hideNavBar key="splash" initial={true} component={Splash} />
          <Scene hideNavBar key="intro" component={Intro} />
          <Scene hideNavBar key="login" component={Login} />
          <Scene hideNavBar key="registration" component={Registration} />
          <Scene key="forgotpassword" component={ForgotPassword} hideNavBar />
          <Scene key="resetpassword" component={ResetPassword} hideNavBar />
          <Drawer
            key="drawermenu"
            drawerType="displace"
            contentComponent={DrawerMenu}
            hideNavBar
            initial={true}
          >
            <Tabs
              key="tabmenu"
              tabBarComponent={TabComponent}
              initial={true}
              hideNavBar
              hideTabBar={this.props.hideTabBar}
            >
              <Scene
                key="mainApp"
                initial={true}
                headerForceInset={{ top: "never" }}
              >
                <Scene
                  key="home"
                  title={t("koc_union")}
                  titleStyle={[
                    globalCss.headingFontFamily,
                    { marginLeft: "auto", marginRight: "auto" },
                  ]}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  renderLeftButton={NavLeftButtonForDrawer}
                  initial={true}
                  type={"reset"}
                  component={Home}
                  hideNavBar={false}
                />
                <Scene
                  key="directors"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("koc_union_board_of_directors")}
                  titleStyle={globalCss.headingFontFamily}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={Directors}
                />
                <Scene
                  key="boardofdirectorsdetail"
                  wrap={false}
                  component={Detail}
                />
                <Scene
                  key="syndicatedata"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("union_regulation")}
                  titleStyle={globalCss.headingFontFamily}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={Syndicate}
                />
                <Scene
                  key="notifications"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("notifications")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={Notification}
                />
                <Scene
                  key="vacancies"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("transfer_and_adjustment")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={Vacancies}
                />
                <Scene
                  key="photoalbum"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("photoalbum")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={PhotoAlbum}
                />
                <Scene
                  key="photoalbumdetails"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={PhotoAlbumDetail}
                />
                <Scene
                  key="idcard"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("koc_union_membership_card")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={IdCard}
                />
                <Scene
                  key="profile"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("profile")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={Profile}
                />
                <Scene
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  title={t("calender")}
                  key="calender"
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForCalender}
                  component={Calender}
                />
                <Scene
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  // back={false}
                  title={t("calender")}
                  key="calenderdetails"
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForCalender}
                  component={CalenderDetails}
                />
                <Scene
                  key="events"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("events_holidays")}
                  onBack={this._onBack}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={Events}
                />
                <Scene
                  key="eventsdetails"
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  onBack={this._onBack}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={EventsDetail}
                />
                <Scene
                  key="news"
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  back={true}
                  title={t("news")}
                  onBack={this._onBack}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={News}
                />
                <Scene
                  key="newsdetails"
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  onBack={this._onBack}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={NewsDetail}
                />
                <Scene
                  key="offer"
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  title={t("offers_and_discount")}
                  onBack={this._onBack}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={Offers}
                />
                <Scene
                  key="offerdetails"
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  onBack={this._onBack}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={OffersDetail}
                />
                <Scene
                  key="faq"
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  onBack={this._onBack}
                  title={t("faq")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={FAQ}
                />
                <Scene
                  key="aboutus"
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  onBack={this._onBack}
                  title={t("about__us")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={About}
                />
                <Scene
                  key="contactus"
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  onBack={this._onBack}
                  title={t("contact_us")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={ContactUs}
                />
                <Scene
                  key="location"
                  back={true}
                  backButtonImage={
                    Platform.OS == "ios"
                      ? require("../images/ios_back.png")
                      : undefined
                  }
                  onBack={this._onBack}
                  title={t("koc_union_location")}
                  navigationBarStyle={{ backgroundColor: "#24255E" }}
                  titleStyle={globalCss.headingFontFamily}
                  navBarButtonColor={"#fff"}
                  renderRightButton={NavRightButtonForDirectors}
                  component={KOCLocation}
                />
                <Scene key="changelanguage" component={ChangeLanguage} />
              </Scene>
            </Tabs>
          </Drawer>
        </Scene>
      </Router>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    _setTabItems: (tabItems, pressedItem, renderNextScreen) =>
      dispatch(actions._setTabItems(tabItems, pressedItem, renderNextScreen)),
  };
}
const RouterCom = connect(null, mapDispatchToProps)(RouterComView);
export { RouterCom };
