import React,{Component}from 'react';
import { 
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  Platform
} from 'react-native';
import  globalCss from './styles/Styles';
import ImageLoader from './components/ImageLoader';
import CustomText from './components/CustomText';
const {height,width}=Dimensions.get('window');
const css=StyleSheet.create({
   imageCont:{
        flex:5
    },imagesScroll:{
        flexGrow:1,alignSelf:'flex-end'
    },contentCenter:{
        justifyContent:'center',alignItems:'center'
    },introImg:{
        height:((height*5)/9)-100,
        width:width-50,
        resizeMode:'contain'
    },innerImgCont:{
        height:220,
        width:Platform.select({
            web:'100%',default:width
        })
        //backgroundColor:'red'
    },textCont:{
        flex:2,
        width:'100%'
    },textHeading:{
        //fontWeight:'normal',
        fontSize:24,
        textAlign:'center',
        paddingHorizontal:40,
        ...globalCss.headingFont
    },textDesc:{
        fontSize:12,
        textAlign:'center',
        paddingHorizontal:30,
        ...globalCss.bodyFont
        //opacity:0.5
    },endCont:{
        flex:1,
        flexDirection:'row'
    }
})
export default class Intro extends Component{
    constructor(props){
        super(props);
        this.introImages=[
            require('./images/intro_1.jpg'),
            require('./images/intro2.jpg'),
            require('./images/intro_3.jpg')
        ];
        this.introImagesRef=[];
        this.state={
            currentActive:0
        }
    }
    _skip=()=>{
        this._gotoHome();
    }
    goNext=()=>{
        if(this.introImages.length==this.state.currentActive+1){
            this._gotoHome();
        }else{
            if(Platform.OS!='web'){
                this.refs.imgScroller.scrollTo({
                    x: this.introImagesRef[this.state.currentActive+1],
                    y: 0,
                    animated: true,
                });
            }
            this.setState({currentActive:this.state.currentActive+1})
        }
    }
    _gotoHome=()=>{
        if(Platform.OS=='web'){
            this.props.history.replace('/home');
        }else this.props.Actions.reset('drawermenu',{Actions:this.props.Actions});
    }
    scrollEnd=(e)=>{
        let index=this.introImagesRef.indexOf(e.nativeEvent.contentOffset.x);
        this.setState({currentActive:index});
    }
    scrollImageRender=()=>{
        if(Platform.OS!='web'){
            return (
                <ScrollView
                            onMomentumScrollEnd={(e)=>this.scrollEnd(e)}
                            ref={'imgScroller'}
                            horizontal={true}
                            pagingEnabled={true}
                            showsHorizontalScrollIndicator={false}
                            contentContainerStyle={css.imagesScroll}
                        >
                        {
                            this.introImages.map((img,index)=>{
                                return (
                                    <View onLayout={(event)=>{
                                        this.introImagesRef[index] = event.nativeEvent.layout.x;
                                    }} key={index.toString()} style={[css.innerImgCont,css.contentCenter]}>
                                        <ImageLoader style={css.introImg} source={img}/>
                                    </View>
                                )
                            })
                        }  
                        </ScrollView>
            )
        }else{
            return(
                <View style={{flexGrow:1,justifyContent:'flex-end'}}>
                    <View style={[css.innerImgCont,css.contentCenter]}>
                        <ImageLoader style={css.introImg} source={this.state.currentActive==0?this.introImages[0]:this.state.currentActive==1?this.introImages[1]:this.introImages[2]}/>
                    </View>
                </View>
                
            )
        }
    }
    render(){
        let screen=this.state.currentActive;
        let heading=screen==0
                            ?'Explore Nearby And Beyond'
                                :screen==1
                                ?'Kuwait Oil Company And Workers Syndicate'
                                    :'Lorem ipsum is simply dummy text of the';
        let Description=screen==0
                                ?'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy'
                                :screen==1
                                ?'Text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                                    :'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
        return(
            <View style={globalCss.parentCont}>
                <View style={globalCss.mainCont}>
                    <View style={[css.imageCont]}>
                        {
                            this.scrollImageRender()
                        }
                    </View>
                    <View style={[css.textCont,css.contentCenter]}>
                        <Text style={css.textHeading}>{heading}</Text>
                        <Text style={css.textDesc}>{Description}</Text>
                    </View>
                    <View style={[css.endCont,css.contentCenter]}>
                        <View style={{
                            flex:1
                        }}>
                            <TouchableOpacity onPress={this._skip}>
                                <CustomText style={{...globalCss.bodyFont,opacity:0.6,marginLeft:'auto'}} name={'skip'}/>
                            </TouchableOpacity>
                        </View>
                        <View style={{
                            flex:3,flexDirection:'row',justifyContent:'center'
                        }}>
                            {this.introImages.map((img,index)=>{
                                return (
                                    <TouchableOpacity key={index.toString()} style={{
                                        width:screen==index?40:24,
                                        height:10,
                                        borderRadius:5,
                                        backgroundColor:screen==index?'#3A365C':'#CFCFE9',
                                        marginHorizontal:4
                                    }} onPress={()=>{this.setState({currentActive:index})}}>
                                    </TouchableOpacity>
                                )
                            })}
                        </View>
                        <View style={{flex:1}}>
                            <TouchableOpacity onPress={this.goNext}>
                                <CustomText style={{color:'#3A365C',...globalCss.bodyFontFamily}} name={'next'}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}