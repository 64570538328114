import React,{Component}from 'react';
import { 
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ImageBackground,
  Platform,
  I18nManager
} from 'react-native';
import  globalCss,{winHeight,winWidth} from './styles/Styles';
import CustomText from './components/CustomText';
import {localStore} from './service';
import { _setAppCurrentLang, _getTabItems } from './constants';
import { _getLangauge, _setLocaleForApp } from './langs';
import * as Localization from 'expo-localization';
import { _setTabItems } from './store/action';
// import { reloadAsync } from 'expo-updates';
import {connect} from 'react-redux';
import {actions} from './store';
const css=StyleSheet.create({
    backImg:{
        flex:1,
        justifyContent:'center',
        alignItems:'center',
    },logoCont:{
        width:winWidth-50,
        height:winHeight/3,
        //alignItems:'center'
        
    },logoImg:{
        width:'100%',
        height:'90%',
        resizeMode:'contain',
        marginLeft:'auto',
        marginRight:'auto'
    },langSelectImg:{
        height:(((winHeight*4)/10)/2)-40,
        width:(winWidth/2),
        resizeMode:'contain'
    },selectLangText:{
        textAlign:'center',
        fontSize:16
    },langButton:{
        width:(winWidth/2)-20,
        height:(((winHeight*4)/10)/4)-20,
        justifyContent:'center',
        alignItems:'center',
        elevation:5,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.37,
        shadowRadius: 7.49,
    },langContBack:{
        position:'absolute',
        backgroundColor:'rgba(0,0,0,0.5)',
        width:'100%',
        height:'100%',
        left:0,
        right:0,
        bottom:0,
        top:0,    
    },langCont:{
        height:'40%',
        width:'100%',
        backgroundColor:'#fff',
        marginTop:'auto' 
    },nextImg:{
        height:(winHeight/10)/2,
        width:(winWidth/6),
        fontSize:18,
        color:'#fff'
    },nextIconCont:{
        height:'10%',
        width:'100%',
        marginBottom:'auto',
    },nextIconInnerCont:{
        marginLeft:'auto',
        marginTop:'auto',
    },logoText:{
        //...globalCss.headingFontFamily,
        fontWeight:'bold',
        color:'#fff',
        //fontSize:16,
        marginLeft:'auto',
        marginRight:'auto',
        padding:2
    }
})
class SplashComp extends Component{
    // constructor(props){
    //     super(props);
    //     // localStore.delete('@appLang');  
    //     console.log(I18nManager,props);
    // }
    
    state={
        langSelect:false,
        lang:undefined
    }
    async componentDidMount(){
        //await localStore.delete('@appLang');
       // this._checkLang();
        _setLocaleForApp();
        setTimeout(()=>{
            if(Platform.OS=='android'||Platform.OS=='ios'){
                let tabItems=_getTabItems();
                this.props._setTabItems(tabItems,null,null);
            }
            this._gotoNext('/home','drawermenu');
        },1500);
    }
    _checkLang=async()=>{
        //console.log(I18nManager,I18nManager.getConstants());
        let resp=await localStore.get('@appLang');
        if(resp.status){
            setTimeout(()=>{
                this._gotoNext('/home','drawermenu');
            },1500);
        }else{
            //this._setLang('ar');
            this.setState({langSelect:true});
            //await I18nManager.forceRTL(true);
        }
    }
    _setLang=async(val)=>{
        let resp=await localStore.set('@appLang',val);  
        if(resp.status){
            _setAppCurrentLang(val);
            this.setState({lang:val}); 
        }
        if(val=='en'){
            if(I18nManager.isRTL){
                await I18nManager.forceRTL(false);
                if(Platform.OS=='ios'||Platform.OS=='android'){
                    this.props._setAppLang('en');
                    //reloadAsync();
                }
                console.log('Called',I18nManager);
            }
        }else{
            if(!I18nManager.isRTL){
                await I18nManager.forceRTL(true);
                if(Platform.OS=='ios'||Platform.OS=='android'){
                    //reloadAsync();
                    this.props._setAppLang('ar');
                }
                console.log('Called',I18nManager,this.props.appLang);
                
            }
        }
        //this.forceUpdate();
        _getLangauge();
    }
    _gotoNext=(web,mob)=>{
        if(Platform.OS=='web'){
            this.props.history.replace(web);
        }else this.props.Actions.reset(mob,{Actions:this.props.Actions});
    }
    render(){
        const {lang}=this.state;
        
        return(
            <View style={globalCss.parentCont}>
                <View style={globalCss.mainCont}>
                    <ImageBackground source={require('./images/splashbg.jpg')} style={css.backImg}>
                        <View style={css.logoCont}>
                            <Image source={require('./images/logo.png')} style={css.logoImg}/>
                            {/* <Text style={css.logoText}>{'نقابة العاملين بشركة نفط الكويت'}</Text>
                            <Text 
                                adjustsFontSizeToFit={Platform.OS=='android'||Platform.OS=='ios'} 
                                numberOfLines={Platform.OS=='android'||Platform.OS=='ios'?1:undefined} 
                                style={css.logoText}
                            >{'Kuwait Oil Company Trade Union'}</Text> */}
                        </View>
                        {this.state.langSelect?
                            <View style={css.langContBack}>
                                <View style={css.nextIconCont}>
                                    <TouchableOpacity disabled={lang==undefined} style={css.nextIconInnerCont} onPress={()=>this._gotoNext('/intro','intro')}>
                                        {/* <Image source={require('./images/next.png')} style={css.nextImg}/> */}
                                        <View style={[{flexDirection:'row'},lang==undefined?{opacity:0.5}:{opacity:1}]}>
                                            <CustomText style={[css.nextImg,globalCss.bodyFontFamily]} value={'Done'}/>
                                            <CustomText style={[css.nextImg,globalCss.bodyFontFamily]} value={'منجز /'}/>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                <View style={css.langCont}>
                                    <View style={{flex:1}}>
                                        <View style={{flex:1,paddingTop:20}}>
                                            <CustomText style={css.selectLangText} value={'اختار اللغة'}/>
                                            <CustomText style={css.selectLangText} value={'Select Language'}/>
                                        </View>
                                        <View style={{flex:2,justifyContent:'center',alignItems:'center'}}>
                                            <Image source={require('./images/language.png')} style={css.langSelectImg}/>
                                        </View>
                                        <View style={{flex:1,flexDirection:'row',justifyContent:'space-around'}}>
                                            <TouchableOpacity style={[{
                                                backgroundColor:lang=='ar'?'#282867':'#fff'
                                            },css.langButton]}
                                            onPress={()=>{this._setLang('ar')}}
                                            >
                                                <CustomText style={{
                                                        ...globalCss.bodyFontFamily,
                                                        color:lang=='ar'?'#fff':'#000000'
                                                    }} 
                                                    value={'عربى'}
                                                />
                                            </TouchableOpacity>
                                            <TouchableOpacity style={[css.langButton,{
                                                backgroundColor:lang=='en'?'#282867':'#fff'
                                            }]}
                                            onPress={()=>{this._setLang('en')}}
                                            >
                                                <CustomText style={{
                                                        ...globalCss.bodyFontFamily,
                                                        color:lang=='en'?'#fff':'#000000'
                                                    }}
                                                    value={'English'}
                                                />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            :null
                        }
                    </ImageBackground>
                </View>
            </View>
        )
    }
}
// function mapStateToProps(state){
//     const {tabReducer}=state
//     return {
//         //appLang:appLang.appLang
//         tabItems:tabReducer.tabItems
//     }
// }
function mapDispatchToProps(dispatch){
    return{
        //toggleLoginUp:()=>dispatch({'type':'toggleLoginUp'})
        _setTabItems:(tabItems,pressedItem,renderNextScreen)=>dispatch(actions._setTabItems(tabItems,pressedItem,renderNextScreen)),
        //_setAppLang:(val)=>dispatch({'type':'changeLang','val':val})
    }
}
const Splash=connect(null,mapDispatchToProps)(SplashComp);
export default Splash;