import React,{Component } from "react";
import { View,Text,Image,ScrollView,StyleSheet,Platform,I18nManager,ImageBackground,Dimensions} from "react-native";
import globalCss, {winWidth,winHeight, vh, themeColor} from './styles/Styles'
import ImageLoader from "./components/ImageLoader";
import { api, localStore, _checkStatus, _parseDate, _setStyle } from "./service";
import AppLoading from "./components/AppLoading";
import * as ScreenOrientation from 'expo-screen-orientation';
import CustomText from "./components/CustomText";
import { _appCurrentLang } from "./constants";
import { isRTL } from "expo-localization";
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
const css=StyleSheet.create({
    main:{
        flex:1,
        backgroundColor:'#EEEDF0',
        padding:20,
        alignItems:'center'
    },cardHeadings:{
        ...globalCss.headingFont,
        fontSize:_setStyle(vh*2,20),
        fontWeight:'bold',
        color:themeColor,
        textAlign:'center'
    },cardTopImages:{
        width:'90%',
        height:'70%',
        resizeMode:'contain',
        alignSelf:'center'
    },cardImagesCont:{
        position:'absolute',
        top:0,
        left:0,
        height:'100%',
        width:'100%',
        borderRadius:20,
        zIndex:-1
    },cardUserHeadings:{
        textAlign:'left'
    },cardLabels:{
        fontSize:_setStyle(vh*2,20),
        ...globalCss.bodyFont
    }
});

class IdCardView extends Component{
    constructor(){
        super();
        this.state={
            profileHeight:0,
            profileWidth:0,
            user:{},
            loading:true,
            Items:[],
            width:winWidth,
            height:winHeight,
            orientation:'portrait'
        }
        this._getUser();
    }
    componentDidMount(){
        console.log(winHeight,winWidth,I18nManager.isRTL)
        if(Platform.OS=='android'||Platform.OS=='ios'){
            ScreenOrientation.unlockAsync();
            ScreenOrientation.addOrientationChangeListener(this._setOrientationType)   
        }
        Dimensions.addEventListener("change",this._orientationOccur);
    }
    componentWillUnmount(){
        if(Platform.OS=='android'||Platform.OS=='ios'){
            ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT); 
            ScreenOrientation.removeOrientationChangeListeners();
        }
        Dimensions.removeEventListener("change", this._orientationOccur);
    }
    _orientationOccur=async({ window, screen })=>{
        this.setState({width:window.width,height:window.height});
        //this._setOrientationType()
    }
    _setOrientationType=(val)=>{
        //console.log(val.orientationInfo.orientation);
        // let resp=await ScreenOrientation.getOrientationAsync();
        val=val.orientationInfo.orientation;
        if(val==2||val==4){
            val='landscape';
        }else val='portrait';
        console.log(val,'here');
        this.setState({orientation:val});
    }
    // _setItems=(user)=>{
    //     let Items=[
    //         {
    //             icon:require('./images/civil_id.png'),
    //             label:'EMP No',
    //             value:user.koc_id
    //         },{
    //             icon:require('./images/name.png'),
    //             label:'Name',
    //             value:_appCurrentLang=='ar'?user.arabic_name:user.name
    //         },{
    //             icon:require('./images/Designation.png'),
    //             label:'Designation',
    //             value:_appCurrentLang=='ar'?user.arabic_designation:user.designation
    //         },{
    //             icon:require('./images/Date-of-Joining.png'),
    //             label:'Date of Joining',
    //             value:_parseDate(user.date_of_joining)
    //         },{
    //             icon:require('./images/civil_id.png'),
    //             label:'Civil ID',
    //             value:user.civil_id
    //         }
    //     ]
    //     user={
    //         civil_id:user.civil_id,
    //         date_of_joining:_parseDate(user.date_of_joining),
    //         designation:_appCurrentLang=='ar'?user.arabic_designation:user.designation,
    //         name:_appCurrentLang=='ar'?user.arabic_name:user.name,
    //         koc_id:user.koc_id
    //     }
    //     this.setState({Items,loading:false,user});
    // }
    _getHeightOfProfileCont=(e)=>{
        this.setState({
            profileHeight:e.nativeEvent.layout.height,
            profileWidth:e.nativeEvent.layout.width
        })
    }
    _getUserImageContainerDimension=(e)=>{
        this.setState({
            userImageHeight:e.nativeEvent.layout.height,
            userImageWidth:e.nativeEvent.layout.width
        })
    }
    _getUserImageSize=(img)=>{
        Image.getSize(img,(width,height)=>{
            this.setState({userImageBorder:(height)/2})
        })
    }
    _getUser=async()=>{
        let userId=await localStore.get('userId');
        if(_checkStatus(userId)){
            userId=userId.value;
            let resp=await api.get('users/'+userId,true);
            console.log(resp);
            if(_checkStatus(resp)){
                let user=resp.value.user;
                user={
                    civil_id:user.civil_id,
                    date_of_joining:_parseDate(user.date_of_joining),
                    designation:_appCurrentLang=='ar'?user.arabic_designation:user.designation,
                    name:_appCurrentLang=='ar'?user.arabic_name:user.name,
                    koc_id:user.koc_id,
                    image:user.image,
                    id:user.id
                }
                this._getUserImageSize(user.image);
                this.setState({loading:false,user});
            }
        }
    }
    //Styles
    cardContainer=()=>{
        const {height,width}=this.state;
        //return {borderRadius:20,backgroundColor:'#fff',height:'100%',width:'100%'}
        return {
            borderRadius:20,
            backgroundColor:'#fff',
            height:Platform.OS=='web'?'50%':height<width?'100%':'50%',
            width:height<width?'80%':'100%',
            //paddingTop:50
            //flex:1,
            
        }
    }
    cardBackgroundImage=()=>{
        const {height,width}=this.state;
        return {
            height:Platform.OS=='web'?'50%':height<width?'100%':'50%',
            width:Platform.OS=='web'?'100%':height<width?'80%':'100%',
        }
    }
    topLeftSemiCircle=()=>{
        const {profileHeight,profileWidth,orientation}=this.state;
        return {
            position:'absolute',
            top:0,
            left:0,
            backgroundColor:'#F4F4F4',
            height:'40%',
            width:'30%',
            borderBottomRightRadius:profileWidth/2,
            borderTopLeftRadius:20,
            paddingTop:10
            //justifyContent:'center',
            //alignItems:'center'
        }
    }
    topRightSemiCircle=()=>{
        const {profileHeight,profileWidth,orientation}=this.state;
        return {
            position:'absolute',
            top:0,
            right:0,
            //backgroundColor:'#F4F4F4',
            height:'40%',
            width:'30%',
            borderBottomLeftRadius:profileWidth/2,
            borderTopRightRadius:20,
            paddingTop:10
            //justifyContent:'center',
            //alignItems:'center'
        }
    }
    render(){
        // /source={require('./images/companyid_bg.png')}
        const {loading,user,userImageBorder}=this.state;
        if(loading){
            return (
                <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
                    <AppLoading/>
                </View>
            )
        }else return(
            <View style={css.main}>
                <View onLayout={this._getHeightOfProfileCont}   style={this.cardContainer()}>
                    <View style={{flex:1}}>
                        <View style={{flex:4}}></View>
                        <View style={{flex:1}}>
                            <CustomText style={css.cardHeadings} name={'koc_union_membership_card'}/>
                        </View>
                    </View>
                    <View style={{flex:2,paddingTop:20}}>
                            <View style={{flex:2,flexDirection:'row',paddingHorizontal:20,alignItems:'center'}}>
                                <View style={{flex:1}}>
                                    <View >
                                        <CustomText style={css.cardLabels} name={'koc_no_'}/>
                                        <CustomText style={[css.cardHeadings,css.cardUserHeadings]} value={user.koc_id}/>
                                    </View>
                                    {/* <View style={{flex:1}}>
                                        <CustomText style={css.cardLabels} name={'date_of_joining'}/>
                                        <CustomText style={[css.cardHeadings,css.cardUserHeadings]} value={user.date_of_joining}/>
                                    </View> */}
                                </View>
                                <View style={{flex:1,alignItems:'flex-end'}}>
                                    <View>
                                        <CustomText style={css.cardLabels} name={'civil_id'}/>
                                        <CustomText style={[css.cardHeadings,css.cardUserHeadings]} value={user.civil_id}/>
                                    </View>
                                </View>
                            </View>
                            <ImageBackground style={{flex:1}} source={require('./images/card_bg.png')} resizeMode={'contain'} >
                                <View style={{borderTopWidth:2,borderTopColor:'#EDEDED',marginHorizontal:20}}>
                                    <CustomText style={[css.cardHeadings,{textAlign:'left',paddingVertical:10}]} value={user.name}/>
                                </View>
                                
                            </ImageBackground>
                    </View>
                    <View style={css.cardImagesCont}>
                        <View  onLayout={this._getUserImageContainerDimension} style={this.topLeftSemiCircle()}>
                            {user.image==''||user.image==undefined?
                                <Image style={css.cardTopImages} source={require('./images/card_photo.png')}/>
                                :<Image style={[css.cardTopImages,{borderRadius:userImageBorder}]} source={{uri:user.image}}/>
                            }
                        </View>
                        <View style={this.topRightSemiCircle()}>
                            <Image style={css.cardTopImages} source={require('./images/logo.png')}/>
                        </View>
                    </View>
                </View>
                {/* <View style={{flex:1}}>

                </View> */}
            </View>
        )
    }
    _item=(item,index)=>{
        return(
            <View style={{
                height:Platform.select({web:50,default:60}),
                width:'100%',
                //flex:1,
                flexDirection:'row'
            }} key={index}>
                <View style={{flex:1}}>
                    <Image style={{width:20,height:20,resizeMode:'contain'}} source={item.icon}/>
                </View>
                <View style={{flex:8,paddingLeft:10}}>
                    <CustomText style={globalCss.bodyFont} value={item.label}/>
                    <CustomText style={globalCss.headingFont} value={item.value}/>
                </View>
            </View>
        )
    }
}
export default class IdCard extends Component{
    render(){
        if(Platform.OS!='web')
            return (<IdCardView {...this.props}/>)
        else return (<Page {...this.props} DynamicComponent={IdCardView}/>)
    }
}