
import {StyleSheet,Platform,Dimensions,StatusBar} from 'react-native';
const {width,height}=Dimensions.get('window');
const vw=width/100;
const vh=height/100;
var units = {
    vmin :Math.min(vw, vh),
    vmax :Math.max(vw, vh)
};
const bodyFontColor='#565f66';
const themeColor='#24245B';
const winHeight=Platform.select({web:height,default:height});
const winWidth=Platform.select({web:700,default:width});
const screenWidth=width;
var globalCss= StyleSheet.create({
    parentCont:{
        height:Platform.select({
            web:height,default:height
        }),
        flex:1,
        justifyContent:"center",alignItems:'center'
    },mainCont:{
        flex:1,
        width:Platform.select({
            web:700,default:width
        }),
        height:'100%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.37,
        shadowRadius: 7.49,
        elevation: 12,
    },shadow:{
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.37,
        shadowRadius: 7.49,
        elevation: 12,  
    },input:{
        ...Platform.select({
            web: {
                outlineColor:"#ffffff00",
                marginTop:'auto',
                marginBottom:8,
            }
        }),
        
    },webThemeColor:{
        color:'#24245B'
    },bodyFont:{
        fontFamily:'Poppins',
        color:'#565f66'
    },headingFont:{
        fontFamily:'Roboto',
        color:'#141313'
    },headingFontFamily:{
        fontFamily:'Roboto'
    },bodyFontFamily:{
        fontFamily:'Poppins'
    },firstPage:{
        flex:1,
        ...(Platform.OS=='android'||Platform.OS=='ios')&&{backgroundColor:'#24245B'},
        ...(Platform.OS=='android'&&{paddingTop:StatusBar.currentHeight})
    }
});
export default globalCss;
export {winHeight,winWidth,screenWidth,themeColor,bodyFontColor,vw,vh}