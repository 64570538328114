import { combineReducers } from "redux";
import {persistReducer} from 'redux-persist';
import AsyncStorage from "@react-native-community/async-storage";
import {tabItems, _getTabItems} from '../constants';
const initialStateForBaseReducer={
    loginPopUp:false,
    allEventsHolidays:{data:[]},
    //allEventsHolidays:[],
    allGalleries:{data:[]},
    //allGalleries:[],
    allOffersDiscount:{data:[]},
    allNews:{data:[]},
    allNotifications:{data:[]},
    allDirectors:[],
    allVacancies:{data:[]},
    syndicateData:[],
    allJobTypes:[]
    // tabItems:[
    //     {title:'Home',image:require('../images/home.png'),activeImg:require('../images/home_active.png'),scene:'home',active:true},
    //     {title:'Offers',image:require('../images/offer.png'),activeImg:require('../images/offer_active.png'),scene:'offer',active:false},
    //     {title:'Events',image:require('../images/eventbtm.png'),activeImg:require('../images/event_active.png'),scene:'events',active:false},
    //     {title:'News',image:require('../images/news.png'),activeImg:require('../images/news_active.png'),scene:'news',active:false},
    //     {title:'More',image:require('../images/more.png'),activeImg:require('../images/more_active.png'),scene:'',active:false}
    // ]
}  
const baseReducer=(state=initialStateForBaseReducer,action)=>{
    switch(action.type){
        case 'toggleLoginUp':
            return {...state,loginPopUp:!state.loginPopUp}
        case 'allEventsHolidays':
            return {...state,allEventsHolidays:action.val}
        case 'allGalleries':
            return {...state,allGalleries:action.val}
        case 'allOffersDiscount':
            return {...state,allOffersDiscount:action.val}
        case 'allNews':
            return {...state,allNews:action.val}
        case 'allNotifications':
            return {...state,allNotifications:action.val}
        case 'allDirectors':
            return {...state,allDirectors:action.val}
        case 'allVacancies':
            return {...state,allVacancies:action.val}
        case 'syndicateData':
            return {...state,syndicateData:action.val}
        case 'allJobTypes':
            return{...state,allJobTypes:action.val}
    }
    return state;
}
const intialStateForTabReducer={
    //tabItems:tabItems,
    tabItems:_getTabItems()
    //hideTabBar:false
    // tabItems:[
    //     {title:'Home',image:require('../images/home.png'),activeImg:require('../images/home_active.png'),scene:'home',active:true},
    //     {title:'Offers',image:require('../images/offer.png'),activeImg:require('../images/offer_active.png'),scene:'offer',active:false},
    //     {title:'Events',image:require('../images/eventbtm.png'),activeImg:require('../images/event_active.png'),scene:'events',active:false},
    //     {title:'News',image:require('../images/news.png'),activeImg:require('../images/news_active.png'),scene:'news',active:false},
    //     {title:'More',image:require('../images/more.png'),activeImg:require('../images/more_active.png'),scene:'',active:false}
    // ]
}
const tabReducer=(state=intialStateForTabReducer,action)=>{
    switch (action.type) {
        case 'tabItems':
            return {...state,tabItems:action.val}
        // case 'hideTabBar':
        //     return {...state,hideTabBar:action.val}
        default :
            return {...state}
    }
}
// const initialAppLang={
//     appLang:null
// }
// const appLang=(state=initialAppLang,action)=>{
//     switch (action.type){
//         case 'changeLang':
//             return {...state,appLang:action.val}
//         default :
//             return {...state}
//     }
// }
const persistConfig={
    key:'root',
    storage:AsyncStorage,
    blacklist:['tabReducer']
}
const allReducer=combineReducers({
    baseReducer,
    tabReducer,
    //appLang
    //tabReducer
})
const rootReducer=persistReducer(persistConfig,allReducer);
export {rootReducer};