import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  StyleSheet,
  Platform,
  TouchableOpacity,
  ToastAndroid,
} from "react-native";
import globalCss, { winWidth, vh, vw } from "./styles/Styles";
import { connect } from "react-redux";
import { actions } from "./store";
import AppLoading from "./components/AppLoading";
import * as FileSystem from "expo-file-system";
import * as WebBrowser from "expo-web-browser";
import PDFReader from "rn-pdf-reader-js";
import { _setStyle, _downloadFileInMobile } from "./service";
import * as Sharing from "expo-sharing";
import CustomText from "./components/CustomText";
if (Platform.OS == "web") {
  var Page = require("./web/PageWithHeaderFooter").Page;
}
const css = StyleSheet.create({
  parent: {
    flex: 1,
    // borderTopColor:'#fff',
    // borderTopWidth:5
  },
  mainCont: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: _setStyle("#fff"),
    //paddingHorizontal:'5%',
    //paddingTop:30
  },
  itemCont: {
    //justifyContent:'center',
    alignItems: "center",
    width: "50%",
    paddingHorizontal: "2%",
    paddingVertical: 20,
    ...globalCss.shadow,
    //borderWidth:1,
    //borderColor:'#fff',
    //borderRadius:1
  },
  itemImg: {
    width: 70,
    height: 70,
    resizeMode: "contain",
  },
  itemTitle: {
    //fontWeight:'bold',
    fontSize: 14,
    textAlign: "center",
    marginVertical: 2,
    ...globalCss.headingFont,
  },
  itemDate: {
    //color:'#E7E8EB',
    ...globalCss.bodyFont,
    fontSize: 14,
    marginVertical: 2,
    ...Platform.select({ web: { opacity: 0.5, color: "#000000" } }),
  },
  itemDownCont: {
    flexDirection: "row",
    marginVertical: 2,
  },
  downloadIcon: {
    width: 18,
    height: 18,
    resizeMode: "contain",
  },
});
class SyndicatePage extends Component {
  constructor(props) {
    super(props);
    this.props._getSyndicateData();
    this.state = {
      items: this.props.syndicateData,
      loading: this.props.syndicateData.length == 0,
      showPdfItem: false,
      currentPdf: "",
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.syndicateData !== prevState.items) {
      return { syndicateData: nextProps.syndicateData };
    } else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.syndicateData !== this.props.syndicateData) {
      const { syndicateData } = this.props;
      this.setState({
        items: syndicateData,
        loading: false,
      });
    }
  }
  _callback = (downloadProgress) => {
    const progress =
      downloadProgress.totalBytesWritten /
      downloadProgress.totalBytesExpectedToWrite;
    console.log("callBack called", progress);
    this.setState({
      downloadProgress: progress,
    });
  };
  _downloadPdf = async (item) => {
    console.log(item);
    _downloadFileInMobile(item.file_path, item.title + ".pdf");
    // try {
    //     let _downloadResumable = FileSystem.createDownloadResumable(
    //         item.file_path,
    //         FileSystem.documentDirectory + item.title,
    //         {},
    //         this._callback
    //     );
    //     const { uri } = await _downloadResumable.downloadAsync();
    //     console.log('Finished downloading to ', uri);
    //     this._readFileAsBinary(uri,item);
    //     //this._shareOrSaveFile(uri);
    // } catch (e) {
    //     console.error(e);
    // }
  };
  _viewPdf = async (item) => {
    if (Platform.OS == "android" || Platform.OS == "ios") {
      this.setState({ currentPdf: item.file_path, showPdfItem: true });
    } else {
      let result = await WebBrowser.openBrowserAsync(item.file_path);
    }
  };
  _readFileAsBinary = async (uri, item) => {
    try {
      let binaryPdf = await FileSystem.readAsStringAsync(uri, {
        encoding: FileSystem.EncodingType.Base64,
      });
      //binaryPdf='data:image/png;base64,'+binaryPdf;
      this._writeFileToAppDocumentDirectory(
        FileSystem.documentDirectory + encodeURI(item.title) + ".pdf",
        binaryPdf
      );
    } catch (e) {
      console.error(e);
    }
  };
  _writeFileToAppDocumentDirectory = async (filePath, fileBase64) => {
    try {
      let written = await FileSystem.writeAsStringAsync(filePath, fileBase64, {
        encoding: FileSystem.EncodingType.Base64,
      });
      console.log(written);
      this._shareOrSaveFile(filePath);
    } catch (e) {
      console.log(e);
    }
  };
  // _shareOrSaveFile=async(item)=>{
  //     // await Sharing.shareAsync(filePath);
  //     if(Platform.OS=='android'||Platform.OS=='ios'){
  //         console.log(item)
  //         _downloadFileInMobile(item.file_path)
  //     }
  // }
  // _isSharingAwavilable=async(filePath)=>{
  //     let resp =await Sharing.shareAsync(filePath);
  //     console.log(resp);
  // }
  _hidePdfViewer = () => {
    this.setState({ currentPdf: "", showPdfItem: false });
  };
  render() {
    const { items, loading, currentPdf, showPdfItem } = this.state;
    if (loading) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <AppLoading />
        </View>
      );
    } else {
      return (
        <View style={css.parent}>
          <PDFViewer
            uri={currentPdf}
            show={showPdfItem}
            hidePdfView={this._hidePdfViewer}
          />
          <ScrollView contentContainerStyle={css.mainCont} horizontal={false}>
            {items.map((item, index) => {
              return (
                <TouchableOpacity
                  onPress={() => this._viewPdf(item)}
                  key={index}
                  style={css.itemCont}
                >
                  <Image
                    style={css.itemImg}
                    source={require("./images/pdf.png")}
                  />
                  <CustomText style={css.itemTitle} value={item.title} />
                  <CustomText style={css.itemDate} value={item.createdDate} />
                  <TouchableOpacity
                    disabled={Platform.OS == "web"}
                    onPress={() => {
                      this._downloadPdf(item);
                    }}
                    style={css.itemDownCont}
                  >
                    <Image
                      style={css.downloadIcon}
                      source={require("./images/downlaod.png")}
                    />
                    <CustomText
                      style={[css.itemDate, { marginLeft: 5 }]}
                      value={item.size}
                    />
                  </TouchableOpacity>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      );
    }
  }
}
function mapStateToProps(state) {
  const { baseReducer } = state;
  return {
    syndicateData: baseReducer.syndicateData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    _getSyndicateData: () => dispatch(actions._getSyndicateData()),
  };
}
const SyndicateView = connect(
  mapStateToProps,
  mapDispatchToProps
)(SyndicatePage);
export default class Syndicate extends Component {
  render() {
    if (Platform.OS != "web") return <SyndicateView />;
    else return <Page {...this.props} DynamicComponent={SyndicateView} />;
  }
}

const pdfcss = StyleSheet.create({
  mainCont: {
    position: "absolute",
    top: 0,
    left: 0,
    width: winWidth,
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 2,
  },
  cancelIconCont: {
    height: "10%",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelIcon: {
    color: "#fff",
    fontSize: 6 * vw,
  },
  loadText: {
    color: "#fff",
  },
  pdfCont: {
    height: "90%",
  },
});

class PDFViewer extends Component {
  state = { loading: true };
  render() {
    const { uri, show, hidePdfView } = this.props;
    if (show) {
      return (
        <View style={pdfcss.mainCont}>
          <View style={pdfcss.cancelIconCont}>
            <TouchableOpacity onPress={hidePdfView}>
              <CustomText style={pdfcss.cancelIcon} name={"close"} />
            </TouchableOpacity>
          </View>
          <View style={pdfcss.pdfCont}>
            <PDFReader
              source={{ uri: uri }}
              onLoad={() => this.setState({ loading: false })}
              onError={() => {
                this.setState({ loading: false });
                ToastAndroid.show(
                  "Error while loading pdf",
                  ToastAndroid.SHORT
                );
              }}
            />
            {this.state.loading && (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 60,
                }}
              >
                <CustomText style={pdfcss.loadText} name={"pdf_loading"} />
              </View>
            )}
          </View>
        </View>
      );
    } else return null;
  }
}
