import React,{Component } from "react";
import { View,Text,Image,ScrollView,StyleSheet,Platform,TouchableOpacity,TextInput,FlatList} from "react-native";
import {Actions} from 'react-native-router-flux';
import MobileNav from './components/mobileNav';
import { SliderBox } from "react-native-image-slider-box";
import globalCss, {winWidth,winHeight, bodyFontColor, vh} from './styles/Styles';
import ZoomImg from "./components/ZoomImg";
import {api,_checkStatus,_getWeekDay,_testImage,_parseHtml, _parseDate} from './service';
//import LoginPopUp from './components/LoginPopUp';
import {actions} from './store';
import {connect} from 'react-redux';
import AppLoading from "./components/AppLoading";
import ImageLoader from "./components/ImageLoader";
import CustomText from "./components/CustomText";
import { t } from "./langs";
import { _appCurrentLang } from "./constants";
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
const css=StyleSheet.create({
    main:{
        //flexGrow:1,
        flex:1,
        backgroundColor:'#EEEDF0',
        paddingHorizontal:10,
        paddingVertical:10
    },searchCont:{
        borderRadius:2,
        flexDirection:'row',
        height:(6*vh)<40?40:(6*vh),
        backgroundColor:'#fff',
        alignItems:'center'
    },searchIcon:{
        height:3*vh,
        width:'10%',
        resizeMode:'contain'
    },searchInput:{
        width:'90%',
        ...globalCss.bodyFont,
        ...Platform.select({
            web: {
                outlineColor:"#ffffff00"
            }
        }),
    },imgIcon:{
        width:12,
        height:12,
        resizeMode:'contain'
    },itemCont:{
        flexDirection:'row',
        borderRadius:20,
        backgroundColor:'#fff',
        marginVertical:10,
        ...globalCss.shadow
        //paddingHorizontal:5
    },itemImg:{
        borderRadius:20,
        height:120,
        width:'30%',
        resizeMode:'cover'
    },titleCont:{
        width:'70%',
        height:120,
        paddingHorizontal:10,
        paddingVertical:5,
        justifyContent:'center'
    },offerImg:{
        height:18,
        width:18,
        resizeMode:'contain'
    },itemTitle:{
        textAlign:'left',
        fontSize:15,
        paddingVertical:Platform.select({web:2,default:0}),
        ...globalCss.headingFont
    },description:{
        textAlign:'left',
        ...globalCss.bodyFont,
        fontSize:12,
        paddingVertical:Platform.select({web:2,default:0}),
        marginBottom:5
    },viewCont:{
        backgroundColor:'#EEEDF0',
        borderRadius:5,
        padding:5,
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row',
        width:'28%'
    },viewTxt:{
        color:'#24255E',
        ...globalCss.bodyFontFamily,
        fontSize:12,
        marginHorizontal:2
    },eyeIcon:{
        height:12,
        width:12,
        resizeMode:'contain',
        marginHorizontal:2
    }
})
class OffersPage extends Component{
    constructor(props){
        super(props);
        this.state={
            search:'',
            offers:this.props.allOffersDiscount.data,
            loading:this.props.allOffersDiscount.data.length==0
        }
        this.callLoadMore=false,
        this.current_page=1
    }
    componentDidMount(){
        this.props._getAllOffers();
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.allOffersDiscount!==prevState.offers){
            return { allOffersDiscount: nextProps.allOffersDiscount};
        }else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.allOffersDiscount!==this.props.allOffersDiscount){
            const {allOffersDiscount}=this.props;
            this.setState({offers:allOffersDiscount.data,loading:false},()=>{
                this._checkIfToRenderNextPage(allOffersDiscount);
            });
          }
    }
    _handleSearch=async (txt)=>{
        this.setState({search:txt,loading:true});
        if(txt!=undefined||txt!=''){
            let search=txt.toLocaleLowerCase();
            let searchResults=await api.get('offer-and-discounts/search?q='+search);
            //console.log(searchResults,'search ');
            if(_checkStatus(searchResults)){
                this._checkIfToRenderNextPage(searchResults.value.offer_discounts);
                searchResults=searchResults.value.offer_discounts.data.map((item)=>{
                    return{
                        title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                        day:_getWeekDay(item.created_at),
                        date:_parseDate(item.created_at),
                        image:item.image||_testImage,
                        description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                        id:item.id
                    }
                });
                this.setState({
                    offers:searchResults,
                    loading:false
                    //search:txt
                });
            }
        }else{
            this.props._getAllOffers();
        }
        // let offers=this._allOffers.filter((ofr)=>{
        //     return ofr.title.toLocaleLowerCase().includes(search)||ofr.description.includes(search)
        // })
        // this.setState({search,offers});
    }
    _goToDetail=(item)=>{
        //console.log(index);
        if(Platform.OS!='web')
            Actions.offerdetails({id:item.id,title:item.title});
        else this.props.history.push(`/offerdetails/${item.id}`);
    }
    _loadMoreOffers=async(v)=>{
        console.log('load more called offers',v,this.callLoadMore,this.current_page);
        const {offers}=this.state;
        if(this.callLoadMore){
            this.setState({loading:true});
            let resp=await api.get('offer-and-discounts?page='+(this.current_page+1));
            if(_checkStatus(resp)){
                resp=resp.value.offer_discounts;
                resp.data=resp.data.map((item)=>{
                    return{
                        title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                        day:_getWeekDay(item.created_at),
                        date:_parseDate(item.created_at),
                        image:item.image||_testImage,
                        description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                        id:item.id
                    }
                });
                this.setState({offers:[...offers,...resp.data],loading:false},()=>{
                    this._checkIfToRenderNextPage(resp);
                });
            }
        }else return;

    }
    _checkIfToRenderNextPage=(dataObj)=>{
        if(dataObj.next_page_url!=null){
            this.callLoadMore=true;
            this.current_page=dataObj.current_page;
            //this.next_page_url=allEventsHolidays.next_page_url;

        }else{
            this.callLoadMore=false;
        }
    }
    render(){
        const {search,offers,loading}=this.state;
        return(
            <View style={css.main}>
                {/* <LoginPopUp show={this.props.loginPopUp}/> */}
                <View style={css.searchCont}>
                    <Image style={css.searchIcon} source={require('./images/search.png')}/>
                    <TextInput
                        value={search}
                        onChangeText={this._handleSearch}
                        placeholder={t('search')}
                        style={css.searchInput}
                    />
                </View>
                <FlatList
                    showsVerticalScrollIndicator={true}
                    data={offers}
                    onEndReached={(v)=>{this._loadMoreOffers(v)}}
                    onEndReachedThreshold={0.5}
                    contentContainerStyle={{flexGrow:1}}
                    ListFooterComponent={loading?<AppLoading style={{alignSelf:'center'}}/>:null}
                    renderItem={({item})=>{
                        return(
                            <View  style={css.itemCont}>
                                <View style={css.titleCont}>
                                   <Image style={css.offerImg} source={require('./images/tag.png')}/>
                                   <CustomText numberOfLines={1} style={css.itemTitle} value={item.title}/>
                                   <CustomText numberOfLines={2} style={css.description} value={item.description}/>
                                   <TouchableOpacity style={css.viewCont} onPress={()=>this._goToDetail(item)}>
                                        <CustomText style={css.viewTxt} name={'view'}/>
                                        <Image style={css.eyeIcon} source={require('./images/view.png')}/>
                                   </TouchableOpacity>
                                </View>
                                <ImageLoader style={css.itemImg} source={{uri:item.image}}/>
                            </View>
                        )
                    }}
                    keyExtractor={item=>item.id+''}
                />
            </View>
        )
    }
}
class Offers extends Component{
    render(){
        if(Platform.OS!='web')
            return (<OffersView />)
        else return (<Page {...this.props} DynamicComponent={OffersView}/>)
    }
}
function mapStateToProps(state){
    const {baseReducer}=state
    return {
        //loginPopUp:state.loginPopUp,
        allOffersDiscount:baseReducer.allOffersDiscount
    }
  }
  function mapDispatchToProps(dispatch){
    return{
        //toggleLoginUp:()=>dispatch({'type':'toggleLoginUp'})
        _getAllOffers:()=>dispatch(actions._getAllOffers()),
    }
}
const OffersView= connect(mapStateToProps,mapDispatchToProps)(OffersPage);
export default Offers;
const detailsCss=StyleSheet.create({
    title:{
        textAlign:'left',
        fontSize:18,
        ...globalCss.headingFont
    },dateCont:{
        flexDirection:'row',
        //justifyContent:'center',
        alignItems:'center',
        marginVertical:5,
        
    },date:{
        color:'#24255E',
        fontSize:12,
        marginHorizontal:3,
        ...globalCss.bodyFontFamily
    },img:{
        width:'100%',
        borderRadius:5,
    },descCont:{
        backgroundColor:'#fff',
        paddingVertical:10,
        //marginTop:20
    },desc:{
        textAlign:'justify',
        fontSize:16,
        paddingHorizontal:10,
        ...globalCss.bodyFontFamily
    },topCont:{
        backgroundColor:'#EEEDF0',
        paddingHorizontal:10,
        paddingVertical:20
    }
})
class OffersDetailView extends Component{
    state={
        imagesForOffers:[],
        showZoomImg:false,
        index:null,
        item:{},
        id:null,
        title:'',
        loading:false
        
    }
    componentDidMount(){
        const {id,title}=Platform.OS!='web'?this.props:this.props.match.params;
        this.setState({id,title,loading:true},()=>{
            this._getOfferItem(id);
        });
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.id!=prevState.id&&Platform.OS!='web'){
            return { id: nextProps.id,title:nextProps.title};
        }else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.id!==this.props.id){
            const {id,title}=this.props;
            this.setState({id,title,loading:true},()=>{
                this._getOfferItem(id);
            });
          }
    }
    _getOfferItem=async (Id)=>{
        let offer=await api.get(`offer-and-discounts/${Id}`);
        if(_checkStatus(offer)){
            console.log(JSON.parse(JSON.stringify(offer)));
            offer=offer.value.offer_discount;
            offer={
                title:_appCurrentLang=='ar'?offer.arabic_title : offer.title,
                day:_getWeekDay(offer.created_at),
                date:_parseDate(offer.created_at),
                image:offer.image,
                description:_appCurrentLang=='ar'?_parseHtml(offer.arabic_description) :_parseHtml(offer.description),
                imagesForOffers:(typeof offer.image=='string'?[offer.image]:typeof offer.image=='object'?offer.image:[])||[_testImage]
            }
            this.setState({
                item:offer,
                imagesForOffers:offer.imagesForOffers,
                loading:false
            })

        }
    }
    _hideZoomImg=()=>{
        this.setState({showZoomImg:false});
    }
    _showZoomImg=()=>{
        this.setState({showZoomImg:true});
    }
    render(){
            //const {id}=Platform.OS!='web'?this.props:this.props.match.params;
            const {imagesForOffers,showZoomImg,item,loading}=this.state;
        if(loading){
            return (
                <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
                    <AppLoading/>
                </View>
            )
        }else return(
            <View style={{flex:1,backgroundColor:'#fff'}}>
                <ScrollView contentContainerStyle={{flexGrow:1}}>
                    <ZoomImg images={imagesForOffers} show={showZoomImg} _closeZoomImg={this._hideZoomImg} />
                    <View style={detailsCss.topCont}>
                        <CustomText style={detailsCss.title} value={item.title}/>
                        <View style={detailsCss.dateCont}>
                            <Image style={css.imgIcon} source={require('./images/date.png')}/>
                            <CustomText style={detailsCss.date} value={item.day}/>
                            <CustomText style={detailsCss.date} value={item.date}/>
                        </View>
                        <SliderBox
                            inactiveDotColor={bodyFontColor}
                            onCurrentImagePressed={this._showZoomImg}
                            style={{
                                borderRadius:20,
                                height:24*vh,
                            }}
                            images={imagesForOffers}
                            dotColor="#2E2F77"
                            //ImageComponent={ImageLoader}
                            autoplay
                            resizeMethod={'resize'}
                            resizeMode={'cover'}
                            circleLoop
                            parentWidth={winWidth-20}
                            paginationBoxStyle={{
                                position: "absolute",
                                bottom: Platform.select({web:-10,default:-20}),

                                //padding: 0,
                                alignItems: "center",
                                alignSelf: "center",
                                justifyContent: "center",
                                
                                //paddingVertical: 20,
                                //paddingHorizontal:50,
                                //backgroundColor:'red'
                            }}
                            dotStyle={{
                                height:6,
                                width:16,
                                borderRadius:5
                            }}
                        />
                    </View>
                    <View style={detailsCss.descCont}>
                        <CustomText style={detailsCss.desc}
                           value= {item.description}
                        />
                    </View>
                </ScrollView>
            </View>
        )
    }
}
class OffersDetail extends Component{
    render(){
        if(Platform.OS!='web')
            return (<OffersDetailView {...this.props}/>)
        else return (<Page {...this.props} DynamicComponent={OffersDetailView}/>)
    }
}
export {OffersDetail}
