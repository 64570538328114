import React,{Component } from "react";
import { View,Text,Image,ScrollView,StyleSheet,Platform,TouchableOpacity,TextInput,FlatList,SafeAreaView} from "react-native";
import {Actions} from 'react-native-router-flux';
import AppLoading from './components/AppLoading';
import ZoomImg from "./components/ZoomImg";
import { SliderBox } from "react-native-image-slider-box";
import {api,_getWeekDay,_checkStatus,_parseDate,_parseHtml, localStore, _goto} from './service';
//import LoginPopUp from './components/LoginPopUp';
import {connect} from 'react-redux';
import {actions} from './store';
import globalCss, {winWidth, bodyFontColor, vh, vw} from './styles/Styles'
import ImageLoader from "./components/ImageLoader";
import { _setPreviousProps, _setPreviousRoute, _appCurrentLang } from "./constants";
import CustomText from "./components/CustomText";
import { t } from "./langs";
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
const css=StyleSheet.create({
    main:{
        //flexGrow:1,
        flex:1,
        backgroundColor:'#EEEDF0',
        paddingHorizontal:10,
        paddingVertical:10
    },searchCont:{
        borderRadius:2,
        flexDirection:'row',
        height:(6*vh)<40?40:(6*vh),
        backgroundColor:'#fff',
        alignItems:'center'
    },searchIcon:{
        height:3*vh,
        width:'10%',
        resizeMode:'contain'
    },searchInput:{
        width:'90%',
        ...globalCss.bodyFont,
        ...Platform.select({
            web: {
                outlineColor:"#ffffff00"
            }
        }),
    },itemCont:{
        flexDirection:'row',
        borderRadius:15,
        backgroundColor:'#fff',
        marginVertical:10,
        ...globalCss.shadow
    },itemImg:{
        borderRadius:5,
        height:100,
        width:'30%',
        resizeMode:'cover'
    },titleCont:{
        width:'70%',
        height:100,
        padding:15,
        justifyContent:'center'
    },title:{
        textAlign:'left',
        fontSize:18,
        ...globalCss.headingFont
    },dateCont:{
        marginTop:10,
        flexDirection:'row'
    },imgIcon:{
        width:12,
        height:12,
        resizeMode:'contain'
    },itemDayCont:{
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center'
    },dayTxt:{
        //color:'#989CAE',
        fontSize:12,
        marginHorizontal:2,
        ...globalCss.bodyFont
    }
})
class EventsPage extends Component{
    constructor(props){
        super(props);
        this.state={
            search:'',
            events:this.props.allEventsHolidays.data,
            loading:this.props.allEventsHolidays.data.length==0
        }
        
       this.callLoadMore=false;
       this.current_page=1;
    }
    async componentDidMount(){
        this.props._getAllEvents();
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.allEventsHolidays!==prevState.events){
            return { allEventsHolidays: nextProps.allEventsHolidays};
        }else return null;
    }
    // shouldComponentUpdate(nextProps,nextState){
    //     if(nextProps.allEventsHolidays!==this.props.allEventsHolidays){
    //       return true;
    //     }else{
    //       return false;
    //     }
    // }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.allEventsHolidays!==this.props.allEventsHolidays){
            const {allEventsHolidays}=this.props;
            this.setState({events:allEventsHolidays.data,loading:false},()=>{
                this._checkIfToRenderNextPage(allEventsHolidays);
            });
          }
    }
    _checkIfToRenderNextPage=(dataObj)=>{
        if(dataObj.next_page_url!=null){
            this.callLoadMore=true;
            this.current_page=dataObj.current_page;
            //this.next_page_url=allEventsHolidays.next_page_url;

        }else{
            this.callLoadMore=false;
        }
    }
    _handleSearch=async (txt)=>{
        this.setState({search:txt,loading:true});
        if(txt!=undefined||txt!=''){
            let search=txt.toLocaleLowerCase();
            let searchResults=await api.get('event-and-holidays/search?q='+search);
            if(_checkStatus(searchResults)){
                this._checkIfToRenderNextPage(searchResults.value.event_holidays);
                searchResults=searchResults.value.event_holidays.data.map((item)=>{
                    return{
                        title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                        day:_getWeekDay(item.created_at),
                        date:_parseDate(item.created_at),
                        image:item.image||_testImage,
                        description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                        id:item.id
                    }
                });
                this.setState({
                    events:searchResults,
                    loading:false
                });
            }
        }else{
            this.props._getAllEvents();
        }
    }
    _loadMoreEvents=async(v)=>{
        console.log('load more called events',v,this.callLoadMore,this.current_page);
        const {events}=this.state;
        if(this.callLoadMore){
            this.setState({loading:true});
            let resp=await api.get('event-and-holidays?page='+(this.current_page+1));
            if(_checkStatus(resp)){
                resp=resp.value.event_holidays;
                resp.data=resp.data.map((item)=>{
                    return{
                        title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                        day:_getWeekDay(item.created_at),
                        date:_parseDate(item.created_at),
                        image:item.image||_testImage,
                        description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                        id:item.id
                    }
                });
                this.setState({events:[...events,...resp.data],loading:false},()=>{
                    this._checkIfToRenderNextPage(resp);
                });
            }
        }else return;

    }
    _goToDetail=(item)=>{
        if(Platform.OS!='web')
            Actions.eventsdetails({id:item.id,title:item.title});
        else this.props.history.push(`/eventsdetails/${item.id}`);
    }
    render(){
        const {search,events,loading}=this.state;
        return(
            <View style={css.main}>
                <View style={css.searchCont}>
                    <Image style={css.searchIcon} source={require('./images/search.png')}/>
                    <TextInput
                        value={search}
                        onChangeText={this._handleSearch}
                        placeholder={t('search')}
                        style={css.searchInput}
                    />
                </View>
                <FlatList
                    showsVerticalScrollIndicator={true}
                    data={events}
                    onEndReached={(v)=>{this._loadMoreEvents(v)}}
                    onEndReachedThreshold={0.5}
                    contentContainerStyle={{flexGrow:1}}
                    ListFooterComponent={loading?<AppLoading />:null}
                    renderItem={({item})=>{
                        return(
                            <TouchableOpacity key={item.id+''}  style={css.itemCont} onPress={()=>this._goToDetail(item)}>
                                <ImageLoader style={css.itemImg} source={{uri:item.image}}/>
                                <View style={css.titleCont}>
                                    <CustomText numberOfLines={1} style={css.title} value={item.title}/>
                                    <View style={css.dateCont}>
                                        <View style={css.itemDayCont}>
                                            <Image style={css.imgIcon} source={require('./images/date.png')}/>
                                            <CustomText style={css.dayTxt} value={item.day}/>
                                        </View>
                                        <View style={[css.itemDayCont,{marginLeft:20}]}>
                                            <Image style={css.imgIcon} source={require('./images/clock.png')}/>
                                            <CustomText style={css.dayTxt} value={item.date}/>
                                        </View>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        )
                    }}
                    keyExtractor={item=>item.id+''}
                />
            </View>
        )
    }
}
function mapStateToProps(state){
    const {baseReducer}=state
    return {
        //loginPopUp:state.loginPopUp,
        allEventsHolidays:baseReducer.allEventsHolidays
    }
}
function mapDispatchToProps(dispatch){
    return{
        //toggleLoginUp:()=>dispatch({'type':'toggleLoginUp'})
        _getAllEvents:()=>dispatch(actions._getAllEvents()),
    }
}
const EventsView=connect(mapStateToProps,mapDispatchToProps)(EventsPage);
export default class Events extends Component{
    render(){
        if(Platform.OS!='web')
            return (<EventsView/>)
        else return (<Page {...this.props} DynamicComponent={EventsView}/>)
    }
}
const detailsCss=StyleSheet.create({
    title:{
        textAlign:'left',
        fontSize:18,
        ...globalCss.headingFont
    },dateCont:{
        flexDirection:'row',
        alignItems:'center',
        marginVertical:5
    },date:{
        color:'#24255E',
        fontSize:12,
        marginHorizontal:3,
        ...globalCss.bodyFontFamily
    },img:{
        width:'100%',
        borderRadius:5,
    },descCont:{
        backgroundColor:'#fff',
        paddingVertical:10,
        //marginTop:20
    },desc:{
        textAlign:'justify',
        fontSize:16,
        paddingHorizontal:10,
        ...globalCss.bodyFont
    },topCont:{
        backgroundColor:'#EEEDF0',
        paddingHorizontal:10,
        paddingVertical:20
    },registerButton:{
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#333482',
        height:5*vh,
        width:70
    },registerButtonText:{
        color:'#fff',
        fontSize:2*vh
    }
})
class EventsDetailView extends Component{
    state={
        events:[],
        imagesForEvent:[],
        item:{},
        id:null,
        title:'',
        showZoomImg:false,
        loading:false,
        showRegisterButton:false,
        registerLoading:false
    }
    componentDidMount(){
        const {id,title}=Platform.OS!='web'?this.props:this.props.match.params;
        this.setState({id,title,loading:true},()=>{
            this._getEventItem(id);
        });
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.id!=prevState.id&&Platform.OS!='web'){
            return { id: nextProps.id,title:nextProps.title};
        }else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.id!==this.props.id){
            const {id,title}=this.props;
            this.setState({id,title,loading:true},()=>{
                this._getEventItem(id);
            });
          }
    }
    _getEventItem=async (Id)=>{
        let event=await api.get(`event-and-holidays/${Id}`);
        console.log(event);
        if(_checkStatus(event)){
            event=event.value.event_holiday;
            event={
                title:_appCurrentLang=='ar'?event.arabic_title : event.title,
                day:_getWeekDay(event.created_at),
                date:_parseDate(event.created_at),
                image:event.image,
                description:_appCurrentLang=='ar'?_parseHtml(event.arabic_description) :_parseHtml(event.description),
                imagesForEvent:[event.image]||[_testImage],
                showRegisterButton:event.type!=null&&event.type=='set_limit'&&event.limit!=null,
                id:event.id
            }
            this.setState({
                item:event,
                imagesForEvent:event.imagesForEvent,
                loading:false,
                showRegisterButton:event.showRegisterButton
            })

        }
    }
    _registerForEvent=async()=>{
        this.setState({registerLoading:true});
        const {item,id}=this.state;
        let userId=await localStore.get('userId');
        let accessToken=await localStore.get('accessToken');
        if(_checkStatus(userId)&&_checkStatus(accessToken)){
            userId=userId.value;
            let register=await api.post('event-and-holidays/join',{event_holiday_id:item.id,user_id:parseInt(userId)},true);
            if(_checkStatus(register)){
                this.setState({registerLoading:false});
                alert(t('succesfully_registered_for_the_event'));
            }else{
                this.setState({registerLoading:false});
            }
        }else{
            this.setState({registerLoading:false});
            _setPreviousRoute('eventsdetails');
            _setPreviousProps({id:id});
            _goto(this.props,'login');
        }
    }
    _hideZoomImg=()=>{
        this.setState({showZoomImg:false});
    }
    _showZoomImg=()=>{
        this.setState({showZoomImg:true});
    }
    render(){
        //const {id}=Platform.OS!='web'?this.props:this.props.match.params;
        const {item,imagesForEvent,showZoomImg,loading,showRegisterButton,registerLoading}=this.state;
        if(loading){
            return (
                <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
                    <AppLoading/>
                </View>
            )
        }else return(
            <View style={{flex:1,backgroundColor:'#fff'}}>
                <ScrollView contentContainerStyle={{flexGrow:1}}>
                    <ZoomImg images={imagesForEvent} show={showZoomImg} _closeZoomImg={this._hideZoomImg} />
                    <View style={detailsCss.topCont}>
                        <View style={[detailsCss.dateCont,{flex:1}]}>
                            <CustomText numberOfLines={1} style={[detailsCss.title,{flex:3}]} value={item.title}/>
                            {showRegisterButton?
                                <TouchableOpacity onPress={this._registerForEvent} style={[detailsCss.registerButton,{flex:1}]}>
                                    {registerLoading===false
                                        ?<CustomText style={detailsCss.registerButtonText} name={'register'}/>
                                        :<AppLoading color={'#fff'} size={'small'}/>
                                    }
                                </TouchableOpacity>
                                :null
                            }
                        </View>
                        <View style={detailsCss.dateCont}>
                            <Image style={css.imgIcon} source={require('./images/eventdate.png')}/>
                            <CustomText style={detailsCss.date} value={item.day}/>
                            <CustomText style={detailsCss.date} value={item.date}/>
                        </View>
                        <SliderBox
                            inactiveDotColor={bodyFontColor}
                            
                            images={imagesForEvent}
                            onCurrentImagePressed={this._showZoomImg}
                            dotColor="#2E2F77"
                            //ImageComponent={ImageLoader}
                            autoplay
                            resizeMethod={'resize'}
                            resizeMode={'cover'}
                            circleLoop
                            style={{height:24*vh,borderRadius:10}}
                            parentWidth={winWidth-20}
                            paginationBoxStyle={{
                                position: "absolute",
                                bottom: Platform.select({web:-10,default:-20}),

                                //padding: 0,
                                alignItems: "center",
                                alignSelf: "center",
                                justifyContent: "center",
                                //paddingVertical: 20,
                                //paddingHorizontal:50,
                                //backgroundColor:'red'
                            }}
                            dotStyle={{
                                height:6,
                                width:16,
                                borderRadius:5
                            }}
                        />
                    </View>
                    <View style={detailsCss.descCont}>
                        <CustomText style={detailsCss.desc}
                            value={item.description}
                        />
                    </View>
                </ScrollView>
            </View>
        )
    }
}
class EventsDetail extends Component{
    render(){
        if(Platform.OS!='web')
            return (<EventsDetailView {...this.props}/>)
        else return (<Page {...this.props} DynamicComponent={EventsDetailView}/>)
    }
}
export {EventsDetail}
