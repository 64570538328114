import {api,_checkStatus,_testImage,_parseDate,_parseHtml,_getWeekDay,_handleBackPress,_iconClicked,_getTime} from '../service';
import {_setConstantTabItems, _appCurrentLang} from '../constants';
const showLogin=val=>{
    return dispatch=>{
        setTimeout(() => {
            dispatch( {'type':'toggleLoginUp'})
        },1000);
    }
}
const _setTabItems=(allTabs,presseDItem,renderNextScreen)=>{
    return async dispatch=>{
        let updatedTabs=[];
        if(renderNextScreen){
            updatedTabs= _iconClicked(allTabs,presseDItem,renderNextScreen);
            _setConstantTabItems(JSON.parse(JSON.stringify(updatedTabs)));
            dispatch( {'type':'tabItems',val:updatedTabs});
        }else{
            updatedTabs=await _handleBackPress(allTabs,presseDItem,renderNextScreen);
            _setConstantTabItems(JSON.parse(JSON.stringify(updatedTabs)));
            dispatch( {'type':'tabItems',val:updatedTabs});
        }
    }
}
const _getAllEvents=()=>{
    return async dispatch=>{
        let allEventsHolidays=await api.get('event-and-holidays');
        if(_checkStatus(allEventsHolidays)){
            allEventsHolidays=allEventsHolidays.value.event_holidays;
            allEventsHolidays.data=allEventsHolidays.data.map((item)=>{
                return {
                    title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                    day:_getWeekDay(item.created_at),
                    date:_parseDate(item.created_at),
                    image:item.image||_testImage,
                    id:item.id,
                    description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                }
            });
            dispatch({'type':'allEventsHolidays',val:allEventsHolidays})
        }else{
            dispatch( {'type':'allEventsHolidays',val:{data:[]}})
        }
    }
}
const _getAllGalleries=()=>{
    return async dispatch=>{
        let allGalleries=await api.get('galleries');
        if(_checkStatus(allGalleries)){  
            allGalleries=allGalleries.value.galleries;
            allGalleries.data=allGalleries.data.map((img)=>{
                return {
                    date:_parseDate(img.created_at),
                    image:img.cover_photo,
                    title:_appCurrentLang=='ar'?img.arabic_event_name:img.event_name,
                    allImages:img.images,
                    id:img.id
                }
            })
            dispatch( {'type':'allGalleries',val:allGalleries})
        }else{
            dispatch( {'type':'allGalleries',val:{data:[]}})
        }
    }
}
const _getAllOffers=()=>{
    return async dispatch=>{
        let allOffersDiscount=await api.get('offer-and-discounts');
        console.log(allOffersDiscount);
        if(_checkStatus(allOffersDiscount)){
            allOffersDiscount=allOffersDiscount.value.offer_discounts;
            allOffersDiscount.data=allOffersDiscount.data.map((item)=>{
                return{
                    image:item.image||_testImage,
                    description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                    title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                    day:_getWeekDay(item.created_at),
                    date:'22 July 2020',
                    id:item.id
                }
            })
            dispatch( {'type':'allOffersDiscount',val:allOffersDiscount})
        }else{
            dispatch( {'type':'allOffersDiscount',val:{data:[]}})
        }
    }
}
const _getAllNews=()=>{
    return async dispatch=>{
        let allNews=await api.get('news');
        console.log(allNews);
        if(_checkStatus(allNews)){
            allNews=allNews.value.news;
            //console.log(JSON.parse(JSON.stringify(allNews)));
            allNews.data=allNews.data.map((item)=>{
                return{
                    title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                    day:_getWeekDay(item.created_at),
                    date:_parseDate(item.created_at),
                    createdTime:_getTime(item.created_at),
                    image:item.image||_testImage,
                    description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                    id:item.id
                }
            });
            dispatch( {'type':'allNews',val:allNews});
        }else 
            dispatch( {'type':'allNews',val:{data:[]}})
    }

}
const _getAllNotifications=()=>{
    return async dispatch=>{
        let notifications=await api.get('notifications');
        console.log(notifications);
        if(_checkStatus(notifications)){
            notifications=notifications.value.notifications;
            console.log(JSON.parse(JSON.stringify(notifications)));
            notifications.data=notifications.data.map((item)=>{
                return{
                    title:_appCurrentLang=='ar'?item.arabic_title : item.title,
                    date:_parseDate(item.created_at),
                    image:item.image||_testImage,
                    description:_appCurrentLang=='ar'?_parseHtml(item.arabic_description) :_parseHtml(item.description),
                    id:item.id
                }
            });
            dispatch( {'type':'allNotifications',val:notifications})
        }else{
            dispatch( {'type':'allNotifications',val:[]})
        }
    }
}
const _getAllDirectors=()=>{
    return async dispatch=>{
        let directors=await api.get('board-of-directors');
        if(_checkStatus(directors)){
            console.log(JSON.parse(JSON.stringify(directors)));
            directors=directors.value.user.map((item)=>{
                return{
                    toSetPriority:item.designation_english,
                    designation:_appCurrentLang=='ar' ?item.designation_arabic: item.designation_english,
                    name:_appCurrentLang=='ar'?item.arabic_name: item.english_name,
                    mobile_no: item.mobile_no,
                    image:item.image,
                    photo_id:item.photo_no,
                    image:directors.value.img_path+item.photo_no+'.jpg'
                }
            });
            dispatch( {'type':'allDirectors',val:directors})
        }else{
            dispatch( {'type':'allDirectors',val:[]})
        }
    }
}
const _getAllVacancies=()=>{
    return async dispatch=>{
        let vacancies=await api.get('vacancies');
        if(_checkStatus(vacancies)){
            vacancies=vacancies.value.vacancies;
            console.log(JSON.parse(JSON.stringify(vacancies)))
            vacancies.data=vacancies.data.map((item)=>{
                return{
                    id:item.id,
                    expiry_date:_parseDate(item.expiry_date),
                    created_at:_parseDate(item.created_at),
                    number_of_vacancies:item.number_of_vacancies,
                    from_department:item.from_department,
                    to_department:item.to_department,
                    type:item.type
                }
            });
            dispatch( {'type':'allVacancies',val:vacancies})
        }else{
            dispatch( {'type':'allVacancies',val:{data:[]}})
        }
    }
}
const _getSyndicateData=()=>{
    return async dispatch=>{
        let syndicate=await api.get('syndicatedata');
        if(_checkStatus(syndicate)){
            let pdfPath=syndicate.value.img_path;
            syndicate=syndicate.value;
            console.log(JSON.parse(JSON.stringify(syndicate)))
            syndicate.data=syndicate.data.map((item)=>{
                return{
                    createdDate:_parseDate(item.created_at),
                    id: item.data_id,
                    file_path: pdfPath+'/'+item.file_path+'.pdf',
                    title: _appCurrentLang=='ar'?item.arabic_title||item.title:item.title
                }
            })
            dispatch({type:'syndicateData',val:syndicate.data});
        }else {
            dispatch({type:'syndicateData',val:[]});
        }
    }
}
const _getAllJobTypes=()=>{
    return async dispatch=>{
        let jobtypes=await api.get('allcalendar');
        if(_checkStatus(jobtypes)){
            console.log(jobtypes.value);
            jobtypes=jobtypes.value.calendar.map((item,index)=>{
                return{
                    created_at:_parseDate(item.created_at),
                    half_days: item.half_days,
                    id: item.id,
                    job_type: item.job_type,
                    leaves: item.leaves,
                    //selected:index==0?true:false
                }
            })
            dispatch({type:'allJobTypes',val:jobtypes});
        }else {
            dispatch({type:'allJobTypes',val:[]});
        }
    }
}
export {
    showLogin,
    _getAllEvents,
    _getAllOffers,
    _getAllGalleries,
    _getAllNews,
    _getAllNotifications,
    _getAllDirectors,
    _getAllVacancies,
    _setTabItems,
    _getSyndicateData,
    _getAllJobTypes
}