import React, { Component } from "react";
import {
  Platform,
  I18nManager,
  ImageBackground,
  SafeAreaView,
} from "react-native";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { rootReducer } from "./src/store";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { useFonts } from "@expo-google-fonts/inter";
import { AppLoading } from "expo";
import { localStore, _checkStatus, _setStyle } from "./src/service";
import globalCss, { themeColor } from "./src/styles/Styles";
import { _setLocaleForApp } from "./src/langs";
import * as SplashScreen from "expo-splash-screen";
import * as Sentry from "sentry-expo";
import * as Font from "expo-font";
import { _getTabItems } from "./src/constants";
import Splash from "./src/Splash";
import { _setTabItems } from "./src/store/action";
if (Platform.OS == "web") {
  var Router = require("./src/routing/webRoutes").RouterCom;
} else {
  var Router = require("./src/routing/mobileRoutes").RouterCom;
}
Sentry.init({
  dsn:
    "https://c971c7a44efe4d41911699d8cd5fae79@o498612.ingest.sentry.io/5576341",
  enableInExpoDevelopment: true,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});
const store = createStore(rootReducer, applyMiddleware(thunk));
const persistedStore = persistStore(store);
let customFonts = {
  Roboto: require("./assets/fonts/Roboto-Medium.ttf"),
  Poppins: require("./assets/fonts/Poppins-Medium.ttf"),
};
export default class App extends Component {
  state = {
    fontsLoaded: false,
  };
  async componentDidMount() {
    // Prevent native splash screen from autohiding
    try {
      await SplashScreen.preventAutoHideAsync();
      _setLocaleForApp();
      if (Platform.OS == "android" || Platform.OS == "ios") {
        let tabItems = _getTabItems();
        _setTabItems(tabItems);
        //this.props._setTabItems(tabItems,null,null);
      }
      //this._gotoNext('/home','drawermenu');
    } catch (e) {
      console.warn(e);
    }
    this.prepareResources();
  }
  prepareResources = async () => {
    await Font.loadAsync(customFonts);
    this.setState({ fontsLoaded: true }, async () => {
      await SplashScreen.hideAsync();
    });
  };
  render() {
    const { fontsLoaded } = this.state;
    if (!fontsLoaded) {
      return null;
    } else if (Platform.OS == "ios" || Platform.OS == "android") {
      return (
        <ImageBackground
          source={require("./src/images/appBackground.png")}
          style={{ width: "100%", height: "100%" }}
        >
          <SafeAreaView style={globalCss.firstPage}>
            <Provider store={store}>
              <PersistGate persistor={persistedStore} loading={null}>
                <Router />
              </PersistGate>
            </Provider>
          </SafeAreaView>
        </ImageBackground>
      );
    } else {
      return (
        <SafeAreaView style={globalCss.firstPage}>
          <Provider store={store}>
            <PersistGate persistor={persistedStore} loading={null}>
              <Router />
            </PersistGate>
          </Provider>
        </SafeAreaView>
      );
    }
  }
}
// export default function App(){
//   let [fontsLoaded] = useFonts({
//     'Roboto': require('./assets/fonts/Roboto-Medium.ttf'),
//     'Poppins':require('./assets/fonts/Poppins-Medium.ttf')
//   });
//   if (!fontsLoaded) {
//     return <AppLoading size={'large'}/>;
//   }else
//   if(Platform.OS=='ios'||Platform.OS=='android'){
//     return(
//       <ImageBackground source={require('./src/images/appBackground.png')} style={{width:'100%',height:'100%'}}>
//         <SafeAreaView style={globalCss.firstPage}>
//         <Provider store={store}>
//             <PersistGate persistor={persistedStore} loading={null}>
//               <Router/>
//             </PersistGate>
//           </Provider>
//         </SafeAreaView>
//       </ImageBackground>
//     )
//   }else{
//      return(
//       <SafeAreaView style={globalCss.firstPage}>
//         <Provider store={store}>
//             <PersistGate persistor={persistedStore} loading={null}>
//               <Router/>
//             </PersistGate>
//         </Provider>
//       </SafeAreaView>
//      )
//   }
// }
