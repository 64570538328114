import React from 'react';
import { StyleSheet,View,TextInput,Platform} from 'react-native';
class OtpInputs extends React.Component {
    state={otp:[undefined,undefined,undefined,undefined,undefined,undefined]};
    otpTextInput = [];
    componentDidMount() {
        setTimeout(()=>{
            this.otpTextInput[0].focus();
        },500)
    }
    renderInputs() {
        const inputs = Array(6).fill(0);
        const txt = inputs.map(
            (i, j) => <View key={j} style={styles.individualField}>
                <TextInput
                    style={[styles.inputRadius, { borderRadius: 10 }]}
                    keyboardType="numeric"
                    value={this.state.otp[j]}
                    onChangeText={v => {
                        if(v.length<=1){
                            this.changeValue(j,v);
                        }
                        this.focusNext(j, v);
                       // console.log(j,v)
                    }}
                    onKeyPress={e => this.focusPrevious(e.nativeEvent.key, j)}
                    ref={ref => this.otpTextInput[j] = ref}
                />
            </View>
        );
        return txt;
    }
    focusPrevious(key, index) {
        console.log('key press event');
        if (key === 'Backspace' && index !== 0)
            this.otpTextInput[index - 1].focus();
    }

    focusNext(index, value) {
        debugger;
        // if(index!==this.otpTextInput.length-1){
        //     this.otpTextInput[index].blur();
        // }
        //console.log(value,'called focus',index);
        if (index < this.otpTextInput.length - 1 && value) {
            this.otpTextInput[index + 1].focus();
        }
        if (index === this.otpTextInput.length - 1) {
            this.otpTextInput[index].blur();
        }
       
    }
    changeValue(index,value){
        // const otp=this.state.otp;
        // otp[index]=value;
       // console.log(value,'called change value',index);
        const otp = this.state.otp;
        otp[index] = value;
        this.setState({ otp },()=>{
            this.props.getOtp(otp.join(''));
        }); 
    }


    render() {
        return (
            <View style={styles.gridPad}>
                {this.renderInputs()}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    gridPad: { padding: 30 ,flexDirection:'row',justifyContent:'space-evenly'},
    txtMargin: { margin: 3 },
    inputRadius: { textAlign: 'center',...Platform.select({
        web: {
            outlineColor:"#ffffff00",
            marginTop:'auto',
        }
    }), },
    individualField:{
        borderBottomColor:'#2A296A',
        borderBottomWidth:2,
        margin:3,
        ...Platform.select({
            web:{
                width:50
            }
        })
    }
});

export default OtpInputs;