import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  StyleSheet,
  Platform,
  TouchableOpacity,
  ImageBackground,
  TextInput,
  ActivityIndicator,
} from "react-native";
import globalCss, { winWidth, winHeight } from "./styles/Styles";
import MobileNav from "./components/mobileNav";
import { Actions } from "react-native-router-flux";
import { api, _checkStatus, localStore, _goto } from "./service";
import {
  _previousRoute,
  _previousProps,
  _ifSomeThingToVerifyAfterLoggedIn,
  _setIfSomeThingToVerifyAfterLoggedIn,
  _setPreviousProps,
  _setPreviousRoute,
} from "./constants";
import { DrawerMenu } from "./DrawerMenu";
import CustomText from "./components/CustomText";
import { t } from "./langs";
if (Platform.OS == "web") {
  var Page = require("./web/PageWithHeaderFooter").Page;
}
const css = StyleSheet.create({
  main: {
    flex: 1,
  },
  imageCont: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  contentCont: {
    flex: 3,
    backgroundColor: "#EEEDF0",
  },
  card: {
    position: "absolute",
    bottom: 0,
    height: (winHeight * 3.5) / 5,
    backgroundColor: "#fff",
    zIndex: 2,
    left: 15,
    width: winWidth - 30,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    ...globalCss.shadow,
    flex: 1,
  },
  cardItemCont: {
    flexGrow: 1,
    paddingVertical: 15,
  },
  cardbisector: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  cardContentCard: {
    flex: 1,
  },
  forgot: {
    color: "#24255E",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    ...globalCss.headingFontFamily,
    //marginTop:'auto'
  },
  forgotImg: {
    width: 100,
    resizeMode: "contain",
    height: 100,
    alignSelf: "center",
  },
  forgotDesc: {
    textAlign: "center",
    paddingHorizontal: 30,
    opacity: 0.5,
    marginBottom: 10,
  },
  inputBoxCont: {
    borderBottomColor: "#BFBCCA",
    borderBottomWidth: 2,
    width: "80%",
    height: ((winHeight * 3) / 5 - 50) / 6,
    marginBottom: 10,
  },
  input: {
    ...globalCss.bodyFont,
    ...Platform.select({
      web: {
        outlineColor: "#ffffff00",
        marginTop: "auto",
        marginBottom: 8,
      },
    }),
  },
  lable: {
    //color:'#BFBCCA',
    fontSize: 14,
    ...globalCss.bodyFont,
  },
  nxtBtn: {
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2A296A",
    width: "80%",
    height: ((winHeight * 3) / 5 - 50) / 7,
    //marginTop:20
  },
  navBar: {
    position: "absolute",
    top: 0,
    left: 0,
    flex: 1,
  },
});
class LoginView extends Component {
  state = {
    kocId: "",
    password: "",
    loading: false,
  };
  _gotoForgotPassword = () => {
    if (Platform.OS == "web") {
      this.props.history.push("/forgotpassword");
    } else {
      Actions["forgotpassword"].call();
    }
  };
  _gotoRegistration = () => {
    if (Platform.OS == "web") {
      this.props.history.push("/registration");
    } else {
      Actions["registration"].call();
    }
  };
  _checkAfterLoggedIn = () => {
    this.setState({ loading: false });
    if (
      _previousRoute == null ||
      _previousRoute == undefined ||
      _previousRoute == ""
    ) {
      _goto(this.props, "home", "replace");
    } else if (
      _previousProps != null ||
      _previousProps != undefined ||
      _previousProps != ""
    ) {
      _goto(this.props, _previousRoute, "replace", _previousProps);
    } else _goto(this.props, _previousRoute, "replace");
    _setPreviousProps(null);
    _setPreviousRoute(null);
  };
  _login = async () => {
    const { kocId, password } = this.state;
    this.setState({ loading: true });
    let resp = await api.post("users/login", {
      koc_id: kocId,
      password: password,
    });
    if (_checkStatus(resp)) {
      resp = resp.value;
      if (resp.access_token != undefined && resp.access_token != "") {
        let setToken = await localStore.set("accessToken", resp.access_token);
        let setUserId = await localStore.set("userId", resp.user.id + "");
        if (_checkStatus(setToken) && _checkStatus(setUserId)) {
          if (typeof _ifSomeThingToVerifyAfterLoggedIn === "function") {
            let resp = await _ifSomeThingToVerifyAfterLoggedIn();
            if (resp) {
              _setIfSomeThingToVerifyAfterLoggedIn(null);
              this._checkAfterLoggedIn();
            }
          } else {
            this._checkAfterLoggedIn();
            // this.setState({loading:false});
            // if(_previousRoute==null||_previousRoute==undefined||_previousRoute==''){
            //     _goto(this.props,'home','replace');
            // }else  if(_previousProps!=null||_previousProps!=undefined||_previousProps!=''){
            //     _goto(this.props,_previousRoute,'replace',_previousProps);
            // }else
            //     _goto(this.props,_previousRoute,'replace');
          }
        } else {
          console.error(setToken);
        }
      } else {
        alert(t("error_in_fetching_token"));
      }
    } else {
      this.setState({
        // kocId:'',
        // password:'',
        loading: false,
      });
    }
  };
  render() {
    const { kocId, password, loading } = this.state;
    return (
      <View style={globalCss.parentCont}>
        <View style={globalCss.mainCont}>
          <View style={css.main}>
            <ImageBackground
              style={css.imageCont}
              source={require("./images/forgotpassword_bg2.jpg")}
            >
              {Platform.OS != "web" ? (
                <View style={css.navBar}>
                  <MobileNav
                    title={t("login")}
                    color={"transparent"}
                    style={{ flex: 1, paddingTop: 30 }}
                    backButtonStyles={{ marginHorizontal: 20 }}
                    hideButton={true}
                  />
                </View>
              ) : null}
              <Image
                style={{ width: 100, height: 100 }}
                source={require("./images/logo.png")}
              />
            </ImageBackground>
            <View style={css.contentCont}>
              <View style={css.card}>
                <ScrollView contentContainerStyle={css.cardItemCont}>
                  <View style={css.cardbisector}>
                    <CustomText style={css.forgot} name={"login"} />
                    <Image
                      style={css.forgotImg}
                      source={require("./images/login.png")}
                    />
                  </View>
                  <View style={css.cardbisector}>
                    <View style={css.inputBoxCont}>
                      <CustomText style={css.lable} name={"koc_id"} />
                      <TextInput
                        value={kocId}
                        onChangeText={(kocId) => {
                          this.setState({ kocId });
                        }}
                        keyboardType={"numeric"}
                        style={css.input}
                      />
                    </View>
                    <View style={css.inputBoxCont}>
                      <CustomText style={css.lable} name={"password"} />
                      <TextInput
                        value={password}
                        onChangeText={(password) => {
                          this.setState({ password });
                        }}
                        style={css.input}
                        secureTextEntry={true}
                      />
                    </View>
                    <TouchableOpacity
                      disabled={kocId.length == 0 || password.length == 0}
                      onPress={this._login}
                      style={[
                        css.nxtBtn,
                        globalCss.shadow,
                        kocId.length == 0 || password.length == 0
                          ? { opacity: 0.5 }
                          : { opacity: 1 },
                      ]}
                    >
                      {loading ? (
                        <ActivityIndicator color={"#fff"} size={"small"} />
                      ) : (
                        <CustomText
                          style={{ color: "#fff", ...globalCss.bodyFontFamily }}
                          name={"login"}
                        />
                      )}
                    </TouchableOpacity>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onPress={this._gotoForgotPassword}
                        style={{ marginVertical: 10, marginRight: 10 }}
                      >
                        <CustomText
                          style={[
                            { color: "#2A296A", ...globalCss.bodyFontFamily },
                          ]}
                          name={"forgot_password_?"}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={this._gotoRegistration}
                        style={{ marginVertical: 10, marginLeft: 10 }}
                      >
                        <CustomText
                          style={[
                            { color: "#2A296A", ...globalCss.bodyFontFamily },
                          ]}
                          name={"sign_up"}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                </ScrollView>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
export default class Login extends Component {
  render() {
    return <LoginView {...this.props} />;
    // if(Platform.OS!='web')
    //     return (<LoginView {...this.props}/>)
    // else return (<Page {...this.props} DynamicComponent={LoginView}/>)
  }
}
