import React,{Component} from "react";
import {BrowserRouter as Router,Switch,Route } from 'react-router-dom';
import {createBrowserHistory } from 'history';
var history=createBrowserHistory();
var unlisten = history.listen(({ location, action }) => {
    console.log(action, location.pathname, location.state);
});
//Scenes
import Home from '../Home';
import Intro from "../Intro";
import Splash from "../Splash";
import Registration from '../Registration';
import Directors,{Detail} from "../BoardDirectors";
import Syndicate from "../SyndicateData";
import Notifications from "../Notifications";
import PhotoAlbum,{PhotoAlbumDetail} from "../PhotoAlbum";
import Events,{EventsDetail} from "../Events";
import News,{NewsDetail} from "../News";
import Offers,{OffersDetail} from "../Offers";
import IdCard from '../CompanyIdCard';
import Profile from '../Profile';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import Calender,{CalenderDetails} from '../Calender';
import Login from '../Login';
import FAQ from "../FAQ";
import About from "../About";
import KOCLocation from "../Location";
import ContactUs from "../ContactUs";
import Vacancies from '../Transfer_Adjustment';
//import Page from "../web/PageWithHeaderFooter";

function  RouterCom(){
    return(
        <Router history={history}>
            <Switch>
                    <Route exact path="/" component={Splash} />
                    <Route exact path="/intro" component={Intro} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/registration" component={Registration} />
                    <Route exact path="/home" component={Home} />
                    <Route exact path='/directors' component={Directors}/>
                    <Route exact path='/directorDetail/:id' component={Detail}/>
                    <Route exact path='/syndicatedata' component={Syndicate}/>
                    <Route exact path='/notifications' component={Notifications}/>
                    <Route exact path='/photoalbum' component={PhotoAlbum}/>
                    <Route exact path='/photoalbumdetails/:id' component={PhotoAlbumDetail}/>
                    <Route exact path='/events' component={Events}/>
                    <Route exact path='/eventsdetails/:id' component={EventsDetail}/>
                    <Route exact path='/news' component={News}/>
                    <Route exact path='/newsdetails/:id' component={NewsDetail}/>
                    <Route exact path='/offer' component={Offers}/>
                    <Route exact path='/offerdetails/:id' component={OffersDetail}/>
                    <Route exact path='/idcard' component={IdCard}/>
                    <Route exact path='/profile' component={Profile}/>
                    <Route exact path='/forgotpassword' component={ForgotPassword}/>
                    <Route path='/resetpassword/:email?' component={ResetPassword}/>
                    <Route exact path='/calender' component={Calender}/>
                    <Route exact path='/calenderdetails/:id' component={CalenderDetails}/>
                    <Route exact path='/vacancies' component={Vacancies}/>
                    <Route exact path='/faq' component={FAQ}/>
                    <Route exact path='/aboutus' component={About}/>
                    <Route exact path='/location' component={KOCLocation}/>
                    <Route exact path='/contactus' component={ContactUs}/>
            </Switch>
        </Router>
      )
}
export {RouterCom}
