import React,{Component  } from "react";
import { Text } from "react-native";
import { t } from "../langs";
export default class CustomText extends Component{
    render(){
        const {value,name}=this.props;
        return (
            <Text {...this.props}>
                {
                    value==undefined&&name!=undefined?t(name):value
                }
            </Text>
        )
    }
}