import React,{Component}from 'react';
import { 
  StyleSheet,
  View,
  ScrollView,
  Platform,  
} from 'react-native';
const MapView = Platform.select({
  ios:() => require('react-native-maps').default,
  android:() => require('react-native-maps').default,
  default: () => require('google-maps-react').Map
})();
const Marker = Platform.select({
  ios:() => require('react-native-maps').Marker,
  android:() => require('react-native-maps').Marker,
  default: () => require('google-maps-react').Marker
})();
const Page=Platform.select({
  ios:()=> <></>,
  android:()=><></>,
  default:()=>require('./web/PageWithHeaderFooter').Page
})
export default class KOCLocation extends Component{
    render(){
      if(Platform.OS=='web'){
        return <></>;
        //return(<Page {...this.props} DynamicComponent={KOCLocationView}/>)
      }else return(<KOCLocationView/>)
    }
}

class KOCLocationView extends Component{
  constructor(){
    super();
    this.state={
      region: {
        latitude:29.142866,
        longitude: 78.245466,
        // latitudeDelta: 0.0922,
        // longitudeDelta: 0.0421,
      },
    }
  }
  onRegionChange(region) {
    this.setState({ region });
  }
    render(){
        if(Platform.OS=='web'){
          
        }else{
          return(
            <MapView
              style={{flex:1}}
                initialRegion={{
                  latitude:29.142866,
                  longitude: 78.245466,
                  latitudeDelta: 0.0922,
                  longitudeDelta: 0.0421,
                }}>
                  <Marker
                    coordinate={this.state.region}
                    title={'Anuj Telecom'}
                    description={'Go here to recharge your phone'}
                  />
              </MapView>
          )
        }
    }
}