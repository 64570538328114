import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import globalCss, { winHeight, winWidth, vh } from "../styles/Styles";
import CustomText from "./CustomText";
// import ReactNativeZoomableView from "@dudigital/react-native-zoomable-view/src/ReactNativeZoomableView";
import ImageViewer from "react-native-image-zoom-viewer";
const css = StyleSheet.create({
  zoomCont: {
    position: "absolute",
    width: winWidth,
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 2,
    alignItems: "center",
  },
  imgMoverCont: {
    //marginTop:50,
    width: "80%",
    // height: "70%",
    // borderRadius: 10,
    // paddingHorizontal: "1%",
    // paddingTop: "1%",
  },
  zoomImg: {
    height: winHeight / 2.3,
    width: "100%",
    // width: "100%",
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    resizeMode: "contain",
    backgroundColor: "white",
  },
  buttonCont: {
    width: "100%",
    height: 30,
    flexDirection: "row",
  },
  prevBtn: {
    marginRight: "auto",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  nxtBtn: {
    marginLeft: "auto",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row-reverse",
  },
  btnImg: {
    height: 18,
    width: 18,
    resizeMode: "contain",
  },
  btnText: {
    ...globalCss.bodyFontFamily,
    fontSize: 12,
  },
  nxtImg: {
    transform: [
      {
        rotate: "180deg",
      },
    ],
  },
  cancelIconCont: {
    height: 6 * vh,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
  },
  cancelIocn: {
    fontSize: 3 * vh,
    color: "#fff",
  },
});
export default class ZoomImg extends Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
    };
  }
  _previewImg = () => {
    const { currentIndex } = this.state;
    this.setState({ currentIndex: currentIndex - 1 });
  };
  _nextImg = () => {
    const { currentIndex } = this.state;
    this.setState({ currentIndex: currentIndex + 1 });
  };
  render() {
    const { show, images, _closeZoomImg } = this.props;
    const imageUrls = [];
    for (let i = 0; i < images.length; i++) {
      imageUrls.push({ url: images[i] });
    }
    const { currentIndex } = this.state;
    if (show) {
      return (
        <TouchableWithoutFeedback onPress={_closeZoomImg}>
          <View style={css.zoomCont}>
            <View style={css.cancelIconCont}>
              <TouchableOpacity onPress={_closeZoomImg}>
                <CustomText style={css.cancelIocn} name={"close"} />
              </TouchableOpacity>
            </View>
            <View style={[css.imgMoverCont, { marginTop: 20 }]}>
              <View style={css.zoomImg}>
                <ImageViewer imageUrls={imageUrls} />
              </View>
              {/* <Image
                style={css.zoomImg}
                source={{ uri: images[currentIndex] }}
              /> */}
              {/* {images.length > 1 ? (
                <View style={css.buttonCont}>
                  <TouchableOpacity
                    onPress={this._previewImg}
                    style={[
                      css.prevBtn,
                      currentIndex == 0 ? { opacity: 0.5 } : {},
                    ]}
                    disabled={currentIndex == 0}
                  >
                    <Image
                      style={[css.btnImg]}
                      source={require("../images/activarrow.png")}
                    />
                    <CustomText style={css.btnText} name={"preview"} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={this._nextImg}
                    style={[
                      css.nxtBtn,
                      currentIndex == images.length - 1 ? { opacity: 0.5 } : {},
                    ]}
                    disabled={currentIndex == images.length - 1}
                  >
                    <Image
                      style={[css.btnImg, css.nxtImg]}
                      source={require("../images/activarrow.png")}
                    />
                    <CustomText style={css.btnText} name={"next"} />
                  </TouchableOpacity>
                </View>
              ) : null} */}
            </View>
          </View>
        </TouchableWithoutFeedback>
      );
    } else {
      return null;
    }
  }
}
