import React,{Component}from 'react';
import { 
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  Platform,
  RefreshControl,
  FlatList
  
} from 'react-native';
import { SliderBox } from "react-native-image-slider-box";
import CustomSlider from './components/CustomSlider';
import { Actions } from 'react-native-router-flux';
import ImageLoader from './components/ImageLoader';
import { NavRightButtonForDirectors} from "./DrawerMenu";
import {connect} from 'react-redux';
import {actions} from './store';
import globalCss,{winWidth,winHeight,bodyFontColor, themeColor, vw, vh} from './styles/Styles'
import AppLoading from './components/AppLoading';
import { _setStyle, _goto } from './service';
import { tabItems } from './constants';
import CustomText from './components/CustomText';
//import Swiper from 'react-native-swiper';
if(Platform.OS=='web'){
  var Page=require('./web/PageWithHeaderFooter').Page;
}
var height=Platform.select({web:(Dimensions.get('window').height)*1.5,default:Dimensions.get('window').height});
const css=StyleSheet.create({
  body:{
    flex:1,
    backgroundColor:'#EEEDF0',
    paddingVertical:10,
    //paddingBottom:10,
    justifyContent:'center',
    //alignItems:'center'
  },carousalBody:{
      flex:3.5
  },paginationBoxStyle:{
      position: "absolute",
      bottom: -(_setStyle(5*vh,25)),
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "center",
  },dotStyle:{
      height:_setStyle(1*vh,8),
      width:_setStyle(5*vw,16),
      borderRadius:5
  },listBody:{
      flex:2,
      paddingLeft:20
  },listTitleCont:{
      flexDirection:'row',
      alignItems:'center'
  },headingText:{
      paddingVertical:_setStyle(2*vw,4),
      fontSize:Platform.select({android:4*vw,ios:4*vw,default:16}),
      fontWeight:'bold',
      ...globalCss.headingFont
  },listItem:{
      //height:(height/8)*0.75,
      width:((winWidth-20)/2.2),
      paddingRight:20,
      height:'80%'
  },listImage:{
      width:'100%',
      height:_setStyle('75%','77%'),
      borderRadius:10
  },listMetaDataText:{
    fontSize:Platform.select({android:3*vw,ios:3*vw,default:12}),
    padding:_setStyle(1*vw,4),
    ...globalCss.bodyFont
  },moreButtonCont:{
    marginLeft:'auto',
    marginRight:10
  },moreButtonText:{
    fontSize:2*vh,
    color:'#24255E',
    textDecorationLine:'underline'
  },scrollNext:{
    width:_setStyle(4*vw,2*vw),
    height:2*vh,
    transform:[{rotate:'180deg'}],
    resizeMode:'contain'
  },scrollBack:{
    width:_setStyle(4*vw,1.5*vw),
    height:2*vh,
    resizeMode:'contain'
  },scrollButttonCont:{
    width:_setStyle(12*vw,5*vw),
    flexDirection:'row',
    justifyContent:'space-between',
    marginLeft:10,
    //backgroundColor:'red'
  }
})
export default class Home extends Component{
  render(){
    if(Platform.OS=='web'){
      return(<Page {...this.props} DynamicComponent={HomeView}/>)
    }else return(<HomeView/>)
  }
}
class HomePage extends Component{
  constructor(props){
    super(props);
    this.state={
      showMenu:false,
      refresh:false,
      loading:this.props.allEventsHolidays.data.length==0||this.props.allOffersDiscount.data.length==0||this.props.allNews.data.length==0,
      events:this.props.allEventsHolidays.data.slice(0,5),
      offers:this.props.allOffersDiscount.data.slice(0,5),
      news:JSON.parse(JSON.stringify(this.props.allNews.data.slice(0,5))),
      //testSlider:this.props.allNews.data
    }
    this.eventsScrollPosition=0;
    this.offerScrollPosition=0;
      this.props._getAllEvents();
      this.props._getAllNews();
      this.props._getAllOffers();
  }
  shouldComponentUpdate(nextProps,nextState){
    if(nextProps.allEventsHolidays!==this.props.allEventsHolidays||nextProps.allNews!==this.props.allNews||nextProps.allOffersDiscount!==this.props.allOffersDiscount){
      return true;
    }else{
      return false;
    }
  }
  componentDidUpdate(prevProps, prevState){
    const {allOffersDiscount,allEventsHolidays,allNews}=this.props;
    if(prevProps.allOffersDiscount!==allOffersDiscount){
      this.setState({offers:allOffersDiscount.data.slice(0,5),loading:false},()=>{this.forceUpdate()})
    }if(prevProps.allEventsHolidays!==allEventsHolidays){
      this.setState({events:allEventsHolidays.data.slice(0,5),loading:false},()=>{this.forceUpdate()})
    }if(prevProps.allNews!==allNews){
      this.setState({news:JSON.parse(JSON.stringify(allNews.data.slice(0,5))),loading:false},()=>{this.forceUpdate()})
    }
  }
  _init=async()=>{
      this.props._getAllEvents();
      this.props._getAllNews();
      this.props._getAllOffers(); 
    
  }
  changeVisibility=()=>{
    this.setState({showMenu:!this.state.showMenu});
  }
  onRefresh=async()=>{
    this._init();
  }
  _goToDetail=(scene,item)=>{
    if(Platform.OS!='web'){
      const temp=Actions[scene];
      temp({id:item.id,title:item.title});
    }
    else this.props.history.push(`/${scene}/${item.id}`);
  }
  _carousalImgPressed=(img,item)=>{
    let news=this.props.allNews.data.find((val)=>{
      return val.image==item.image
    });
   this._goToDetail('newsdetails',news);
  }
  _gotoMore=(scene)=>{
    _goto(this.props,scene);
    if(Platform.OS=='ios'||Platform.OS=='android'){
      this.props._setTabItems(tabItems,undefined,false);
    }
  }
  _scrollTo=(which,type)=>{
    if(type=='next'){
      this[which+'scroller'].scrollToOffset({
        offset: this[which+'ScrollPosition']+80,
        animated: true,
      })
    }else{
      this[which+'scroller'].scrollToOffset({
        offset: this[which+'ScrollPosition']-80,
        animated: true,
      })
    }
  }
  _onScroll=(e,type)=>{
    if(type=='events'){
      this.eventsScrollPosition=e.nativeEvent.contentOffset.x;
    }else{
      this.offerScrollPosition=e.nativeEvent.contentOffset.x;
    }
  }
  _renderListItem=(item,type)=>{
    return(
      <TouchableOpacity onPress={()=>{this._goToDetail(type,item)}} style={css.listItem}>
        <ImageLoader style={css.listImage} source={{uri:item.image}}/>
        <CustomText numberOfLines={2} ellipsizeMode={'tail'} style={css.listMetaDataText} value={item.description}/>
      </TouchableOpacity>
    )
  }
  render(){
    const {refresh,loading,events,news,offers,testSlider}=this.state;
    if(loading){
      return(
        <View style={{justifyContent:'center',alignItems:'center',flex:1}}>
           <AppLoading />
        </View>
      )
    }
    else return(
          <View
            refreshControl={<RefreshControl refreshing={refresh} onRefresh={this.onRefresh} />}
            style={css.body}
          >
            <View style={css.carousalBody}>
            <CustomSlider
              sliderBoxHeight={(height/8)*2.5}
              sliderBoxWidth={winWidth}
              images={news}
              _onPress={this._carousalImgPressed}
              parentContainerStyle={{paddingHorizontal:20}}
              imageStyle={{borderRadius:20}}
              autoPlay={true}
              autoPlayTimeGap={3000}
              inactiveDotColor={bodyFontColor}
              activeDotColor={'#24255E'}
              metadata={true}
              metadataContainerStyles={{paddingHorizontal:30}}
              metadataHeadingStyles={{...globalCss.headingFont,color:'#fff',fontSize:3*vh}}
              metadataDescriptionStyles={{...globalCss.bodyFontFamily,color:'#fff',fontSize:2*vh}}
              metadataInnerContainerStyles={{borderBottomLeftRadius:20,borderBottomRightRadius:20}}
            />
              {/* <SliderBox
                sliderBoxHeight={(height/8)*2.5}
                onCurrentImagePressed={this._carousalImgPressed}
                imageLoadingColor={themeColor}
                images={news}
                dotColor="#2E2F77"
                autoplay
                resizeMethod={'resize'}
                resizeMode={'cover'}
                circleLoop
                inactiveDotColor={bodyFontColor}
                style={{borderRadius:10,height:(height/8)*2.5,marginHorizontal:20,marginTop:10}}
                parentWidth={winWidth}
                paginationBoxStyle={css.paginationBoxStyle}
                dotStyle={css.dotStyle}
                
            /> */}
            </View>
            <View style={css.listBody}>
              <View style={css.listTitleCont}>
                <CustomText style={css.headingText} name={'events_holidays'} />
                {Platform.OS=='ios'||Platform.OS=='android'?
                  null
                  :<View style={css.scrollButttonCont}>
                    <TouchableOpacity onPress={()=>{this._scrollTo('events','back')}}>
                      <Image source={require('./images/Calendar_2_arrow.png')} style={css.scrollBack}/>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>{this._scrollTo('events','next')}}>
                      <Image source={require('./images/Calendar_2_arrow.png')} style={css.scrollNext}/>
                    </TouchableOpacity>
                  </View>
                }
                <TouchableOpacity style={css.moreButtonCont} onPress={()=>{this._gotoMore('events')}}>
                  <CustomText style={[css.headingText,css.moreButtonText]} name={'more'} />
                </TouchableOpacity>
              </View>
              <FlatList
                onScroll={(e)=>{this._onScroll(e,'events')}}
                ref={scroller => { this.eventsscroller = scroller}}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{flexGrow:1}}
                data={events}
                renderItem={({item})=>{return this._renderListItem(item,'eventsdetails')}}
                keyExtractor={item=>item.id+''}
              />
            </View>
            <View style={css.listBody}>
              <View style={css.listTitleCont}>
                <CustomText style={css.headingText} name={'offers'}/>
                {Platform.OS=='android'||Platform.OS=='ios'?
                  null
                  :<View style={css.scrollButttonCont}>
                    <TouchableOpacity onPress={()=>{this._scrollTo('offer','back')}}>
                      <Image source={require('./images/Calendar_2_arrow.png')} style={css.scrollBack}/>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>{this._scrollTo('offer','next')}}>
                      <Image source={require('./images/Calendar_2_arrow.png')} style={css.scrollNext}/>
                    </TouchableOpacity>
                  </View>
                }
                <TouchableOpacity style={css.moreButtonCont} onPress={()=>{this._gotoMore('offer')}}>
                  <CustomText style={[css.headingText,css.moreButtonText]} name={'more'} />
                </TouchableOpacity>
              </View>
              <FlatList
                onScroll={(e)=>{this._onScroll(e,'offer')}}
                ref={scroller => { this.offerscroller = scroller}}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                //indicatorStyle={'black'}
                contentContainerStyle={{flexGrow:1}}
                data={offers}
                renderItem={({item})=>{return this._renderListItem(item,'offerdetails')}}
                keyExtractor={item=>item.id+''}
              />
            </View>
          </View>
    )
  }
}
function mapStateToProps(state){
  const {baseReducer}=state
  return {
      loginPopUp:baseReducer.loginPopUp,
      allEventsHolidays:baseReducer.allEventsHolidays,
      allNews:baseReducer.allNews,
      allOffersDiscount:baseReducer.allOffersDiscount
  }
}
function mapDispatchToProps(dispatch){
  return{
      toggleLoginUp:()=>dispatch({'type':'toggleLoginUp'}),
      _getAllEvents:()=>dispatch(actions._getAllEvents()),
      _getAllNews:()=>dispatch(actions._getAllNews()),
      _getAllOffers:()=>dispatch(actions._getAllOffers()),
      _setTabItems:(tabItems,itemPresses,renderNextScreen)=>dispatch(actions._setTabItems(tabItems,itemPresses,renderNextScreen))
  }
}
const HomeView=connect(mapStateToProps,mapDispatchToProps)(HomePage);
// class HomeNavBar extends Component{
//   render(){
//     return(
//       <View style={{
//         backgroundColor:'#24245B',
//         height:10*vh,
//         flexDirection:'row',
//         alignItems:'flex-end',
//         paddingBottom:10,
//         paddingHorizontal:10
//       }}>
//         <View style={{
//           flex:1,
//           //alignItems:'center'
//         }}>
//           <TouchableOpacity onPress={()=>{Actions.drawerOpen()}}>
//             <Image source={require('./images/mobilemenu.png')} style={{width:20,height:20,resizeMode:'contain'}}/>
//           </TouchableOpacity>
//         </View>
//         <View style={{
//           flex:7,justifyContent:'center',alignItems:'center'
//         }}>
//           <CustomText style={{
//             color:'#fff',
//             //fontWeight:'500',
//             fontSize:20,
//             ...globalCss.headingFontFamily
//           }}
//           name={'koc_union'}/>
//         </View>
//         <View style={{
//           flex:2,
//           alignItems:'flex-end'
//         }}>
//           <NavRightButtonForDirectors/>
//         </View>
//       </View>
//     )
//   }
// }
// export {HomeNavBar};