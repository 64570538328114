import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  StyleSheet,
  Platform,
  TouchableOpacity,
  ImageBackground,
  Animated,
  Easing,
  Picker,
} from "react-native";
import { baseUrl } from "./constants";
import { Actions } from "react-native-router-flux";
import MobileNav from "./components/mobileNav";
import globalCss, { winWidth, winHeight } from "./styles/Styles";
import { connect } from "react-redux";
import { actions } from "./store";
import DateTimePicker from "@react-native-community/datetimepicker";
import DateTimePickerModal from "react-native-modal-datetime-picker";
if (Platform.OS == "web") {
  var WebDatePicker = require("react-calendar").Calendar;
  require("./styles/Calendar.css");
}
//import Calendar from 'react-calendar';
import AppLoading from "./components/AppLoading";
import {
  _setStyle,
  _parseDate,
  localStore,
  _checkStatus,
  api,
  _getWeekDay,
  _goto,
} from "./service";
import { Calendar, CalendarList, Agenda } from "react-native-calendars";
import CustomText from "./components/CustomText";
import { t } from "./langs";
import { cos } from "react-native-reanimated";
if (Platform.OS == "web") {
  var Page = require("./web/PageWithHeaderFooter").Page;
}
const css = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: "#EEEDF0",
  },
  backImgCont: {
    flex: 1,
  },
  backImg: {
    height: "100%",
    width: "100%",
    resizeMode: "cover",
  },
  contentContainer: {
    flex: 2,
  },
  jobTypeContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  addStartDateContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  jobTypeText: {
    fontSize: 20,
    color: "#24255E",
    marginTop: 5,
    marginBottom: 15,
  },
  JobTypeSelectorContainer: {
    width: winWidth - 100,
    height: 70,
    borderRadius: 30,
    alignItems: "center",
  },
  JobTypeSelector: {
    backgroundColor: "#121130",
    //borderRadius:10,
    alignItems: "center",
  },
  alphabetContainer: {
    borderRadius: 10,
    height: "80%",
    width: 40,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  alphabet: {
    //color:'#fff',
    fontSize: 24,
    fontWeight: "bold",
    ...globalCss.headingFontFamily,
  },
  addStartCalender: {
    flexDirection: "row",
    backgroundColor: "#fff",
    //borderRadius:15,
    alignItems: "center",
    height: 60,
    width: _setStyle(winWidth - 30, winWidth - 100),
  },
  addStartHeading: {
    color: "#24255E",
    fontSize: 16,
    marginLeft: 10,
    textDecorationLine: "underline",
    ...globalCss.headingFontFamily,
  },
  addStartIcon: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  addStartIconCont: {
    width: "10%",
    height: "80%",
    marginLeft: "auto",
    marginRight: 10,
  },
  startDate: {
    // ...globalCss.bodyFontFamily
    marginTop: 4,
  },
});
const _getUserJobType = async (jobTypes) => {
  let userId = await localStore.get("userId");
  if (_checkStatus(userId)) {
    userId = userId.value;
    let resp = await api.get("users/" + userId, true);
    if (_checkStatus(resp)) {
      let jobtype = resp.value.user.job_type;
      if (typeof jobtype == "number") {
        jobtype = jobTypes.find((item) => {
          return item.id == jobtype;
        }).job_type;
        return jobtype;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};
class CalenderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //totalAlphabets:Array(26).fill(false),
      jobTypes: this.props.allJobTypes,
      loading: true,
      startDate: _parseDate(new Date()),
      currentJobType: "",
      calender: false,
      loggedIn: false,
      kocId: "",
      iosCalendar: false,
    };
    this.minDate = new Date();
    this.maxDate = ((d) =>
      new Date(d.getFullYear() + 2, d.getMonth(), d.getDate()))(new Date());
    this.props._getAllJobTypes();
  }
  _setPlatformDependent = (mobile, web) => {
    if (Platform.OS == "android" || Platform.OS == "ios") {
      return mobile;
    } else {
      return web;
    }
  };

  async componentDidMount() {
    this._getOwnUser();
  }

  _submitDetails = async () => {
    if (this.state.currentJobType && this.state.startDate) {
      let data = await api.get(
        `insert_calendar_details/${this.state.kocId}/${this.state.startDate}/${this.state.currentJobType}`,
        false
      );
      console.log(data);
      if (data.value.success) {
        this._getCalendarData();
      }
    }
  };

  _getCalendarData = async () => {
    let data = await api.get("get_calendar_details/" + this.state.kocId, false);
    let arr = data.value.details;
    if (arr.length > 0) {
      const array = arr.join("||");
      if (Platform.OS != "web")
        Actions.calenderdetails({ id: array, type: "replace" });
      else this.props.history.push(`/calenderdetails/${array}`);
    } else {
      this.setState({ loading: false });
    }
  };

  _getOwnUser = async () => {
    let userId = await localStore.get("userId");
    if (_checkStatus(userId)) {
      userId = userId.value;
      let user = await api.get("users/" + userId, true);
      if (_checkStatus(user)) {
        this.setState({ loggedIn: true });
        user = user.value.user;
        this.setState({ kocId: user.koc_id });
        this._getCalendarData();
      }
    } else {
      if (Platform.OS != "web") Actions.login();
      else this.props.history.push(`/login`);
    }
  };

  // async componentDidMount() {
  //   localStore.delete("startDate");
  //   // this.setState({loading:true});
  //   // let currentJobType=await _getUserJobType(this.state.jobTypes);
  //   // if(currentJobType!==false){
  //   //     _goto(this.props,'calenderdetails','replace');
  //   //     this.setState({currentJobType,loading:false});
  //   // }
  // }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.allJobTypes !== prevState.jobTypes) {
  //     return { allJobTypes: nextProps.allJobTypes };
  //   } else return null;
  // }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.allJobTypes !== this.props.allJobTypes) {
  //     const { allJobTypes } = this.props;
  //     this.setState({ jobTypes: allJobTypes, loading: false });
  //   }
  // }
  // _handleAnimation = (animatedValue) => {
  //   Animated.timing(animatedValue, {
  //     toValue: 1,
  //     duration: 1000,
  //     easing: Easing.ease,
  //   }).start();
  // };
  // _upadteUserJobType = async (currentJobType, previousJobtype) => {
  //   let userId = await localStore.get("userId");
  //   if (_checkStatus(userId)) {
  //     userId = userId.value;
  //     let currentJobTypeId = this.state.jobTypes.find((item) => {
  //       return item.job_type == currentJobType;
  //     }).id;
  //     let resp = await api.post(
  //       "users/" + userId,
  //       {
  //         _method: "PUT",
  //         job_type: currentJobTypeId,
  //       },
  //       true
  //     );
  //   } else {
  //     this.setState({ currentJobType: previousJobtype });
  //   }
  // };
  // _changeContainerStyle = async (currentTapIdex) => {
  //   const { jobTypes } = this.state;
  //   this._upadteUserJobType(
  //     jobTypes[currentTapIdex].job_type,
  //     this.state.currentJobType
  //   );
  //   this.setState({ currentJobType: jobTypes[currentTapIdex].job_type });
  // };
  // _goToDetail = () => {
  //   const { startDate } = this.state;
  //   if (Platform.OS != "web") Actions.calenderdetails({ id: startDate });
  //   else this.props.history.push(`/calenderdetails/${startDate}`);
  // };
  _toggleCalender = () => {
    const { calender, iosCalendar } = this.state;
    // if (Platform.OS == "ios") {
    //   this.setState({ iosCalendar: !iosCalendar });
    // }
    this.setState({ calender: !calender });
  };
  _calenderOnChange = async (event, date) => {
    const { calender, iosCalendar } = this.state;
    // this.setState({ iosCalendar: false });
    Platform.OS == "web" && (date = event);
    this._toggleCalender();
    console.log(date);
    if (date) {
      this.setState({ startDate: _parseDate(date) }, () => {
        !iosCalendar && this._submitDetails();
      });
      //await localStore.set("startDate", _parseDate(date));
      //this.setState({ startDate:_parseDate(date) })
    }
  };
  calenderOnChange = async (date) => {
    const { calender, iosCalendar } = this.state;
    // this.setState({ iosCalendar: false });
    this._toggleCalender();
    console.log(date);
    this.setState({ newDate: _parseDate(date) }, () => {
      !iosCalendar && this._submitDetails();
    });
  };
  render() {
    const {
      jobTypes,
      loading,
      startDate,
      calender,
      currentJobType,
      loggedIn,
      iosCalendar,
    } = this.state;
    if (loading) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <AppLoading />
        </View>
      );
    } else if (loggedIn && !loading) {
      return (
        <View style={css.main}>
          {!iosCalendar ? (
            <View style={css.backImgCont}>
              <ImageBackground
                style={css.backImg}
                source={require("./images/Calendar_1_photo.png")}
              />
            </View>
          ) : null}
          <View style={css.contentContainer}>
            {!iosCalendar ? (
              <View style={css.jobTypeContainer}>
                <CustomText
                  style={css.jobTypeText}
                  name={"select_your_current_job_type"}
                />
                <View style={css.JobTypeSelectorContainer}>
                  <ScrollView
                    horizontal={true}
                    contentContainerStyle={css.JobTypeSelector}
                  >
                    {jobTypes.map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index + ""}
                          onPress={() => {
                            this.setState({ currentJobType: item.job_type });
                            this._toggleCalender();
                          }}
                          style={[
                            css.alphabetContainer,
                            item.job_type == currentJobType
                              ? { backgroundColor: "#fff" }
                              : { backgroundColor: "#24255E" },
                          ]}
                        >
                          <CustomText
                            style={[
                              css.alphabet,
                              item.job_type == currentJobType
                                ? { color: "#24255E" }
                                : { color: "#fff" },
                            ]}
                            value={item.job_type}
                          />
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                </View>
              </View>
            ) : null}
            <View style={css.addStartDateContainer}>
              {!iosCalendar ? (
                <View
                  onLayout={(e) => {
                    this.bottom = e.nativeEvent.layout.y + 60;
                    this.left = e.nativeEvent.layout.x;
                  }}
                  style={[css.addStartCalender, globalCss.shadow]}
                >
                  <TouchableOpacity onPress={this._toggleCalender}>
                    <CustomText
                      style={css.addStartHeading}
                      name={"add_start_date"}
                    />
                  </TouchableOpacity>
                  <CustomText
                    style={[css.startDate, { marginLeft: 10 }]}
                    value={startDate}
                  />
                  <TouchableOpacity
                    disabled={
                      currentJobType == undefined || currentJobType == null
                    }
                    onPress={() => currentJobType && this._toggleCalender()}
                    style={css.addStartIconCont}
                  >
                    <Image
                      style={css.addStartIcon}
                      source={require("./images/Add-start-date.png")}
                    />
                  </TouchableOpacity>
                </View>
              ) : null}
            </View>
          </View>
          {calender === true && Platform.OS == "web" ? (
            <View
              style={{
                position: "absolute",
                bottom: this.bottom,
                left: this.left,
                backgroundColor: "white",
                width: 250,
              }}
            >
              <WebDatePicker
                onChange={this._calenderOnChange}
                value={new Date(startDate)}
                minDate={this.minDate}
                maxDate={this.maxDate}
              />
            </View>
          ) : calender === true &&
            (Platform.OS == "android" || Platform.OS == "ios") ? (
            // <DateTimePicker
            //   testID="dateTimePicker"
            //   value={new Date(startDate)}
            //   mode={"date"}
            //   //is24Hour={true}
            //   display="default"
            //   minimumDate={this.minDate}
            //   maximumDate={this.maxDate}
            //   onChange={this._calenderOnChange}
            // />
            <DateTimePickerModal
              isVisible={true}
              mode="date"
              date={new Date(startDate)}
              minimumDate={this.minDate}
              maximumDate={this.maxDate}
              onConfirm={this.calenderOnChange}
              onCancel={() => this.setState({ calender: false })}
            />
          ) : null}
        </View>
      );
    }
  }
}
function mapStateToProps(state) {
  const { baseReducer } = state;
  return {
    allJobTypes: baseReducer.allJobTypes,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    _getAllJobTypes: () => dispatch(actions._getAllJobTypes()),
  };
}
const CalenderView = connect(mapStateToProps, mapDispatchToProps)(CalenderPage);

const cssForDetails = StyleSheet.create({
  jobTypeContainer: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  calenderView: {
    flex: 4,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: "#fff",
    //justifyContent:'center',
    //alignItems:'center',
    ...globalCss.shadow,
  },
  itemCont: {
    flexDirection: "row",
    width: "100%",
    height: "40%",
    //backgroundColor:'red'
    alignItems: "center",
  },
  itemImg: {
    height: "80%",
    width: "10%",
    resizeMode: "contain",
  },
  itemText: {
    marginHorizontal: 10,
    color: "#24255E",
  },
  jobTypePicker: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },
  pickerContainer: {
    borderWidth: 2,
    borderColor: "#24255E",
    height: "80%",
    width: "20%",
    // justifyContent:'center',
    // alignItems:'center'
  },
});

export default class Calender extends Component {
  render() {
    if (Platform.OS != "web") return <CalenderView />;
    else return <Page {...this.props} DynamicComponent={CalenderView} />;
  }
}

class CalenderDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobType: this.props.allJobTypes,
      kocId: null,
      currentJobType: "",
      userRequestedJobType: "",
      selectedEndDate: null,
      showCaledner: false,
      showCalednerPicker: false,
      loading: true,
      userRequestedStartDate: "",
      allData: [],
      userJobData: [],
      minDate: null,
      maxDate: null,
      marking: {},
      constMonth: null,
      constYear: null,
      month: null,
      year: null,
      startDate: _parseDate(new Date()),
      m2: null,
      d2: null,
      shiftModal: false,
      helpModal: false,
      newJobType: null,
      newDate: _parseDate(new Date()),
      calender: false,
      userKocId: null,
      iosCalendar: false,
      iosdrop: false,
    };
    //this.props._getAllJobTypes();
    //this.minDate = undefined;
  }

  _toggleCalender = () => {
    const { calender, iosCalendar } = this.state;
    if (Platform.OS == "ios") {
      this.setState({ iosCalendar: !iosCalendar });
    }
    this.setState({ calender: !calender });
  };

  _calenderOnChange = async (event, date) => {
    // this.setState({ iosCalendar: false });
    Platform.OS == "web" && (date = event);
    this._toggleCalender();
    console.log(date);
    this.setState({ newDate: _parseDate(date) });
    console.log(new Date(this.state.newDate).getMonth() + 1);
    //await localStore.set("startDate", _parseDate(date));
    //this.setState({ startDate:_parseDate(date) })
  };

  calenderOnChange = async (date) => {
    // this.setState({ iosCalendar: false });
    this._toggleCalender();
    console.log(date);
    this.setState({ newDate: _parseDate(date) });
  };

  _isLeap = (y) => {
    if ((y % 100 != 0 && y % 4 == 0) || y % 400 == 0) return true;

    return false;
  };

  _revoffSetDays = (offset, y) => {
    let month = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (this._isLeap(y)) month[2] = 29;

    let i = 0;
    for (i = 1; i <= 12; i++) {
      if (offset <= month[i]) break;
      offset = offset - month[i];
    }
    this.setState({ d2: offset });
    this.setState({ m2: i });
  };

  _offsetDays = (d, m, y) => {
    let offset = d;

    switch (m - 1) {
      case 11:
        offset += 30;
      case 10:
        offset += 31;
      case 9:
        offset += 30;
      case 8:
        offset += 31;
      case 7:
        offset += 31;
      case 6:
        offset += 30;
      case 5:
        offset += 31;
      case 4:
        offset += 30;
      case 3:
        offset += 31;
      case 2:
        offset += 28;
      case 1:
        offset += 31;
    }

    if (this._isLeap(y) && m > 2) offset += 1;

    return offset;
  };

  _daysInMonth = (month, year) => {
    switch (month) {
      case 1:
        return 31;
      case 2:
        let asd = (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
        return asd ? 29 : 28;
      case 3:
        return 31;
      case 4:
        return 30;
      case 5:
        return 31;
      case 6:
        return 30;
      case 7:
        return 31;
      case 8:
        return 31;
      case 9:
        return 30;
      case 10:
        return 31;
      case 11:
        return 30;
      case 12:
        return 31;
    }
  };

  _monthsName = (month) => {
    switch (month) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
    }
  };

  _markCalendar = () => {
    let arr = this.state.userJobData;
    let userCalendar = this.state.jobType;
    console.log(userCalendar);
    let half_days = [];
    let holidays = [];
    let marking = {};
    for (let i = arr.length - 1; i >= 0; i--) {
      let el = arr[i];
      for (let j = 0; j < userCalendar.length; j++) {
        if (userCalendar[j].job_type == el.job_type) {
          half_days = userCalendar[j].half_days;
          holidays = userCalendar[j].leaves;
          break;
        }
      }
      console.log(half_days);
      console.log(holidays);
      // if (el.last_date != null) {
      let startDate = new Date(el.start_date);
      let sD = startDate.getDate();
      let sM = Number(startDate.getMonth()) + 1;
      let sY = startDate.getFullYear();
      let lastDate = null;
      let lD = null;
      let lM = null;
      let lY = null;
      if (el.last_date != null) {
        lastDate = new Date(el.last_date);
        lD = lastDate.getDate();
        lM = Number(lastDate.getMonth()) + 1;
        lY = lastDate.getFullYear();
      } else {
        // this.setState({ constMonth: sM });
        // this.setState({ month: sM });
        // this.setState({ constYear: sY });
        // this.setState({ yonth: sY });
        lastDate = new Date(startDate);
        let today = new Date();
        if (today.getFullYear() == lastDate.getFullYear()) {
          if (today.getMonth() > lastDate.getMonth()) {
            lM = Number(today.getMonth()) + 1;
            lY = today.getFullYear() + 1;
            lD = this._daysInMonth(lM, lY);
          } else {
            lM = Number(lastDate.getMonth()) + 1;
            lY = lastDate.getFullYear() + 1;
            lD = this._daysInMonth(lM, lY);
          }
        } else if (today.getFullYear() > lastDate.getFullYear()) {
          lM = Number(today.getMonth()) + 1;
          lY = today.getFullYear() + 1;
          lD = this._daysInMonth(lM, lY);
        } else {
          lM = Number(lastDate.getMonth()) + 1;
          lY = lastDate.getFullYear() + 1;
          lD = this._daysInMonth(lM, lY);
        }
        // lM = Number(lastDate.getMonth()) + 1;
        // lY = lastDate.getFullYear();
        // lD = this._daysInMonth(lM, lY);
        this.setState({
          maxDate:
            lY +
            "-" +
            (Number(lM) < 10 ? "0" + lM : lM) +
            "-" +
            (Number(lD) < 10 ? "0" + lD : lD),
        });
      }
      let dfg =
        lY +
        "-" +
        (Number(lM) < 10 ? "0" + lM : lM) +
        "-" +
        (Number(lD) < 10 ? "0" + lD : lD);
      let qwe =
        sY +
        "-" +
        (Number(sM) < 10 ? "0" + sM : sM) +
        "-" +
        (Number(sD) < 10 ? "0" + sD : sD);
      while (new Date(qwe) <= new Date(dfg)) {
        let currDate = sD;
        let currMonth = sM;
        let currYear = sY;
        let daysInMonth = this._daysInMonth(currMonth, currYear);

        let todaysdate = new Date();
        if (
          currDate == todaysdate.getDate() &&
          currMonth == todaysdate.getMonth() + 1 &&
          currYear == todaysdate.getFullYear()
        ) {
          this.setState({ currentJobType: el.job_type });
          this.setState({ newJobType: el.job_type });
        }

        let asd =
          currYear +
          "-" +
          (Number(currMonth) < 10 ? "0" + currMonth : currMonth) +
          "-" +
          (Number(currDate) < 10 ? "0" + currDate : currDate);

        for (let k = 0; k < half_days.length; k++) {
          if (half_days[k] == new Date(asd).getDay() + 1) {
            marking[asd] = {
              selected: true,
              selectedColor: "#fed8b1",
            };
            break;
          }
        }
        for (let k = 0; k < holidays.length; k++) {
          if (holidays[k] == new Date(asd).getDay() + 1) {
            marking[asd] = {
              selected: true,
              selectedColor: "grey",
            };
            break;
          }
        }

        let d1 = sD;
        let m1 = sM;
        let y1 = sY;

        let offset1 = this._offsetDays(d1, m1, y1);
        let x = 1;
        let remDays = this._isLeap(y1) ? 366 - offset1 : 365 - offset1;
        let y2 = 0;
        let offset2 = 0;
        if (x <= remDays) {
          y2 = y1;
          offset2 = offset1 + x;
        } else {
          x -= remDays;
          y2 = y1 + 1;
          let y2days = this._isLeap(y2) ? 366 : 365;
          while (x >= y2days) {
            x -= y2days;
            y2++;
            y2days = this._isLeap(y2) ? 366 : 365;
          }
          offset2 = x;
        }
        this._revoffSetDays(offset2, y2);
        sD = this.state.d2;
        sM = this.state.m2;
        sY = y2;

        // if (Number(currDate) + 1 <= daysInMonth) {
        //   currDate = (Number(currDate) + 1).toString();
        // } else {
        //   currDate = "1";
        //   console.log(currMonth);
        //   if (currMonth + 1 > 12) {
        //     currMonth = "1";
        //     currYear = Number(currYear) + 1;
        //   } else {
        //     currMonth = (currMonth + 1).toString();
        //   }
        // }
        // sD = currDate;
        // sM = currMonth;
        // sY = currYear;
        qwe =
          sY +
          "-" +
          (Number(sM) < 10 ? "0" + sM : sM) +
          "-" +
          (Number(sD) < 10 ? "0" + sD : sD);
        // if (half_days.includes(startDate.getDay())) {
        //   marking[asd] = {
        //     selected: true,
        //     selectedColor: "green",
        //   };
        // } else if (holidays.includes(startDate.getDay())) {
        //   marking[asd] = {
        //     selected: true,
        //     selectedColor: "red",
        //   };
        // }
        // startDate = new Date(asd);
      }
      // while (startDate <= lastDate) {
      //   startDate = startDate + 1;
      //   console.log(startDate);
      // }
      // } else {
      //   let startDate = new Date(el.start_date);
      //   let sD = startDate.getDate();
      //   let sM = Number(startDate.getMonth()) + 1;
      //   this.setState({ month: sM });
      //   let sY = startDate.getFullYear();
      //   this.setState({ year: sY });
      //   let daysInMonth = this._daysInMonth(sM, sY);
      //   while (sD <= daysInMonth) {
      //     let asd =
      //       sY +
      //       "-" +
      //       (Number(sM) < 10 ? "0" + sM : sM) +
      //       "-" +
      //       (Number(sD) < 10 ? "0" + sD : sD);
      //     for (let k = 0; k < half_days.length; k++) {
      //       if (half_days[k] == new Date(asd).getDay() + 1) {
      //         marking[asd] = {
      //           selected: true,
      //           selectedColor: "green",
      //         };
      //         break;
      //       }
      //     }
      //     for (let k = 0; k < holidays.length; k++) {
      //       if (holidays[k] == new Date(asd).getDay() + 1) {
      //         marking[asd] = {
      //           selected: true,
      //           selectedColor: "red",
      //         };
      //         break;
      //       }
      //     }
      //     sD = Number(sD) + 1;
      //   }
      // }
    }
    console.log(marking);
    this.setState({ marking: marking });
  };

  _getCalendarData = async (koc_id) => {
    console.log("GetCalendarData");
    let data = await api.get("get_calendar_details/" + koc_id, false);
    console.log("GetCalendarData After");
    let arr = data.value.details;
    this.setState({ userJobData: arr });
    this.setState({ minDate: arr[arr.length - 1].start_date });
    this.setState({ currentJobType: arr[0].job_type });
    this.setState({ newJobType: arr[0].job_type });
    this.setState({ userKocId: arr[0].id });
    this._markCalendar();
    this.setState({ showCaledner: true });
    this.setState({ loading: false });
  };

  _getOwnUser = async () => {
    console.log("GetOwnUser");
    let userId = await localStore.get("userId");
    if (_checkStatus(userId)) {
      userId = userId.value;
      let user = await api.get("users/" + userId, true);
      if (_checkStatus(user)) {
        user = user.value.user;
        this.setState({ kocId: user.koc_id });
        this._getCalendarData(user.koc_id);
      }
    }
  };

  async componentDidMount() {
    console.log("ComponentDidMount");
    this._getOwnUser();
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.allJobTypes !== prevState.jobTypes) {
  //     return { allJobTypes: nextProps.allJobTypes };
  //   } else return null;
  // }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.allJobTypes !== this.props.allJobTypes) {
  //     const { allJobTypes } = this.props;
  //     this.setState({ jobTypes: allJobTypes, loading: false });
  //   }
  // }
  _getAllJobTypes = async () => {
    let jobTypes = await api.get("allcalendar");
    if (_checkStatus(jobTypes)) {
      jobTypes = jobTypes.value.calendar.map((item, index) => {
        return {
          created_at: _parseDate(item.created_at),
          half_days: item.half_days,
          id: item.id,
          job_type: item.job_type,
          leaves: item.leaves,
          //selected:index==0?true:false
        };
      });
      return jobTypes;
    } else {
      return false;
    }
  };
  // _goCheckLeaveOrHalf = (date) => {
  //   const { jobType, currentJobType } = this.state;
  //   let number = new Date(date.dateString).getUTCDay() + 1;
  //   let leaves = jobType.find((item) => {
  //     return item.job_type == currentJobType;
  //   }).leaves;
  //   let half_days = jobType.find((item) => {
  //     return item.job_type == currentJobType;
  //   }).half_days;
  //   if (leaves.includes(number + "")) {
  //     return "leave";
  //   } else if (half_days.includes(number + "")) {
  //     return "half_day";
  //   } else {
  //     return "working";
  //   }
  // };
  // _checkIfDateIsBiggerFromStartDate = (date) => {
  //   const { startDate } = this.state;
  //   let state = false;
  //   let calendarDateInstance = new Date(date);
  //   let userEnteredStartDate = new Date(startDate);
  //   //Checking Year
  //   if (
  //     calendarDateInstance.getFullYear() > userEnteredStartDate.getFullYear()
  //   ) {
  //     state = true;
  //     return state;
  //   } else if (
  //     calendarDateInstance.getFullYear() < userEnteredStartDate.getFullYear()
  //   ) {
  //     state = false;
  //     return state;
  //   } else if (
  //     calendarDateInstance.getFullYear() == userEnteredStartDate.getFullYear()
  //   ) {
  //     console.log("Year Equal");
  //   }
  //   //Checking Month
  //   if (calendarDateInstance.getMonth() > userEnteredStartDate.getMonth()) {
  //     state = true;
  //     return state;
  //   } else if (
  //     calendarDateInstance.getMonth() < userEnteredStartDate.getMonth()
  //   ) {
  //     state = false;
  //     return state;
  //   } else if (
  //     calendarDateInstance.getMonth() == userEnteredStartDate.getMonth()
  //   ) {
  //     console.log("Year And Month Equal");
  //   }
  //   //Checking date
  //   if (calendarDateInstance.getDate() > userEnteredStartDate.getDate()) {
  //     state = true;
  //     return state;
  //   } else if (
  //     calendarDateInstance.getDate() < userEnteredStartDate.getDate()
  //   ) {
  //     state = false;
  //     return state;
  //   } else if (
  //     calendarDateInstance.getDate() == userEnteredStartDate.getDate()
  //   ) {
  //     console.log("Dates Are Equal");
  //     state = true;
  //     return state;
  //   }
  // };
  // _dayComponent = ({ date, state }) => {
  //   let currentDate = state;
  //   if (this._checkIfDateIsBiggerFromStartDate(date.dateString)) {
  //     if (currentDate != "disabled") {
  //       currentDate = this._goCheckLeaveOrHalf(date);
  //       if (currentDate == "half_day") {
  //         return (
  //           <View style={{ justifyContent: "center", alignItems: "center" }}>
  //             <CustomText value={date.day} />
  //             <Image
  //               style={{ height: 10, width: 10, resizeMode: "contain" }}
  //               source={require("./images/halfday.png")}
  //             />
  //           </View>
  //         );
  //       } else if (currentDate == "leave") {
  //         return (
  //           <View style={{ justifyContent: "center", alignItems: "center" }}>
  //             <CustomText value={date.day} />
  //             <Image
  //               style={{ height: 10, width: 10, resizeMode: "contain" }}
  //               source={require("./images/fullday.png")}
  //             />
  //           </View>
  //         );
  //       }
  //     }
  //   }
  //   return (
  //     <View>
  //       <CustomText
  //         style={{
  //           textAlign: "center",
  //           color: state === "disabled" ? "gray" : "black",
  //         }}
  //         value={date.day}
  //       />
  //     </View>
  //   );
  // };
  // _toggleCalenderPicker = () => {
  //   this.setState({ showCalednerPicker: !this.state.showCalednerPicker });
  // };
  // _calenderOnChange = (event, date) => {
  //   if (date != undefined && date != null) {
  //     Platform.OS == "web" && (date = event);
  //     this._toggleCalenderPicker();
  //     this.setState(
  //       { startDate: _parseDate(date), showCaledner: "loader" },
  //       () => {
  //         setTimeout(() => {
  //           this.setState({ showCaledner: true });
  //         }, 1000);
  //       }
  //     );
  //   } else {
  //     this._toggleCalenderPicker();
  //   }

  //   //this.setState({ startDate:_parseDate(date) })
  // };
  // _parseCalenderDate = (date) => {
  //   let temp = new Date(date);
  //   return (
  //     temp.getFullYear() + "-" + (temp.getMonth() + 1) + "-" + temp.getDate()
  //   );
  // };
  // _setCalender = (currentJobType) => {
  //   this.setState({ currentJobType, showCaledner: "loader" }, () => {
  //     // console.log('force update called');
  //     // this.forceUpdate();
  //     setTimeout(() => {
  //       this.setState({ showCaledner: true });
  //     }, 1000);
  //   });
  // };

  _nextMonth = () => {
    this.setState({ loading: true });
    let sD = 1;
    let sM = Number(this.state.month) + 1;
    let sY = null;
    if (sM > 12) {
      sM = 1;
      sY = this.state.year + 1;
    }
    let daysInMonth = this._daysInMonth(sM, sY);
    let userCalendar = this.state.jobType;
    let half_days = [];
    let holidays = [];
    let marking = this.state.marking;
    for (let j = 0; j < userCalendar.length; j++) {
      if (userCalendar[j].job_type == this.state.currentJobType) {
        half_days = userCalendar[j].half_days;
        holidays = userCalendar[j].leaves;
        break;
      }
    }
    while (sD <= daysInMonth) {
      let asd =
        sY +
        "-" +
        (Number(sM) < 10 ? "0" + sM : sM) +
        "-" +
        (Number(sD) < 10 ? "0" + sD : sD);
      for (let k = 0; k < half_days.length; k++) {
        if (half_days[k] == new Date(asd).getDay() + 1) {
          marking[asd] = {
            selected: true,
            selectedColor: "#fed8b1",
          };
          break;
        }
      }
      for (let k = 0; k < holidays.length; k++) {
        if (holidays[k] == new Date(asd).getDay() + 1) {
          marking[asd] = {
            selected: true,
            selectedColor: "grey",
          };
          break;
        }
      }
      sD = Number(sD) + 1;
    }
    this.setState({ marking: marking });
    this.setState({ loading: false });
  };

  _changeShift = async () => {
    this.setState({ helpModal: false });
    this.setState({ shiftModal: false });
    this.setState({ loading: true });
    this.setState({ iosdrop: false });
    let { newJobType, newDate } = this.state;
    let date = new Date(newDate);
    let sD = date.getDate();
    let sM = Number(date.getMonth()) + 1;
    let sY = date.getFullYear();
    if (sD - 1 <= 0) {
      if (sM - 1 <= 0) {
        sM = 12;
        sY = sY - 1;
      } else {
        sM = sM - 1;
      }
      sD = this._daysInMonth(sM, sY);
    } else {
      sD = sD - 1;
    }
    let asd =
      (Number(sD) < 10 ? "0" + sD : sD) + " " + this._monthsName(sM) + " " + sY;
    console.log(asd);
    console.log(newDate);
    let data = await api.get(
      "edit_calendar_details/" + this.state.userKocId + "/" + asd,
      false
    );
    data = await api.get(
      `insert_calendar_details/${this.state.kocId}/${this.state.newDate}/${this.state.newJobType}`,
      false
    );
    if (Platform.OS != "web") Actions.calender();
    else this.props.history.push(`/calender`);
  };
  render() {
    const {
      startDate,
      jobType,
      showCaledner,
      showCalednerPicker,
      loading,
      calender,
      iosCalendar,
    } = this.state;

    if (loading) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <AppLoading />
        </View>
      );
    } else if (!loading) {
      return (
        <View style={css.main}>
          {/* {Platform.OS != "web" ? (
            <MobileNav title={t("calender")} calender={true} />
          ) : null} */}
          <View style={css.main}>
            <View style={cssForDetails.jobTypeContainer}>
              <View style={cssForDetails.itemCont}>
                <Image
                  style={cssForDetails.itemImg}
                  source={require("./images/jobtype.png")}
                />
                <CustomText
                  style={cssForDetails.itemText}
                  name={"job_type_:"}
                />
                {/* <View style={cssForDetails.pickerContainer}>
                  <Picker
                    selectedValue={this.state.currentJobType}
                    style={cssForDetails.jobTypePicker}
                    onValueChange={(currentJobType) => {
                      this._setCalender(currentJobType);
                    }}
                    mode={"dropdown"}
                    itemStyle={Platform.OS == "ios" ? { height: 40 } : {}}
                  >
                    {jobType.map((item, index) => {
                      return (
                        <Picker.Item
                          key={index}
                          color={"#000"}
                          label={item.job_type}
                          value={item.job_type}
                        />
                      );
                    })}
                  </Picker>
                </View> */}
                <CustomText
                  style={cssForDetails.itemText}
                  value={this.state.currentJobType}
                />
              </View>
              {/* <TouchableOpacity
                onPress={this._toggleCalenderPicker}
                style={cssForDetails.itemCont}
              >
                <Image
                  style={cssForDetails.itemImg}
                  source={require("./images/start-date.png")}
                />
                <CustomText
                  style={cssForDetails.itemText}
                  name={"start_date_:"}
                />
                <CustomText style={cssForDetails.itemText} value={startDate} />
              </TouchableOpacity> */}
              <View
                style={{ flexDirection: "row", flexWrap: "wrap", height: 50 }}
              >
                <View
                  style={{
                    flex: 1,
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => this.setState({ helpModal: true })}
                    style={{
                      backgroundColor: "#24245B",
                      paddingHorizontal: 10,
                      paddingVertical: 10,
                      width: "80%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CustomText
                      style={[cssForDetails.itemText, { color: "white" }]}
                      name={"help_"}
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1,
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => this.setState({ shiftModal: true })}
                    style={{
                      backgroundColor: "#24245B",
                      paddingHorizontal: 10,
                      paddingVertical: 10,
                      width: "80%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CustomText
                      style={[cssForDetails.itemText, { color: "white" }]}
                      name={"changeshifts_"}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>

            <View style={cssForDetails.calenderView}>
              {showCaledner === true ? (
                <Calendar
                  //displayLoadingIndicator={true}
                  // dayComponent={this._dayComponent}
                  minDate={new Date(this.state.minDate)}
                  maxDate={new Date(this.state.maxDate)}
                  // disableAllTouchEventsForDisabledDays={true}
                  // markedDates={{
                  //   "2021-04-12": {
                  //     selected: true,
                  //     selectedColor: "red",
                  //   },
                  //   "2021-04-05": {
                  //     selected: true,
                  //     selectedColor: "red",
                  //   },
                  //   "2021-04-19": {
                  //     selected: true,
                  //     selectedColor: "red",
                  //   },
                  //   "2021-04-26": {
                  //     selected: true,
                  //     selectedColor: "red",
                  //   },
                  // }}
                  markedDates={this.state.marking}
                  // onPressArrowRight={(addMonth) => {
                  //   console.log(addMonth);
                  // }}
                  // disableArrowRight={true}
                />
              ) : showCaledner == "loader" ? (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AppLoading />
                </View>
              ) : null}
            </View>
            {showCalednerPicker === true && Platform.OS == "web" ? (
              <View
                style={{
                  position: "absolute",
                  bottom: this.bottom,
                  left: this.left,
                  backgroundColor: "red",
                  width: 200,
                }}
              >
                <WebDatePicker
                  onChange={this._calenderOnChange}
                  value={new Date(startDate)}
                  minDate={new Date()}
                />
              </View>
            ) : showCalednerPicker === true &&
              (Platform.OS == "android" || Platform.OS == "ios") ? (
              <DateTimePicker
                testID="dateTimePicker"
                value={new Date(startDate)}
                mode={"date"}
                //is24Hour={true}
                display="default"
                minimumDate={new Date()}
                onChange={this._calenderOnChange}
              />
            ) : null}
          </View>

          {this.state.helpModal || this.state.shiftModal ? (
            <View
              style={{
                width: "100%",
                height: "100%",
                zIndex: 1,
                position: "absolute",
                backgroundColor: "rgba(52, 52, 52, 0.8)",
              }}
            >
              {this.state.helpModal ? (
                <View
                  style={{
                    height: 200,
                    backgroundColor: "white",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      height: 50,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CustomText
                        style={cssForDetails.itemText}
                        name={"halfdays_"}
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: "#fed8b1",
                          paddingVertical: 10,
                          paddingHorizontal: 50,
                        }}
                      ></View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      height: 50,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CustomText
                        style={cssForDetails.itemText}
                        name={"holidays_"}
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: "grey",
                          paddingVertical: 10,
                          paddingHorizontal: 50,
                        }}
                      ></View>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => this.setState({ helpModal: false })}
                      style={{
                        backgroundColor: "#24245B",
                        paddingHorizontal: 50,
                        paddingVertical: 10,
                      }}
                    >
                      <CustomText
                        style={[cssForDetails.itemText, { color: "white" }]}
                        name={"close_"}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              ) : (
                <View
                  style={{
                    height: 200,
                    padding: 20,
                    backgroundColor: "white",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      height: 50,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <CustomText
                        style={cssForDetails.itemText}
                        name={"job_type_:"}
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <View style={cssForDetails.pickerContainer}>
                        {Platform.OS == "ios" ? (
                          <TouchableOpacity
                            style={{ alignItems: "center" }}
                            onPress={() =>
                              this.setState({
                                iosdrop: this.state.iosdrop ? false : true,
                              })
                            }
                          >
                            <CustomText
                              style={[cssForDetails.itemText, { padding: 10 }]}
                              value={this.state.newJobType}
                            />
                          </TouchableOpacity>
                        ) : (
                          <Picker
                            selectedValue={this.state.newJobType}
                            style={cssForDetails.jobTypePicker}
                            onValueChange={(currentJobType) => {
                              console.log(currentJobType);
                              this.setState({ newJobType: currentJobType });
                            }}
                            mode={"dropdown"}
                            itemStyle={
                              Platform.OS == "ios" ? { height: 40 } : {}
                            }
                          >
                            {jobType.map((item, index) => {
                              return (
                                <Picker.Item
                                  key={index}
                                  color={"#000"}
                                  label={item.job_type}
                                  value={item.job_type}
                                />
                              );
                            })}
                          </Picker>
                        )}
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      height: 50,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ iosdrop: false });
                          this._toggleCalender();
                        }}
                      >
                        <CustomText
                          style={css.addStartHeading}
                          name={"add_start_date"}
                        />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <CustomText
                        style={[css.startDate, { marginLeft: 10 }]}
                        value={this.state.newDate}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      height: 50,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#24245B",
                          paddingHorizontal: 50,
                          paddingVertical: 10,
                        }}
                        onPress={() => {
                          this.setState({ iosdrop: false });
                          this._changeShift();
                        }}
                      >
                        <CustomText
                          style={[cssForDetails.itemText, { color: "white" }]}
                          name={"save_"}
                        />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#24245B",
                          paddingHorizontal: 50,
                          paddingVertical: 10,
                        }}
                        onPress={() => {
                          this.setState({ iosdrop: false });
                          this.setState({ shiftModal: false });
                        }}
                      >
                        <CustomText
                          style={[cssForDetails.itemText, { color: "white" }]}
                          name={"close_"}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  {calender === true && Platform.OS == "web" ? (
                    <View
                      style={{
                        position: "absolute",
                        bottom: this.bottom,
                        left: this.left,
                        backgroundColor: "white",
                        width: 250,
                      }}
                    >
                      <WebDatePicker
                        onChange={this._calenderOnChange}
                        value={new Date(this.state.newDate)}
                        minDate={new Date(this.state.startDate)}
                      />
                    </View>
                  ) : calender === true ? (
                    // <DateTimePicker
                    //   display="inline"
                    //   value={new Date(this.state.newDate)}
                    //   mode={"date"}
                    //   //is24Hour={true}
                    //   minimumDate={new Date(this.state.startDate)}
                    //   onChange={this._calenderOnChange}
                    // />
                    <DateTimePickerModal
                      isVisible={true}
                      mode="date"
                      date={new Date(this.state.newDate)}
                      minimumDate={new Date()}
                      onConfirm={this.calenderOnChange}
                      onCancel={() => this.setState({ calender: false })}
                    />
                  ) : null}
                  {/* // calender === true && Platform.OS == "ios" ? (<Calendar
                //   onChange={this._calenderOnChange}
                //   value={new Date(this.state.newDate)}
                //   minDate={this.minDate}
                // />):null} */}
                </View>
              )}
            </View>
          ) : null}

          {this.state.iosdrop ? (
            <View
              style={{
                width: "100%",
                height: "100%",
                zIndex: 2,
                position: "absolute",
                backgroundColor: "rgba(52, 52, 52, 0.8)",
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  height: 100,
                  backgroundColor: "white",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: 10,
                  }}
                >
                  {jobType.map((item, index) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ newJobType: item.job_type });
                          this.setState({ iosdrop: false });
                        }}
                        key={index}
                        style={{
                          flex: 1,
                        }}
                      >
                        <CustomText
                          style={[
                            cssForDetails.itemText,
                            {
                              color: "white",
                              backgroundColor: "#24245B",
                              padding: 10,
                            },
                          ]}
                          value={item.job_type}
                        />
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </View>
            </View>
          ) : null}
        </View>
      );
    }
  }
}
function mapStateToPropsDetail(state) {
  const { baseReducer } = state;
  return {
    allJobTypes: baseReducer.allJobTypes,
  };
}
function mapDispatchToPropsDetail(dispatch) {
  return {
    _getAllJobTypes: () => dispatch(actions._getAllJobTypes()),
  };
}
const CalenderDetailsView = connect(
  mapStateToPropsDetail,
  mapDispatchToPropsDetail
)(CalenderDetailsPage);
class CalenderDetails extends Component {
  render() {
    if (Platform.OS != "web") return <CalenderDetailsView />;
    else return <Page {...this.props} DynamicComponent={CalenderDetailsView} />;
  }
}
export { CalenderDetails };
