import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "./store";
import { View, Text, Image, TouchableOpacity, BackHandler } from "react-native";
import globalCss, { vh, vw } from "./styles/Styles";
import CustomText from "./components/CustomText";
class TabComponentView extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    setTimeout(() => {
      BackHandler.addEventListener(
        "hardwareBackPress",
        this._handleBackButtonClick
      );
    }, 1000);
  }
  componentWillUnmount() {
    BackHandler.removeEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  }
  _handleBackButtonClick = () => {
    console.log("back called");
    this.props._setTabItems([...this.props.tabItems], undefined, false);
  };
  _renderTabItem = (item, index) => {
    return (
      <TouchableOpacity
        key={index + ""}
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
        onPress={() => {
          this.props._setTabItems([...this.props.tabItems], item, true);
        }}
      >
        {item.active ? (
          <Image
            source={item.activeImg}
            style={{ resizeMode: "contain", width: 6.5 * vw, height: 6.5 * vh }}
          />
        ) : (
          <Image
            source={item.image}
            style={{ resizeMode: "contain", width: 6.5 * vw, height: 6.5 * vh }}
          />
        )}
        <CustomText
          style={{
            fontSize: 3 * vw,
            ...globalCss.bodyFont,
          }}
          name={item.title}
        />
      </TouchableOpacity>
    );
  };
  render() {
    const { tabItems } = this.props;
    return (
      <View
        style={{
          //flex:1,
          justifyContent: "space-around",
          flexDirection: "row",
          height: 9 * vh,
          backgroundColor: "#fff",
        }}
      >
        {tabItems.map((tab, index) => {
          return this._renderTabItem(tab, index);
        })}
      </View>
    );
  }
}
function mapStateToProps(state) {
  const { tabReducer } = state;
  return {
    tabItems: tabReducer.tabItems,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    _setTabItems: (tabItems, itemPresses, renderNextScreen) =>
      dispatch(actions._setTabItems(tabItems, itemPresses, renderNextScreen)),
  };
}
const TabComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TabComponentView);
export default TabComponent;
