import React,{Component} from 'react';
import {
    View,
    SafeAreaView,
    ScrollView,
    Image,
    Dimensions,
    StyleSheet,
    TouchableOpacity,
    Text,
    Animated,Modal,Easing,Platform
} from 'react-native';
import { Actions } from 'react-native-router-flux';
import ImageLoader from './components/ImageLoader';
import globalCss,{winWidth, winHeight} from "./styles/Styles";
import MobileNav from './components/mobileNav';
import { actions } from './store';
import {connect} from 'react-redux';
import { api, _checkStatus } from './service';
import AppLoading from './components/AppLoading';
import CustomText from './components/CustomText';
import { _appCurrentLang } from './constants';
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
//import Page from "./web/PageWithHeaderFooter";
const css=StyleSheet.create({
    scroll:{
        flexGrow:1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        backgroundColor:'#EEEDF0',
        paddingVertical:10
    },itemCont:{
        justifyContent:'center',
        alignItems:'center',
        width:'50%',
        padding:10,
        //height:'20%'
    },name:{
        fontSize:12
    },designation:{
        fontSize:12,
        color:'#D9D9DD'
    },
    imgCont:{
        flex:3,
        justifyContent:'center',
        alignItems:'center',
        ...Platform.select({web:{paddingVertical:10}})
    },name:{
        ...globalCss.headingFont,
        fontSize:12
    },designation:{
        fontSize:12,
        opacity:0.5,
        ...globalCss.bodyFont
    },tabCont:{
        flex:1,
    },direction:{
        flexDirection:'row',
        alignItems:'center'
    },tabIcon:{
        height:20,
        width:20,
        resizeMode:'contain',marginLeft:10
    },tabTitleCont:{
        marginLeft:20
    },tabTitle:{
        //fontWeight:'bold',
        fontSize:14,
        ...globalCss.headingFont
    },tabDesignation:{
        fontSize:13,
        opacity:0.5,
        ...globalCss.bodyFont
    },dashedBorder:{
        borderBottomWidth:2,
        borderStyle:'dotted',
        borderRadius:0
    }
})
export default class Directors extends Component{ 
    render(){
        if(Platform.OS!='web')
            return (<DirectorsView/>)
        else return (<Page {...this.props} DynamicComponent={DirectorsView}/>)
    }
}
class DirectorsPage extends Component{
    constructor(){
        super();
        this.state={
            items:[],
            animatedView:false,
        }
        this.opacity=new Animated.Value(0);
        this.position=new Animated.ValueXY({x:0,y:0});
        
    }
    componentDidMount(){

        let items=[]
        for(let i=0;i<10;i++){
            items.push(
                {image:'../Images/'+i+'.jpg'}
            )
        }
        this.setState({items:items});
        this.props._getAllDirectors();
    }
    detailPage=(e,director)=>{
        // this.setState({animatedView:true,navTitle:'Name_'+index});
        // this.opacity=new Animated.Value(0);
        // this.position=new Animated.ValueXY({x:e.nativeEvent.pageX-60,y:e.nativeEvent.pageY-60});
        // this.positionforfuture={x:e.nativeEvent.pageX,y:e.nativeEvent.pageY};
        
            
        //Actions.boardofdirectorsdetail({x:e.nativeEvent.pageX-60,y:e.nativeEvent.pageY-60});
        //Actions.push('boardofdirectorsdetail')
        //Actions.push('boardofdirectorsdetail',{title:'Name_'+index,designation:'(Director)'})
        if(Platform.OS!='web')
            Actions.boardofdirectorsdetail({id:director.photo_id});
        else this.props.history.push(`/directorDetail/${director.photo_id}`);
        
    }
    //Styles
    getImage=()=>{
        let width=winWidth/3;
        return{
            borderWidth:2,
            //resizeMode:'contain',
            borderColor:'#ffffff',
            ...Platform.select({
                web:{
                    width:winHeight/5,
                    height:(winHeight/5),
                    borderRadius:(winHeight/5)/2,
                    //resizeMode:'contain'
                },default:{
                    width:width-30,
                    height:width,
                    borderRadius:(width-30)/2,
                }
            })            
        }
    }
    getImageCont=()=>{
        let width=winWidth/3;
        return{
            borderWidth:2,
            //resizeMode:'contain',
            backgroundColor:'#fff',
            alignItems:'center',
            justifyContent:'center',
            borderColor:'#ffffff',
            ...Platform.select({
                web:{
                    width:winHeight/5,
                    height:(winHeight/5),
                    borderRadius:(winHeight/5)/2,
                    //resizeMode:'contain'
                },default:{
                    width:width,
                    height:width,
                    borderRadius:(width)/2,
                }
            })            
        }
    }

    main=()=>{
        return{
            flex:1,
            backgroundColor:'#EEEDF0'
        }
    }
   
    _setPriorityKey=(designation)=>{
        switch (designation) {
            case 'Board President':
                return 1
            case 'Board Vice President':
                return 2
            case 'Board Secretary':
                return 3
            case 'Board Assistant Secretary':
                return 4
            case 'Board Treasurer':
                return 5
            case 'Member':
                return 6
            default:
                return 7
        }
    }
    _sortDirectors=(directors)=>{
        directors.forEach((val)=>{
            val['priority']=this._setPriorityKey(val.toSetPriority);
        })
        return directors.sort((a,b)=>{
            return a['priority']-b['priority']
        })
    }
    render(){
        const {allDirectors}=this.props;
        let directors=this._sortDirectors(allDirectors);
        if(allDirectors.length==0){
            return(
                <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
                    <AppLoading/>
                </View>
            )
        }else return(
            <ScrollView 
                contentContainerStyle={[css.scroll,{}]}
                horizontal={false}
                >
                    {directors.map((v,index)=>{
                        return(
                            <View key={index} style={css.itemCont}>
                                <TouchableOpacity style={this.getImageCont()} onPress={(e)=>{this.detailPage(e,v)}}>
                                    {/* <View style={{backgroundColor:'red',borderRadius:(winWidth-20)/2}}> */}
                                        {v.image==undefined?
                                            <ImageLoader style={this.getImage()} source={require('./images/blank_user.png')}/>
                                            :<ImageLoader style={this.getImage()} source={{uri:v.image}}/>    
                                        }
                                    {/* </View> */}
                                </TouchableOpacity>
                                <CustomText style={css.name} value={v.name}/>
                                <CustomText style={css.designation} value={v.designation}/>
                            </View>
                        )
                    })}
            </ScrollView>
        )
    }
}
function mapStateToProps(state){
    const {baseReducer}=state
    return {
        allDirectors:baseReducer.allDirectors
    }
  }
  function mapDispatchToProps(dispatch){
    return{
        _getAllDirectors:()=>dispatch(actions._getAllDirectors())
    }
}
const DirectorsView= connect(mapStateToProps,mapDispatchToProps)(DirectorsPage);
const cssDetail=StyleSheet.create({
    main:{
        flex:1,
    }
})
class DetailView extends Component{
    constructor(){
        super();
        this.state={
            director:{},
            id:null,
            loading:true
        }
    }
    componentDidMount(){
        const {id}=Platform.OS!='web'?this.props:this.props.match.params;
        this.setState({loading:true},()=>{
            this._getDirectorDetail(id);
        })
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.id!=prevState.id&&Platform.OS!='web'){
            return { id: nextProps.id};
        }else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.id!==this.props.id){
            const {id}=this.props;
            this.setState({id,loading:true},()=>{
                this._getDirectorDetail(id);
            });
          }
    }
    _getDirectorDetail=async(Id)=>{
        let director=await api.get('board-of-directors/'+Id);
        if(_checkStatus(director)){
            director.value.user.image=director.value.img_path+director.value.user.photo_no+'.jpg'
            director=director.value.user;
            director={
                name:_appCurrentLang=='ar'?director.arabic_name: director.english_name,
                designation:_appCurrentLang=='ar' ?director.designation_arabic: director.designation_english,
                image:director.image,
                mobile_no:director.mobile_no
            }
            this.setState({director,loading:false});
        }
    }
    //Styles
    getImage=()=>{
        let width=winWidth/3;
        return{
            borderWidth:2,
            borderColor:'#ffffff',
            ...Platform.select({
                web:{
                    width:winHeight/3.5,
                    height:(winHeight/3.5),
                    borderRadius:(winHeight/3.5)/2,
                    //resizeMode:'contain'
                },default:{
                    width:width-30,
                    height:width,
                    borderRadius:(width-30)/2,
                }
            })            
        }
    }
    getImageCont=()=>{
        let width=winWidth/3;
        return{
            borderWidth:2,
            //resizeMode:'contain',
            backgroundColor:'#fff',
            alignItems:'center',
            justifyContent:'center',
            borderColor:'#ffffff',
            ...Platform.select({
                web:{
                    width:winHeight/5,
                    height:(winHeight/5),
                    borderRadius:(winHeight/5)/2,
                    //resizeMode:'contain'
                },default:{
                    width:width,
                    height:width,
                    borderRadius:(width)/2,
                }
            })            
        }
    }
    render(){
        //alert(Object.keys(this.props).join(','));
       const {name,designation,image,mobile_no}=this.state.director;
       const {loading}=this.state;
        return(
            <View style={cssDetail.main}>
                <View style={{
                    flex:1,
                }}>
                   {Platform.OS!='web'
                        ?<MobileNav title={name}/>
                        :null
                    }
                    {loading?
                    <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
                        <AppLoading/>
                    </View>
                    :<ScrollView contentContainerStyle={{flexGrow:1}}>
                        <View style={css.imgCont}>
                            <View style={this.getImageCont()}>
                                {image==undefined?
                                    <ImageLoader style={this.getImage()} resizeMethod={'resize'} source={require('./images/blank_user.png')}/>
                                    :<ImageLoader style={this.getImage()} resizeMethod={'resize'} source={{uri:image}}/>    
                                }
                            </View>
                            {/* <Image style={this.getImage()} resizeMethod={'resize'} source={{uri:'https://source.unsplash.com/1024x768/?women'}}/> */}
                            <CustomText style={css.name} value={name}/>
                            <CustomText style={css.designation} value={designation}/>
                        </View>
                        <View style={css.tabCont}>
                            <View style={css.direction}>
                                <Image style={css.tabIcon} source={require('./images/Position.png')}/>
                                <View style={css.tabTitleCont}>
                                    <CustomText style={css.tabTitle} name={'position'}/>
                                    <CustomText style={css.tabDesignation} value={designation}/>
                                </View>
                            </View>   
                        </View>
                        <View style={css.tabCont}>
                            <View style={[css.direction]}>
                                <Image style={css.tabIcon} source={require('./images/Department.png')}/>
                                <View style={css.tabTitleCont}>
                                    <CustomText style={css.tabTitle} name={'department'} />
                                    <CustomText style={css.tabDesignation} name={'director'}/>
                                </View>
                            </View>
                            <View style={{marginTop:10, height: 1, width:winWidth-20,marginHorizontal:10, borderRadius: 1, borderWidth: 1, opacity:0.5, borderStyle: 'dashed' }} />
                        </View>
                        <View style={css.tabCont}>
                            <View style={css.direction}>
                                <Image style={css.tabIcon} source={require('./images/email.png')}/>
                                <View style={css.tabTitleCont}>
                                    <CustomText style={css.tabTitle} name={'email'}/>
                                    <CustomText style={css.tabDesignation} value={'testingphase@yahoo.com'}/>
                                </View>
                            </View> 
                            <View style={{ marginTop:10,height: 1, width:winWidth-20,marginHorizontal:10, borderRadius: 1, borderWidth: 1, opacity:0.5, borderStyle: 'dashed' }} />  
                        </View>
                        <View style={css.tabCont}>
                            <View style={css.direction}>
                                <Image style={css.tabIcon} source={require('./images/workno.png')}/>
                                <View style={css.tabTitleCont}>
                                    <CustomText style={css.tabTitle} name={'work_number'}/>
                                    <CustomText style={css.tabDesignation} value={mobile_no}/>
                                </View>
                            </View>   
                        </View>
                        <View style={css.tabCont}>
                            <View style={css.direction}>
                                <Image style={css.tabIcon} source={require('./images/workno.png')}/>
                                <View style={css.tabTitleCont}>
                                    <CustomText style={css.tabTitle} name={'personal_number'}/>
                                    <CustomText style={css.tabDesignation} value={mobile_no}/>
                                </View>
                            </View>   
                        </View>
                    </ScrollView>
                    }
                </View>
            </View>
        )
    }
}
class Detail extends Component{ 
    render(){
        if(Platform.OS!='web')
            return (<DetailView {...this.props} />)
        else return (<Page {...this.props} DynamicComponent={DetailView}/>)
    }
}
export {Detail}
