import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  StyleSheet,
  Platform,
  TouchableOpacity,
  TextInput,
  FlatList,
} from "react-native";
import { Actions } from "react-native-router-flux";
import MobileNav from "./components/mobileNav";
import ZoomImg from "./components/ZoomImg";
import { SliderBox } from "react-native-image-slider-box";
import globalCss, { winWidth, bodyFontColor, vh } from "./styles/Styles";
import LoginPopUp from "./components/LoginPopUp";
import { connect } from "react-redux";
import { actions } from "./store";
import {
  api,
  _getWeekDay,
  _checkStatus,
  _parseDate,
  _parseHtml,
  _setStyle,
} from "./service";
import AppLoading from "./components/AppLoading";
import ImageLoader from "./components/ImageLoader";
import CustomSlider from "./components/CustomSlider";
import CustomText from "./components/CustomText";
import { t } from "./langs";
import { _appCurrentLang } from "./constants";
if (Platform.OS == "web") {
  var Page = require("./web/PageWithHeaderFooter").Page;
}
const css = StyleSheet.create({
  main: {
    flex: 1,
    //backgroundColor:'#EEEDF0',
    //paddingHorizontal:10,
    paddingVertical: 10,
  },
  searchCont: {
    borderRadius: 2,
    flexDirection: "row",
    height: 6 * vh < 40 ? 40 : 6 * vh,
    backgroundColor: "#fff",
    alignItems: "center",
    marginLeft: 20,
    marginRight: _setStyle(20, 20),
    ...globalCss.shadow,
  },
  searchIcon: {
    height: 3 * vh,
    width: "10%",
    resizeMode: "contain",
  },
  searchInput: {
    width: "90%",
    ...globalCss.bodyFont,
    ...Platform.select({
      web: {
        outlineColor: "#ffffff00",
      },
    }),
  },
  itemCont: {
    flexDirection: "row",
    borderRadius: 15,
    backgroundColor: "#fff",
    marginHorizontal: 20,
    // justifyContent:'center',
    //alignItems:'center',
    marginTop: 25,
    marginBottom: 5,
    ...globalCss.shadow,
  },
  itemImg: {
    borderRadius: 5,
    height: 110,
    width: "30%",
    resizeMode: "cover",
  },
  titleCont: {
    width: "70%",
    height: 110,
    padding: 10,
    justifyContent: "space-between",
    // justifyContent:'center',
  },
  title: {
    textAlign: "left",
    fontSize: 16,
    ...globalCss.headingFont,
  },
  dateCont: {
    marginTop: 10,
    flexDirection: "row",
    //justifyContent:'center',
    alignItems: "center",
    ...Platform.select({
      web: {},
      default: {
        justifyContent: "space-between",
      },
    }),
  },
  imgIcon: {
    width: 12,
    height: 12,
    resizeMode: "contain",
  },
  itemDayCont: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dayTxt: {
    //color:'#989CAE',
    fontSize: 12,
    marginHorizontal: 2,
    ...globalCss.bodyFont,
  },
});
class NewsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      news: JSON.parse(JSON.stringify(this.props.allNews.data)).slice(5),
      imagesForNews: this.props.allNews.data.slice(0, 5),
      loading: this.props.allNews.data.length == 0,
    };
    this.callLoadMore = false;
    this.current_page = 1;
  }
  async componentDidMount() {
    this.props._getAllNews();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allNews !== prevState.news) {
      return { allNews: nextProps.allNews };
    } else return null;
  }
  // shouldComponentUpdate(nextProps,nextState){
  //     if(nextProps.allNews!==this.props.allNews){
  //       return true;
  //     }else{
  //       return false;
  //     }
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allNews !== this.props.allNews) {
      const { allNews } = this.props;
      this.setState(
        {
          news: JSON.parse(JSON.stringify(allNews.data)).slice(5),
          imagesForNews: allNews.data.slice(0, 5),
          loading: false,
        },
        () => {
          this._checkIfToRenderNextPage(allNews);
        }
      );
    }
  }
  _handleSearch = async (txt) => {
    this.setState({ search: txt, loading: true });
    if (txt != undefined || txt != "") {
      let search = txt.toLocaleLowerCase();
      let searchResults = await api.get("news/search?q=" + search);
      if (_checkStatus(searchResults)) {
        this._checkIfToRenderNextPage(searchResults.value.news);
        searchResults = searchResults.value.news.data.map((item) => {
          return {
            title: _appCurrentLang == "ar" ? item.arabic_title : item.title,
            day: _getWeekDay(item.created_at),
            date: _parseDate(item.created_at),
            image: item.image || _testImage,
            description:
              _appCurrentLang == "ar"
                ? _parseHtml(item.arabic_description)
                : _parseHtml(item.description),
            id: item.id,
          };
        });
        let searchImg = this.state.imagesForNews.join(",");
        searchResults = searchResults.filter((v) => {
          return !searchImg.includes(v.image);
        });
        this.setState({
          news: searchResults,
          loading: false,
        });
      }
    } else {
      this.props._getAllNews();
    }
  };
  _goToDetail = (item) => {
    if (Platform.OS != "web")
      //Actions.push('newsdetails',{id:index});
      Actions.newsdetails({ id: item.id, title: item.title });
    else this.props.history.push(`/newsdetails/${item.id}`);
  };
  _loadMoreNews = async (v) => {
    console.log(
      "load more called News",
      v,
      this.callLoadMore,
      this.current_page
    );
    const { news } = this.state;
    if (this.callLoadMore) {
      this.setState({ loading: true });
      let resp = await api.get("news?page=" + (this.current_page + 1));
      if (_checkStatus(resp)) {
        resp = resp.value.news;
        resp.data = resp.data.map((item) => {
          return {
            title: _appCurrentLang == "ar" ? item.arabic_title : item.title,
            day: _getWeekDay(item.created_at),
            date: "22 July 2020",
            image: item.image || _testImage,
            description:
              _appCurrentLang == "ar"
                ? _parseHtml(item.arabic_description)
                : _parseHtml(item.description),
            id: item.id,
          };
        });
        this.setState({ news: [...news, ...resp.data], loading: false }, () => {
          this._checkIfToRenderNextPage(resp);
        });
      }
    } else return;
  };
  _checkIfToRenderNextPage = (dataObj) => {
    if (dataObj.next_page_url != null) {
      this.callLoadMore = true;
      this.current_page = dataObj.current_page;
      //this.next_page_url=allEventsHolidays.next_page_url;
    } else {
      this.callLoadMore = false;
    }
  };
  _carousalImgPressed = (img, item) => {
    // const {imagesForNews}=this.state;
    // const {allNews}=this.props;
    // let currentNewsItem=imagesForNews[img];
    // currentNewsItem=allNews.data.find((v)=>{return v.image==currentNewsItem});
    this._goToDetail(item);
  };
  render() {
    const { search, news, imagesForNews, loading } = this.state;
    return (
      <View style={css.main}>
        <View style={css.searchCont}>
          <Image
            style={css.searchIcon}
            source={require("./images/search.png")}
          />
          <TextInput
            value={search}
            onChangeText={this._handleSearch}
            placeholder={t("search")}
            style={css.searchInput}
          />
        </View>
        <View style={{ height: 32 * vh }}>
          <CustomSlider
            sliderBoxHeight={24 * vh}
            sliderBoxWidth={winWidth}
            images={imagesForNews}
            _onPress={this._carousalImgPressed}
            parentContainerStyle={{ paddingHorizontal: 20 }}
            //imageStyle={{marginVertical:10}}
            autoPlay={true}
            autoPlayTimeGap={3000}
            inactiveDotColor={bodyFontColor}
            activeDotColor={"#24255E"}
            metadata={true}
            metadataContainerStyles={{ paddingHorizontal: 30 }}
            metadataHeadingStyles={{
              ...globalCss.headingFont,
              color: "#fff",
              fontSize: 3 * vh,
            }}
            metadataDescriptionStyles={{
              ...globalCss.bodyFontFamily,
              color: "#fff",
              fontSize: 2 * vh,
            }}
          />
        </View>
        {/* <SliderBox
                    onCurrentImagePressed={this._carousalImgPressed}
                    inactiveDotColor={bodyFontColor}
                    images={imagesForNews}
                    dotColor="#2E2F77"
                    autoplay
                    resizeMethod={'resize'}
                    resizeMode={'cover'}
                    circleLoop
                    style={{
                        height:24*vh,
                        marginVertical:10,
                        marginLeft:20,
                        marginRight:_setStyle(20,10)
                    }}
                    parentWidth={Platform.select({web:winWidth-20,default:winWidth})}
                    paginationBoxStyle={{
                        position: "absolute",
                        bottom: Platform.select({web:-10,default:-20}),
                        alignItems: "center",
                        alignSelf: "center",
                        justifyContent: "center",
                    }}
                    dotStyle={{
                        height:6,
                        width:16,
                        borderRadius:5
                    }}
                />   */}
        <FlatList
          showsVerticalScrollIndicator={true}
          data={news}
          onEndReached={(v) => {
            this._loadMoreNews(v);
          }}
          onEndReachedThreshold={0.5}
          contentContainerStyle={{ flexGrow: 1 }}
          ListFooterComponent={
            loading ? <AppLoading style={{ alignSelf: "center" }} /> : null
          }
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                style={css.itemCont}
                onPress={() => this._goToDetail(item)}
              >
                <ImageLoader style={css.itemImg} source={{ uri: item.image }} />
                <View style={css.titleCont}>
                  <View>
                    <CustomText
                      numberOfLines={2}
                      style={[css.title, { fontSize: 16 }]}
                      value={item.title}
                    />
                    <CustomText
                      numberOfLines={1}
                      style={[css.dayTxt, { fontSize: 14 }]}
                      value={item.description}
                    />
                  </View>
                  <View style={css.dateCont}>
                    <View style={css.itemDayCont}>
                      <Image
                        style={css.imgIcon}
                        source={require("./images/date.png")}
                      />
                      <CustomText style={css.dayTxt} value={item.date} />
                    </View>
                    <View style={[css.itemDayCont, { marginLeft: 10 }]}>
                      <Image
                        style={css.imgIcon}
                        source={require("./images/clock.png")}
                      />
                      <CustomText style={css.dayTxt} value={item.createdTime} />
                    </View>
                    <Image
                      style={[css.imgIcon, { marginLeft: 10 }]}
                      source={require("./images/view.png")}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            );
          }}
          keyExtractor={(item) => item.id + ""}
        />
      </View>
    );
  }
}
function mapStateToProps(state) {
  const { baseReducer } = state;
  return {
    //loginPopUp:state.loginPopUp,
    allNews: baseReducer.allNews,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    //toggleLoginUp:()=>dispatch({'type':'toggleLoginUp'})
    _getAllNews: () => dispatch(actions._getAllNews()),
  };
}
const NewsView = connect(mapStateToProps, mapDispatchToProps)(NewsPage);
export default class News extends Component {
  render() {
    if (Platform.OS != "web") return <NewsView />;
    else return <Page {...this.props} DynamicComponent={NewsView} />;
  }
}
const detailsCss = StyleSheet.create({
  title: {
    textAlign: "left",
    fontSize: 16,
    ...globalCss.headingFont,
  },
  dateCont: {
    flexDirection: "row",
    //justifyContent:'center',
    alignItems: "center",
    marginVertical: 5,
  },
  date: {
    color: "#989CAE",
    fontSize: 12,
    marginHorizontal: 3,
    ...globalCss.bodyFontFamily,
  },
  img: {
    width: "100%",
    //borderRadius:20,
  },
  descCont: {
    backgroundColor: "#fff",
    paddingVertical: 10,
    //marginTop:20
  },
  desc: {
    textAlign: "justify",
    fontSize: 16,
    paddingHorizontal: 10,
    ...globalCss.bodyFontFamily,
  },
  topCont: {
    backgroundColor: "#EEEDF0",
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
});
class NewsDetailView extends Component {
  state = {
    news: [],
    imagesForNews: [],
    item: {},
    id: null,
    title: "",
    showZoomImg: false,
    loading: false,
  };
  componentDidMount() {
    const { id, title } =
      Platform.OS != "web" ? this.props : this.props.match.params;
    this.setState({ id, title, loading: true }, () => {
      this._getNewsItem(id);
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.id != prevState.id && Platform.OS != "web") {
      return { id: nextProps.id, title: nextProps.title };
    } else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      const { id, title } = this.props;
      this.setState({ id, title, loading: true }, () => {
        this._getNewsItem(id);
      });
    }
  }
  _getNewsItem = async (Id) => {
    let news = await api.get(`news/${Id}`);
    console.log(news);
    if (_checkStatus(news)) {
      news = news.value.news;
      news = {
        title: _appCurrentLang == "ar" ? news.arabic_title : news.title,
        day: _getWeekDay(news.created_at),
        date: _parseDate(news.created_at),
        image: news.image,
        description:
          _appCurrentLang == "ar"
            ? _parseHtml(news.arabic_description)
            : _parseHtml(news.description),
        imagesForNews: [news.image] || [_testImage],
      };
      this.setState({
        item: news,
        imagesForNews: news.imagesForNews,
        loading: false,
      });
    }
  };
  _hideZoomImg = () => {
    this.setState({ showZoomImg: false });
  };
  _showZoomImg = () => {
    this.setState({ showZoomImg: true });
  };
  render() {
    //const {id}=Platform.OS!='web'?this.props:this.props.match.params;
    const { item, imagesForNews, showZoomImg, loading } = this.state;
    //console.log(id,item,this.props)
    if (loading) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <AppLoading />
        </View>
      );
    } else
      return (
        <View style={{ flex: 1, backgroundColor: "#fff" }}>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <ZoomImg
              images={imagesForNews}
              show={showZoomImg}
              _closeZoomImg={this._hideZoomImg}
            />
            <View style={detailsCss.topCont}>
              <CustomText style={detailsCss.title} value={item.title} />
              <View style={detailsCss.dateCont}>
                <Image
                  style={css.imgIcon}
                  source={require("./images/date.png")}
                />
                <CustomText style={detailsCss.date} value={item.day} />
                <CustomText style={detailsCss.date} value={item.date} />
              </View>
              <SliderBox
                //ImageComponent={ImageLoader}
                images={imagesForNews}
                dotColor="#2E2F77"
                autoplay
                resizeMethod={"resize"}
                resizeMode={"cover"}
                circleLoop
                onCurrentImagePressed={this._showZoomImg}
                style={{
                  height: 24 * vh,
                  paddingVertical: 10,
                  paddingLeft: Platform.select({ web: 10, default: 0 }),
                  paddingRight: Platform.select({ web: 5, default: 0 }),
                  borderRadius: 20,
                }}
                parentWidth={winWidth - 20}
                paginationBoxStyle={{
                  position: "absolute",
                  bottom: Platform.select({ web: -10, default: -20 }),

                  //padding: 0,
                  alignItems: "center",
                  alignSelf: "center",
                  justifyContent: "center",

                  //paddingVertical: 20,
                  //paddingHorizontal:50,
                  //backgroundColor:'red'
                }}
                dotStyle={{
                  height: 6,
                  width: 16,
                  borderRadius: 5,
                }}
              />
            </View>
            <View style={detailsCss.descCont}>
              <CustomText style={detailsCss.desc} value={item.description} />
            </View>
          </ScrollView>
        </View>
      );
  }
}
class NewsDetail extends Component {
  render() {
    if (Platform.OS != "web") return <NewsDetailView {...this.props} />;
    else return <Page {...this.props} DynamicComponent={NewsDetailView} />;
  }
}
export { NewsDetail };
