import React, { Component, useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import globalCss, { winHeight, winWidth, vw, vh } from "./styles/Styles";
import { Actions } from "react-native-router-flux";
import {
  localStore,
  api,
  _checkStatus,
  _goto,
  _logout,
  _setStyle,
  _parseDate,
} from "./service";
import {
  _setPreviousRoute,
  _setPreviousProps,
  _setIfSomeThingToVerifyAfterLoggedIn,
  googleMapUrl,
  whatsAppUrl,
} from "./constants";
import { connect } from "react-redux";
import ImageLoader from "./components/ImageLoader";
import Logo from "./images/logo";
import CustomText from "./components/CustomText";
import * as WebBrowser from "expo-web-browser";
import { t, languageRestart } from "./langs";
const css = StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: "#333482",
    paddingHorizontal: 15,
    paddingVertical: 40,
  },
  icon: {
    ...Platform.select({
      web: {
        width: 20,
        height: 20,
        resizeMode: "contain",
      },
      default: {
        width: 5 * vw,
        height: 3 * vh,
        resizeMode: "contain",
      },
    }),
  },
});
class DrawerMenu extends Component {
  constructor(props) {
    super(props);
    if (Platform.OS == "web") {
      this.drawerMenuItems = [
        ...this.extraMenuItemsForWeb,
        ...this.drawerMenuItems,
      ];
    }
  }
  state = {
    logoutButton: false,
  };
  async componentDidMount() {
    this._checkLoginStatus();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  async componentDidUpdate(prevProp, prevState) {
    let resp = await localStore.get("accessToken");
    if (_checkStatus(resp)) {
      if (prevState.logoutButton) return null;
      else this.setState({ logoutButton: true });
    } else {
      if (prevState.logoutButton) this.setState({ logoutButton: false });
      else return null;
    }
  }
  _checkLoginStatus = async () => {
    let resp = await localStore.get("accessToken");
    if (_checkStatus(resp)) {
      this.setState({ logoutButton: false });
    }
  };
  _checkUserStatus = async (route) => {
    let resp = await localStore.get("accessToken");
    if (_checkStatus(resp)) {
      this.goto(route);
    } else {
      _setPreviousRoute(route);
      if (Platform.OS == "web") this.props.history.push("/login");
      else Actions.push("login");
    }
  };
  openExternalApp = (url) => {
    if (Platform.OS == "web") {
      WebBrowser.openBrowserAsync(url).then((data) => {
        console.log(data);
      });
    } else {
      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          Linking.openURL(url);
        } else {
          console.log("Don't know how to open URI: " + url);
        }
      });
    }
  };
  drawerMenuItems = [
    {
      title: t("profile"),
      icon: require("./images/profile_white.png"),
      scene: () => {
        this._checkUserStatus("profile");
      },
      border: false,
    },
    {
      title: t("calender"),
      icon: require("./images/Date-of-Joining_white.png"),
      scene: "calender",
      border: false,
    },
    {
      title: t("koc_union_board_of_directors"),
      icon: require("./images/Koc-Unioun-Board-Of-Directors.png"),
      scene: "directors",
      border: false,
    },
    {
      title: t("union_regulation"),
      icon: require("./images/Syndicate-Data.png"),
      scene: "syndicatedata",
      border: _setStyle(false, true),
    },
    {
      title: t("transfer_and_adjustment"),
      icon: require("./images/Transfer-or-Status-Adjustments.png"),
      scene: "vacancies",
      border: false,
    },
    {
      title: t("photoalbum"),
      icon: require("./images/Photo-Album.png"),
      scene: "photoalbum",
      border: true,
    },
    {
      title: t("koc_union_membership_card"),
      icon: require("./images/ompanyid4.png"),
      scene: () => {
        this._checkUserStatus("idcard");
      },
      border: false,
    },
    // ,{
    //     title:'Forgot Password',
    //     icon:require('./images/companyid_white.png'),
    //     scene:'forgotpassword'
    // }
    // ,{
    //     title:'Reset Password',
    //     icon:require('./images/companyid_white.png'),
    //     scene:'resetpassword'
    // }
    {
      title: t("location_guide"),
      icon: require("./images/Location-Guide.png"),
      //scene:'location',
      scene: () => {
        this.openExternalApp(googleMapUrl);
      },
      border: _setStyle(true, false),
    },
    {
      title: t("notifications"),
      icon: require("./images/Notifications.png"),
      scene: "notifications",
      border: false,
    },
    {
      title: t("change_lang"),
      icon: require("./images/switchlang.png"),
      scene: () => {
        languageRestart();
      },
      //scene:"changelanguage",
      border: false,
    },
    {
      title: t("faq"),
      icon: require("./images/faq.png"),
      scene: "faq",
      border: false,
    },
    {
      title: t("contact_us"),
      icon: require("./images/contact.png"),
      scene: () => {
        this.openExternalApp(whatsAppUrl);
      },
      //scene:'contactus',
      border: false,
    },
    {
      title: t("about"),
      icon: require("./images/about.png"),
      scene: "aboutus",
      border: false,
    },
    // ,{
    //     title:'Terms & Conditions',
    //     icon:require('./images/TermsConditions.png'),
    //     scene:'terms_conditions',
    //     border:false
    // }
  ];
  extraMenuItemsForWeb = [
    {
      title: t("home"),
      icon: require("./images/home_active_white.png"),
      scene: "home",
      border: false,
    },
    {
      title: t("offers"),
      icon: require("./images/offers_white.png"),
      scene: "offer",
      border: false,
    },
    {
      title: t("events"),
      icon: require("./images/event_white.png"),
      scene: "events",
      border: false,
    },
    {
      title: t("news"),
      icon: require("./images/newswhite.png"),
      scene: "news",
      border: false,
    },
    // ,{
    //     title:'More',
    //     icon:require('./images/morewhite.png')
    // }
  ];
  menuItem = (item, key) => {
    return (
      <TouchableOpacity
        key={key}
        style={[
          {
            //flex:1,
            flexDirection: "row",
            paddingVertical: 10,
            ...(item.border && {
              borderBottomColor: "#fff",
              borderBottomWidth: 1,
            }),
          },
          item.style ? item.style : {},
        ]}
        onPress={() => this.goto(item.scene)}
      >
        <Image source={item.icon} style={css.icon} />
        <CustomText
          style={{
            marginLeft: 20,
            color: "#fff",
            ...globalCss.headingFontFamily,
            ...Platform.select({
              android: { fontSize: 4 * vw },
              ios: { fontSize: 4 * vw },
              default: {},
            }),
          }}
          value={item.title}
        />
      </TouchableOpacity>
    );
  };
  selfClose = () => {
    if (Platform.OS == "web") {
      this.props.visibility();
    } else {
      Actions.drawerClose();
    }
  };
  goto = (scene) => {
    if (scene != undefined && typeof scene == "string") {
      this.selfClose();
      if (Platform.OS == "web") {
        let state = "/" + scene;
        if (
          (this.props.location && this.props.location.pathname == state) ||
          (this.props.history &&
            this.props.history.location &&
            this.props.history.location.pathname == state)
        ) {
          return;
        } else this.props.history.push(state);
      } else {
        Actions[scene].call();
      }
    } else if (typeof scene == "function") {
      //eval(scene());
      scene.call();
    }
  };
  _logoutCallback = () => {
    this.setState({ logoutButton: false }, () => {
      this.selfClose();
    });
  };

  // _logout=()=>{
  //     if(Platform.OS=='android'||Platform.OS=='ios'){
  //         Alert.alert(
  //             'Logout',
  //             'Are you sure you want to logout?',
  //             [
  //                 {
  //                     text: 'Cancel',
  //                     onPress: () => console.log('Cancel Pressed'),
  //                     style: 'cancel',
  //                 },
  //                 {text: 'OK', onPress: ()=>this._logoutConfirm()
  //                 },
  //             ]
  //         );
  //     }else{
  //         if(confirm('Are you sure you want to logout?')==true){
  //             this._logoutConfirm();
  //         }
  //     }

  // }
  // _logoutConfirm=async()=>{
  //     let resp=await api.post('users/logout',{},true);
  //     if(_checkStatus(resp)){
  //         let removeToken=await localStore.delete('accessToken');
  //         if(_checkStatus(removeToken)){
  //             this.setState({logoutButton:false},()=>{
  //                 alert('user successfully logged out');
  //                 this.selfClose();
  //             });
  //         }
  //     }
  // }
  render() {
    const { logoutButton } = this.state;
    return (
      <View style={globalCss.parentCont}>
        <View
          style={[
            globalCss.mainCont,
            { width: Platform.OS == "web" ? winWidth / 2 : "100%" },
          ]}
        >
          <View style={css.pageContainer}>
            <View style={{ flex: 1 }}>
              <TouchableOpacity style={{}} onPress={this.selfClose}>
                {/* <Image source={require('./images/cross.png')} style={css.icon}/> */}
                <Text
                  style={{ fontSize: _setStyle(4.2 * vw, 20), color: "#fff" }}
                >
                  {"X"}
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 2,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {/* <Logo height={'100%'} width={'100%'} /> */}
              <ImageLoader
                style={{ height: "100%", width: "100%", resizeMode: "contain" }}
                source={require("./images/logo.png")}
              />
            </View>
            <View style={{ flex: 10 }}>
              <ScrollView
                style={{
                  flexGrow: 1,
                }}
              >
                {this.drawerMenuItems.map((item, key) => {
                  return this.menuItem(item, key);
                })}
              </ScrollView>
            </View>
            <View style={{ flex: 1 }}>
              {this.menuItem({
                title: logoutButton ? t("logout") : t("login"),
                icon: require("./images/logout.png"),
                scene: logoutButton
                  ? () => {
                      _logout(this._logoutCallback, this.props);
                    }
                  : "login",
                style: { marginTop: "auto" },
                //scene:'login'
              })}
            </View>
          </View>
        </View>
      </View>
    );
  }
}
const cssForNavRightDir = StyleSheet.create({
  main: {
    flexDirection: "row",
  },
  icon: {
    height: 24,
    width: 24,
    resizeMode: "contain",
  },
  gap: {
    marginHorizontal: 5,
  },
});
function mapStateToPropsLoginPopUp(state) {
  return {
    loginPopUp: state.loginPopUp,
  };
}
function mapDispatchToPropsLoginPopUp(dispatch) {
  return {
    toggleLoginUp: () => dispatch({ type: "toggleLoginUp" }),
  };
}
const _getUserJobType = async () => {
  let userId = await localStore.get("userId");
  if (_checkStatus(userId)) {
    userId = userId.value;
    let resp = await api.get("users/" + userId, true);
    if (_checkStatus(resp)) {
      let jobtype = resp.value.user.job_type;
      if (typeof jobtype == "number") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};
function NavRightButtonForDirectors(props) {
  // const _openCalender=()=>{
  //     if(Platform.OS=='web'){

  //     }else{
  //         Actions['calender'].call();
  //     }
  // }
  const _checkUserStatus = async (route) => {
    let resp = await localStore.get("accessToken");
    if (_checkStatus(resp)) {
      if (Actions.currentScene != route) {
        Actions[route].call();
      }
      //this.props.history.push('/profile');
    } else {
      _setPreviousRoute(route);
      Actions["login"].call();
    }
  };
  const _checkUserStatusForCalender = async (route) => {
    let resp = await localStore.get("accessToken");
    if (_checkStatus(resp)) {
      if (Actions.currentScene != route) {
        let respUser = await _getUserJobType();
        if (respUser != false) {
          Actions["calenderdetails"].call();
        } else {
          Actions[route].call();
        }
      }
    } else {
      _setIfSomeThingToVerifyAfterLoggedIn(
        _getUserJobType,
        { scene: "calenderdetails", props: { id: _parseDate(new Date()) } },
        { scene: route, props: {} }
      );
      Actions["login"].call();
    }
  };
  return (
    <View style={cssForNavRightDir.main}>
      {/* <TouchableOpacity onPress={()=>_checkUserStatusForCalender('calender')} style={cssForNavRightDir.gap}>
                    <Image source={require('./images/Date-of-Joining_white.png')} style={cssForNavRightDir.icon}/>
                </TouchableOpacity> */}
      <TouchableOpacity
        onPress={() => _checkUserStatus("profile")}
        style={cssForNavRightDir.gap}
      >
        <Image
          source={require("./images/user.png")}
          style={cssForNavRightDir.icon}
        />
      </TouchableOpacity>
      {/* {isLoggedIn?
                    <LoginModal/>
                    :null
                } */}
    </View>
  );
}
//const NavRightButtonForDirectors=connect(mapStateToPropsLoginPopUp,mapDispatchToPropsLoginPopUp)(NavRightButtonForDirector)
function NavRightButtonForCalender() {
  return (
    <View style={[cssForNavRightDir.main, { paddingRight: 5 }]}>
      {/* <TouchableOpacity style={[cssForNavRightDir.gap]}>
        <Image
          style={cssForNavRightDir.icon}
          source={require("./images/info.png")}
        />
      </TouchableOpacity> */}
    </View>
  );
}
function LoginModal() {
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <View
      style={{
        position: "absolute",
        //top:34,
        //right:-10,
        //bottom:0,
        top: 10,
        width: winWidth,
        height: 300,
        backgroundColor: "red",
        //zIndex:99
      }}
    >
      <Text>jgy</Text>
    </View>
  );
}
function NavLeftButtonForDrawer() {
  return (
    <View style={cssForNavRightDir.main}>
      <TouchableOpacity
        style={{ marginLeft: 20 }}
        onPress={() => {
          Actions.drawerOpen();
        }}
      >
        <Image
          source={require("./images/mobilemenu.png")}
          style={{ width: 20, height: 20, resizeMode: "contain" }}
        />
      </TouchableOpacity>
    </View>
  );
}
export {
  DrawerMenu,
  NavRightButtonForDirectors,
  NavRightButtonForCalender,
  _getUserJobType,
  NavLeftButtonForDrawer,
};
