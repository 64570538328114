import React,{Component}from 'react';
import { 
    View,
    Image,
    TouchableOpacity,
    TextInput,
} from 'react-native';
import globalCss from '../styles/Styles';
import {connect} from 'react-redux';
class LoginModal extends Component{
    state={
      userName:'',
      password:''
    }
    _toggleClose=()=>{
        this.props.toggleLoginUp();
    }
    render(){
      const {show}=this.props;
      const {userName,password}=this.state;
      if(show){
        return(
            <View style={{width:'100%',height:'100%',position:'absolute',backgroundColor:'rgba(0,0,0,0.5)',zIndex:1}}>
              <View style={{height:200,width:'100%',backgroundColor:'#fff',borderTopColor:'#453F91',borderTopWidth:2}}>
                <View style={{
                  flex:1,
                  flexDirection:'row'
                }}>
                  <View style={{flex:1}}>
                    <TouchableOpacity onPress={this._toggleClose} style={{alignSelf:'center',width:15,height:15,marginTop:20}}>
                      <Image style={{width:'100%',height:'100%',resizeMode:'contain'}} source={require('../images/cancel.png')}/>
                    </TouchableOpacity>
                  </View>
                  <View style={{flex:4,justifyContent:'center',alignItems:'center'}}>
                    <Image style={{width:50,height:50,resizeMode:'contain'}} source={require('../images/user_web.png')}/>
                    <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center',marginVertical:10}}>
                      <Image style={{width:18,height:18,resizeMode:'contain',marginRight:10}} source={require('../images/username.png')}/>
                      <TextInput 
                        value={userName}
                        placeholder={'User Name'}
                        style={[globalCss.input,{width:90}]}
                        onChangeText={(userName)=>{this.setState({userName})}}
                      />
  
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center',marginVertical:10}}>
                      <Image style={{width:18,height:18,resizeMode:'contain',marginRight:10}} source={require('../images/loginpassword.png')}/>
                      <TextInput 
                        value={password}
                        placeholder={'Password'}
                        secureTextEntry={true}
                        style={[globalCss.input,{width:90}]}
                        onChangeText={(password)=>{this.setState({password})}}
                      />
                    </View>
                    <View style={{height:2,backgroundColor:'#453F91',width:'100%'}}></View>
                  </View>
                  <View style={{flex:1}}>
                  
                  </View>
                </View>
              </View>
            </View> 
        )
      }else return null;
    }
  }
  function mapStatsToProps(state){
    return {
        loginPopUp:state.loginPopUp,
    }
  }
  function mapDispatchToProps(dispatch){
    return{
        toggleLoginUp:()=>dispatch({'type':'toggleLoginUp'})
    }
  }
  const LoginPopUp=connect(mapStatsToProps,mapDispatchToProps)(LoginModal);
  export default LoginPopUp;
  