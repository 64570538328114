import { t } from "../langs";
import { localStore, _checkStatus } from "../service";
import { I18nManager } from "react-native";
import * as Localization from "expo-localization";
import { Platform } from "@unimodules/core";

//Production Server
const baseUrl = "https://www.kocunion.com/mobile/public/api";
const _testImage =
  "https://images.sftcdn.net/images/t_app-cover-l,f_auto/p/ce2ece60-9b32-11e6-95ab-00163ed833e7/260663710/the-test-fun-for-friends-screenshot.jpg";
var _previousRoute = null;
var _previousProps = null;
var _ifSomeThingToVerifyAfterLoggedIn = null;
var _appCurrentLang = null;
const googleMapUrl = `${
  Platform.OS == "web"
    ? "https://www.google.com/maps/dir/?api=1&travelmode=recommended&layer=traffic&destination=29.08221058208892, 48.068613383600194"
    : Platform.OS == "android"
    ? "geo:29.08221058208892, 48.068613383600194"
    : "http://maps.apple.com/?ll=29.08221058208892, 48.068613383600194"
}`;
const whatsAppUrl = `${
  Platform.OS == "web"
    ? "https://wa.me/+96565056677/"
    : "whatsapp://send?phone=+96565056677"
}`;
var _storagePermission = null;
const _setStoragePermission = (val) => {
  _storagePermission = val;
};
const _setAppCurrentLang = (lang) => {
  _appCurrentLang = lang;
};
const _setPreviousRoute = (route) => {
  _previousRoute = route;
};
const _setConstantTabItems = (tabItems) => {
  tabItems = tabItems;
};
const _setPreviousProps = (props) => {
  _previousProps = props;
};
const _setIfSomeThingToVerifyAfterLoggedIn = (
  callBack,
  trueScene,
  falseScene
) => {
  if (typeof callBack === "function") {
    _ifSomeThingToVerifyAfterLoggedIn = async () => {
      let resp = await callBack();
      if (resp !== false) {
        if (
          trueScene.props != undefined &&
          Object.keys(trueScene.props).length > 0
        ) {
          _setPreviousProps(trueScene.props);
        }
        _setPreviousRoute(trueScene.scene);
        return true;
      } else {
        if (
          falseScene.props != undefined &&
          Object.keys(falseScene.props).length > 0
        ) {
          _setPreviousProps(falseScene.props);
        }
        _setPreviousRoute(falseScene.scene);
        return true;
      }
    };
  } else {
    _ifSomeThingToVerifyAfterLoggedIn = null;
  }
};
var tabItems = [
  {
    title: t("home"),
    image: require("../images/home.png"),
    activeImg: require("../images/home_active.png"),
    scene: "home",
    active: true,
  },
  {
    title: t("offers"),
    image: require("../images/offer.png"),
    activeImg: require("../images/offer_active.png"),
    scene: "offer",
    active: false,
  },
  {
    title: t("events"),
    image: require("../images/eventbtm.png"),
    activeImg: require("../images/event_active.png"),
    scene: "events",
    active: false,
  },
  {
    title: t("news"),
    image: require("../images/news.png"),
    activeImg: require("../images/news_active.png"),
    scene: "news",
    active: false,
  },
  //{title:'More',image:require('../images/more.png'),activeImg:require('../images/more_active.png'),scene:'',active:false}
];
const _getTabItems = () => {
  return [
    {
      title: "home",
      image: require("../images/home.png"),
      activeImg: require("../images/home_active.png"),
      scene: "home",
      active: true,
    },
    {
      title: "offers",
      image: require("../images/offer.png"),
      activeImg: require("../images/offer_active.png"),
      scene: "offer",
      active: false,
    },
    {
      title: "events",
      image: require("../images/eventbtm.png"),
      activeImg: require("../images/event_active.png"),
      scene: "events",
      active: false,
    },
    {
      title: "news",
      image: require("../images/news.png"),
      activeImg: require("../images/news_active.png"),
      scene: "news",
      active: false,
    },
  ];
};
const authRequiredScenes = ["idcard", "profile", "calender", "calenderdetails"];
export {
  baseUrl,
  _testImage,
  _previousRoute,
  _setPreviousRoute,
  tabItems,
  _setConstantTabItems,
  authRequiredScenes,
  _previousProps,
  _setPreviousProps,
  _ifSomeThingToVerifyAfterLoggedIn,
  _setIfSomeThingToVerifyAfterLoggedIn,
  _appCurrentLang,
  _setAppCurrentLang,
  _getTabItems,
  _storagePermission,
  _setStoragePermission,
  googleMapUrl,
  whatsAppUrl,
};
