import React,{Component } from "react";
import { View,Text,Image,ScrollView,StyleSheet,Platform,TouchableOpacity,ImageBackground,TextInput,ActivityIndicator} from "react-native";
import globalCss,{winWidth,winHeight} from './styles/Styles';
import MobileNav from './components/mobileNav';
import { api, localStore, _checkStatus, _goto, _goBack } from "./service";
import AppLoading from "./components/AppLoading";
import { _previousRoute } from "./constants";
import CustomText from "./components/CustomText";
import { t } from "./langs";
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
const css=StyleSheet.create({
    main:{
        flex:1
    },imageCont:{
        flex:2,
        justifyContent:'center',
        alignItems:'center'
    },contentCont:{
        flex:3,
        backgroundColor:'#EEEDF0',
        //backgroundColor:'red',
    },card:{
        position:'absolute',
        bottom:0,
        height:((winHeight*3.5)/5),
        backgroundColor:'#fff',
       // backgroundColor:'blue',
        zIndex:2,
        left:15,
        width:winWidth-30,
        borderTopLeftRadius:50,
        borderTopRightRadius:50,
        ...globalCss.shadow,
        flex:1
    },cardItemCont:{
        flexGrow:1,
        paddingVertical:15,
        //backgroundColor:'#fff'
    },cardbisector:{
        flex:1,
        justifyContent:'center',
        alignItems:'center',
    },cardContentCard:{
        flex:1
    },forgot:{
        color:'#24255E',
        //fontWeight:'bold',
        fontSize:22,
        textAlign:'center',
        //marginTop:'auto',
        ...globalCss.headingFont
    },forgotImg:{
        width:100,
        resizeMode:'contain',
        height:100,
        alignSelf:'center'
    },forgotDesc:{
        textAlign:'center',
        paddingHorizontal:30,
        //opacity:0.5,
        marginBottom:10,
        ...globalCss.bodyFont
    },inputBoxCont:{
        borderBottomColor:'#BFBCCA',
        borderBottomWidth:2,
        width:'80%',
        height:(((winHeight*3)/5)-50)/6,
        marginBottom:10
    },input:{
        ...globalCss.bodyFont,
        ...Platform.select({
            web: {
                outlineColor:"#ffffff00",
                marginTop:'auto',
                marginBottom:8,
            }
        }),
        
    },lable:{
        //color:'#BFBCCA',
        fontSize:14,
        ...globalCss.bodyFont
    },nxtBtn:{
        marginTop:10,
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#2A296A',
        width:'80%',
        height:(((winHeight*3)/5)-50)/7,
        //marginTop:20
    },navBar:{
        position:'absolute',
        top:0,
        left:0,
        flex:1
    }
})
class ResetPasswordView extends Component{
    constructor(props){
        super(props);
        const {email}=Platform.OS!='web'?this.props:this.props.match.params;
        this.state={
            password:'',
            confirmPassword:'',
            loading:false,
            autoRedirected:email!=undefined&&email.length>0,
            passcode:'',
            old_password:''
        }
        this.userRegisteredMail=email;
    }
    _resetPassword=async()=>{
        const {password,confirmPassword,autoRedirected,passcode,old_password}=this.state;
        const {userRegisteredMail}=this;
        this.setState({loading:true});
        let resp=await api.post('users/'+`${autoRedirected?'forgot_password/verify':'reset_password'}`,{
            new_password:password,
            conf_password:confirmPassword,
            ...!autoRedirected&&{
                old_password:old_password
            },
            ...autoRedirected&&{
                email:userRegisteredMail,
                password_reset_code:passcode
            }
        },autoRedirected?undefined:true);
        console.log(resp);
        if(_checkStatus(resp)){
            this.setState({loading:false},async()=>{
                alert(t('password_modified'));
                if(autoRedirected){
                    _goto(this.props,'login','replace');
                }else{
                    let resp=await localStore.delete('accessToken');
                    if(_checkStatus(resp)){
                        _goto(this.props,'login','replace');
                    }
                }
            })
        }else{
            this.setState({loading:false});
        }
    }
    render(){
        const{loading,password,confirmPassword,passcode,autoRedirected,old_password}=this.state;
        const {userRegisteredMail}=this;
        return(
            <View style={globalCss.parentCont}>
                <View style={globalCss.mainCont}>
                    <View style={css.main}>
                        <ImageBackground style={css.imageCont} source={require('./images/forgotpassword_bg2.jpg')}>
                            {Platform.OS!='web'?
                                <View style={css.navBar}>
                                    <MobileNav title={t('reset_password')} color={'transparent'} style={{flex:1,paddingTop:30,...globalCss.headingFontFamily}} backButtonStyles={{marginHorizontal:20}} hideButton={true}/>
                                </View>
                                :null
                            }
                            <Image style={{width:100,height:100}} source={require('./images/logo.png')}/>
                        </ImageBackground>
                        <View style={css.contentCont}>
                            <View style={css.card}>
                                <ScrollView contentContainerStyle={css.cardItemCont}>
                                    <View style={css.cardbisector}>
                                        <CustomText style={css.forgot} name={'reset_password'}/>
                                        <Image style={[css.forgotImg]} source={require('./images/resetpassword.png')}/>
                                        <Text style={css.forgotDesc}>{autoRedirected?`${ t('please_fill_passcode_that_we_sent_to_your_mail')+' '+ userRegisteredMail}`:t('set_new_password')}</Text>
                                    </View>
                                    <View style={css.cardbisector}>
                                        {autoRedirected?
                                            <View style={css.inputBoxCont}>
                                                <CustomText style={css.lable} name={'passcode'}/>
                                                <TextInput
                                                    value={passcode}
                                                    onChangeText={(passcode)=>{this.setState({passcode})}}
                                                    //keyboardType={'numeric'}
                                                    style={css.input}
                                                    //secureTextEntry={true}
                                                />
                                            </View>
                                            :<View style={css.inputBoxCont}>
                                                <CustomText style={css.lable} name={'old_password'}/>
                                                <TextInput
                                                    value={old_password}
                                                    onChangeText={(old_password)=>{this.setState({old_password})}}
                                                    //keyboardType={'numeric'}
                                                    style={css.input}
                                                    secureTextEntry={true}
                                                />
                                            </View>
                                        }
                                        <View style={css.inputBoxCont}>
                                            <CustomText style={css.lable} name={'new_password'}/>
                                            <TextInput
                                                value={password}
                                                onChangeText={(password)=>{this.setState({password})}}
                                                //keyboardType={'numeric'}
                                                style={css.input}
                                                secureTextEntry={true}
                                            />
                                        </View>
                                        <View style={css.inputBoxCont}>
                                            <CustomText style={css.lable} name={'confirm_password'}/>
                                            <TextInput
                                                value={confirmPassword}
                                                onChangeText={(confirmPassword)=>{this.setState({confirmPassword})}}
                                                //keyboardType={'numeric'}
                                                style={css.input}
                                                secureTextEntry={true}
                                            />
                                        </View>
                                        <TouchableOpacity onPress={this._resetPassword} disabled={password.length<0||confirmPassword.length==0||(confirmPassword!=password)||(autoRedirected&&passcode.length==0)||(!autoRedirected&&old_password.length==0)} style={[css.nxtBtn,globalCss.shadow,password.length<0||confirmPassword.length==0||(confirmPassword!=password)||(autoRedirected&&passcode.length==0)||(!autoRedirected&&old_password.length==0)?{opacity:0.5}:{opacity:1}]}>
                                            {loading?
                                                <AppLoading color={'#fff'}/>
                                               :<CustomText style={{color:'#fff',...globalCss.bodyFontFamily}} name={'done'}/>
                                            }
                                        </TouchableOpacity>
                                    </View>
                                </ScrollView>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}
export default class ResetPassword extends Component{
    render(){
        return <ResetPasswordView {...this.props}/>
        // if(Platform.OS!='web')
        //     return (<ForgotPasswordView {...this.props}/>)
        // else return (<Page {...this.props} DynamicComponent={ForgotPasswordView}/>)
    }
}