import React,{Component}from 'react';
import { 
  StyleSheet,
  View,
  ScrollView,
  Platform,  
} from 'react-native';
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
export default class About extends Component{
    render(){
      if(Platform.OS=='web'){
        return(<Page {...this.props} DynamicComponent={AboutView}/>)
      }else return(<AboutView/>)
    }
}
class AboutView extends Component{
    render(){
        return(
            <View>
            
            </View>
        )
    }
}