import React,{Component } from "react";
import { View,Text,Image,ScrollView,StyleSheet,Platform,TouchableOpacity,TouchableWithoutFeedback,FlatList,SectionList,SafeAreaView} from "react-native";
import ImageLoader from './components/ImageLoader';
import globalCss, { winWidth,winHeight, vh } from "./styles/Styles";
import {actions} from './store';
import {connect} from 'react-redux';
import AppLoading from "./components/AppLoading";
import { api, _checkStatus, _parseDate, _testImage, _downloadFileInMobile } from "./service";
import { Actions } from "react-native-router-flux";
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';
import * as WebBrowser from 'expo-web-browser';
import CustomText from "./components/CustomText";
import { _appCurrentLang } from "./constants";
import { t } from "./langs";
//import * as axios from 'axios';
//import RNFetchBlob from 'rn-fetch-blob';
//var RNFetchBlob = require('rn-fetch-blob').default
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
const css=StyleSheet.create({
    main:{
        flex:1,
        padding:10,
        backgroundColor:'#EEEDF0'
    },itemCont:{
        marginBottom:10,
        height:180,
        width:'100%'
        //height:
    },img:{
        width:'100%',
        height:'100%',
        resizeMode:'cover'
    },imgDescCont:{
        position:'absolute',
        bottom:10,
        left:10,
    },imgDesc:{
        color:'#fff',
        fontSize:12,
        fontWeight:'bold',
        textAlign:'left',
        ...globalCss.headingFontFamily
    },imgDate:{
        color:'#fff',
        fontSize:10,
        ...globalCss.bodyFontFamily
    },zoomCont:{
        position:'absolute',
        width:Platform.select({android:winWidth,ios:winWidth,default:winWidth-20}),
        height:'100%',
        backgroundColor:'rgba(0,0,0,0.7)',
        zIndex:2,
        alignItems:'center'
    },imgMoverCont:{
        //marginTop:50,
        width:'80%',
        height:(winHeight/2)+40,
        backgroundColor:'#fff',
        borderRadius:10,
        paddingHorizontal:'1%',
        paddingTop:'1%'
    },zoomImg:{
        height:winHeight/2,
        width:'100%',
        borderTopLeftRadius:10,
        borderTopRightRadius:10,
    },buttonCont:{
        width:'100%',
        height:30,
        flexDirection:'row'
    },prevBtn:{
        marginRight:'auto',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row'
    },nxtBtn:{
        marginLeft:'auto',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row-reverse'
    },btnImg:{
        height:18,
        width:18,
        resizeMode:'contain'
    },btnText:{
        fontSize:12,
        ...globalCss.bodyFontFamily
    },nxtImg:{
        transform:[{
            rotate:'180deg'
        }]
    },wrapper: {
        flex: 1
    },
    container: {
        flexDirection: 'row',
        //paddingHorizontal: 5
    },
    list: {
        flex: 1,
        flexDirection: 'column',
        paddingVertical: 10,
        paddingHorizontal: 5
    },cancelIconCont:{
        height:6*vh,
        justifyContent:'center',
        alignItems:'center',
        paddingTop:20,
    },cancelIocn:{
        fontSize:3.5*vh,
        color:'#fff',
    }
})
class PhotoAlbumPage extends Component{
    constructor(props){
        super(props);
        this.state={
            loading:this.props.allGalleries.data.length==0,
            gridRows:this.props.allGalleries.data,
        }
        this.callLoadMore=false;
        this.current_page=1;
    }
    async componentDidMount(){
        this.props._getAllGalleries();
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.allGalleries!=prevState.gridRows){
            return { allGalleries: nextProps.allGalleries};
        }else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.allGalleries!==this.props.allGalleries){
            const {allGalleries}=this.props;
            this.setState({gridRows:allGalleries.data,loading:false},()=>{
                setTimeout(()=>{
                    this._checkIfToRenderNextPage(allGalleries);
                },2000);
            });
        }
    }
    _loadMoreGalleries=async(v)=>{
        console.log('load more called galleries',v,this.callLoadMore,this.current_page);
        if(this.callLoadMore){
            this.setState({loading:true});
            let resp=await api.get('galleries?page='+(this.current_page+1));
            if(_checkStatus(resp)){
                resp=resp.value.galleries;
                console.log(JSON.parse(JSON.stringify(resp)));
                resp.data=resp.data.map((item)=>{
                    return{
                        date:_parseDate(item.created_at),
                        image:item.cover_photo,
                        title:_appCurrentLang=='ar'?item.arabic_event_name:item.event_name,
                        allImages:item.images,
                        id:item.id
                    }
                });
                //this.staticGalleries=JSON.parse(JSON.stringify([...this.staticGalleries,...resp.data]));
                this.setState({gridRows:[...this.state.gridRows,...resp.data],loading:false},()=>{
                    this._checkIfToRenderNextPage(resp);
                });
            }
        }else return;

    }
    _checkIfToRenderNextPage=(dataObj)=>{
        if(dataObj.next_page_url!=null){
            this.callLoadMore=true;
            this.current_page=dataObj.current_page;

        }else{
            this.callLoadMore=false;
        }
    }
    _goToDetail=(item)=>{
        if(Platform.OS!='web')
            Actions.photoalbumdetails({id:item.id,title:item.title});
        else this.props.history.push(`/photoalbumdetails/${item.id}`);
    }
    render(){
        const {gridRows,loading}=this.state;
        return(
            <View style={css.main}>
                <FlatList
                    scrollEnabled={true}
                    onEndReachedThreshold={0.01}
                    onEndReached={this._loadMoreGalleries}
                    ListFooterComponent={loading?<AppLoading />:null}
                    keyExtractor={item=>item.id.toString()}
                    style={css.list} 
                    data={gridRows}
                    renderItem={({item})=>{
                        return(
                            <TouchableOpacity onPress={()=>{this._goToDetail(item)}}  style={{width:'100%',height:20*vh,marginBottom:10}}>
                                <ImageLoader  style={{width:'100%',height:'100%'}} source={{uri:item.image}}/>
                                <View style={css.imgDescCont}>
                                    <CustomText style={css.imgDate}value={item.date}/>
                                    <CustomText style={css.imgDesc} value={item.title}/>
                                </View>
                            </TouchableOpacity>
                        )
                    }}
                />
            </View>
            
        )
    }
}
function mapStateToProps(state){
    const {baseReducer}=state
    return {
        //loginPopUp:state.loginPopUp,
        allGalleries:baseReducer.allGalleries
    }
}
function mapDispatchToProps(dispatch){
    return{
        //toggleLoginUp:()=>dispatch({'type':'toggleLoginUp'})
        _getAllGalleries:()=>dispatch(actions._getAllGalleries()),
    }
}
const PhotoAlbumView=connect(mapStateToProps,mapDispatchToProps)(PhotoAlbumPage);
export default class PhotoAlbum extends Component{
    render(){
        if(Platform.OS!='web')
            return (<PhotoAlbumView/>)
        else return (<Page {...this.props} DynamicComponent={PhotoAlbumView}/>)
    }
}
const detailsCss=StyleSheet.create({

})
class PhotoAlbumDetailView extends Component{
    constructor(props){
        super(props);
       // let tewtImages=[{id:1,image:_testImage},{id:3,image:'http://codesk.work/dev/koc/public/storage/images/event_and_holidays/1602048939_resetpassword.png'},{id:2,image:'http://codesk.work/dev/koc/public/storage/images/news/1601021954_company_idcard.png'}]
        this.state={
            gridRows:this._setImages([]),
            loading:true,
            id:'',
            currentEventImages:[],
            showZoomCont:false,
            index:0,
            allImages:[],
            permissionsGranted:false,
            downloadLoader:false
        }
    }
    componentDidMount(){
        const {id}=Platform.OS!='web'?this.props:this.props.match.params;
        this.setState({id},()=>{
            this._getGalleryImage(id);
        });
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.id!=prevState.id&&Platform.OS!='web'){
            return { id: nextProps.id,title:nextProps.title};
        }else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.id!==this.props.id){
            const {id}=this.props;
            this.setState({id,loading:true},()=>{
                this._getGalleryImage(id);
            });
          }
    }
    _getCameraRollPermission = async () => {
        let { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
        this.setState({ permissionsGranted: status === 'granted' },);
    };
    async _getDataUrl(img) {
    //\const imageUrl = 'http://i.imgur.com/XP2BE7q.jpg';
        // const response = await fetch(img);
        // // This will be the blob data that you will have to store inside your SQLite DB
        // const blob = await response.blob();
        // console.log(blob);
        const link = document.createElement('a');
        link.href = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsSAAALEgHS3X78AAADiElEQVRo3uWZW4iNURTHf2fOuA+miSIZSRpEBjO88EBo3GoelOJRuRQiL7xMmgjxIF6YiBJhHkxoHuQyU+PBJSn33AYRpYmMnMOZsz2c9ZllOec7Y8zRdz7/WvWt+e9vvrXOXnuttfeOVFbuJp9R0IUxVcAtJeU5tKfcfKsq2wuF4LKNKQGmKb0ohw4UmW+V9IQDgUahc2kdGAuMSDnIRMNVAH1zZM/ENPpcIAG8AZ7YFyIVFTu0vhDYC4wP6A/+CNgMNHp/iA4fPsd7XgOcAIYGOGKGAMuB97LIKUitAVcG7gC4iOhBlojYWgbu5yJeL/HuoQE4DXQAM4ENiquRqcwFxgG1St8PtABRYBlQ3Zl8WAtsLHQuCTBVvfQSWCrGA/QyH7kq/zQXmGn060C9PNcDz4BRolfqNKpz+31lfJDQIbZ5DgyUNJq0A21ebQeeKz2WQyNj5lvtWWzLWAc0zon8C9wCxvxRIYNk6CrxJOBQQO2d1JUZKAVW5dEM5H0I/ebA9zSrPygosnUpXTt9EVgcUAcuAItCH0L55UCWOtBLNVBeP35X6dUqJl9J7+JhtrS/AB+kh/IwQ7Kdt+YaTKocZxrL792dgf7AGaXvArYq/RgwWJ5PAisUV6uasxZgluI2SF8P8AkoVtxyYIvSi2VMaEPIhXoRfwHmKb3V8NVqI/TOcJtUaHw03E7gqDwnDHcEuGxs6LYDCeCSD9+UpbPMhHsi6fBU5L/pRn0diPLrUeI7OZ/xUC5jANqAF4orUzu9duCx4kbTeerWAdxR3AhgmNLv+O0QszlQZELBptEmnzR62CeNbvdJo+v+KI2GPQvlfSvxFVht4lFjI9BbZQ+NPcDxDCn2CNAsz98Md9aspa9/MwPfgDof/liWw4BMuCKSDjdEur0nDlUIRUyGiJkpLZYx3mzpqjlQVekE8FlxA1TouTSVussocM7hI4Occ21Kagzfqrg6wzUqrtFwdYprzWKDr4TyXChUayAO6HvYZsPvo/O6yabYE8C1DF3seeC1Wlc5m4GYKesW23y4gz7cKZEe29AkTYYIKrRtST0Db4HJasM9AXgQMOMnANOV/lbPwDlggRD9gJtywhCUi46o/LD9baWPlJZWAfQBbouX+YAHpK7F4l4IxUkdJ17IAyceiq1xux94AUwBVgJLSN1F9Q2I0TFSl4/npZP92cFGRo6cn9eF7AeOSstvhoK8FQAAAABJRU5ErkJggg==';
        link.setAttribute('download','');
        
        link.setAttribute('id','testImage');
        document.body.appendChild(link);
        
        setTimeout(()=>{
            let imageElement=document.getElementById('testImage');
            console.log(imageElement);
            
            
            link.click();
        },2000)
        //var image=new Image(img);
        //console.log(axios);
        // let image=document.querySelectorAll('img');
        // image.forEach((item)=>{
        //     if(item.getAttribute('src')==img){
        //         image=item;
        //     }
        // });
        // var url = img;

        //     // Initialize the XMLHttpRequest and wait until file is loaded
        //     var xhr = new XMLHttpRequest();
        //     xhr.onload = function () {
        //     // Create a Uint8Array from ArrayBuffer
        //     var codes = new Uint8Array(xhr.response);

        //     // Get binary string from UTF-16 code units
        //     var bin = String.fromCharCode.apply(null, codes);

        //     // Convert binary to Base64
        //     var b64 = btoa(bin);
        //     console.log(b64); //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
        //     };

        //     // Send HTTP request and fetch file as ArrayBuffer
        // xhr.open('GET', url);
        // xhr.responseType = 'arraybuffer';
        // xhr.send();
        // const fileURL = URL.createObjectURL(image);
        // console.log(fileURL);
        //img.src = fileURL;
        // getBase64(image).then(
        //     data => console.log(data)
        // );
        function getBase64(file) {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            });
        }
        // console.log(image)
        // //Create canvas
        // const canvas = document.createElement('canvas');
        // const ctx = canvas.getContext('2d');
        // Image.getSize(img, (width, height) => {
        //     // width=width;
        //     // height=height;
        //     canvas.width = width;
        //     canvas.height = height;
        //     // Draw the image
        //     ctx.drawImage(image, 0, 0);
        //     console.log(canvas.toDataURL('image/jpeg'));
        //     return canvas.toDataURL('image/jpeg');
        //     //this.setState({ width: (winWidth-30)/3, height: imgHeight });
        // });
        // Set width and height
        
     }
     // Select the image
     
    _webFileDownload=async(img)=>{
       // this.setState({downloadLoader:true});
        this._getDataUrl(img);
        //const img = document.querySelector('#my-image');
        // img.addEventListener('load', function (event) {
        //     const dataUrl = getDataUrl(event.currentTarget);
        //     console.log(dataUrl);
        // });
        // axios({
        //     url: img,
        //     method: 'GET',
        //     responseType: 'blob', // important
        //   }).then((response) => {
        //       console.log(response);
        //     // const url = window.URL.createObjectURL(new Blob([response.data]));
        //     // const link = document.createElement('a');
        //     // link.href = url;
        //     // link.setAttribute('download', 'file.pdf');
        //     // document.body.appendChild(link);
        //     // link.click();
        //     this.setState({downloadLoader:true});
        //   });
    }
    _saveImage=async(img)=>{
        if(Platform.OS=='web'){
            debugger;
            //this._webFileDownload(img);
            let result = await WebBrowser.openBrowserAsync(img);
        }else{
            this.setState({downloadLoader:true});
            let resp=   await _downloadFileInMobile(img);
            console.log(resp,'download return value','image donwloaded');
            this.setState({downloadLoader:false});
        }
    }
    _getGalleryImage=async(id)=>{
        let resp=await api.get('gallery-images/'+id);
        if(_checkStatus(resp)){
            console.log(JSON.parse(JSON.stringify(resp)));
            resp=resp.value.images.map((img)=>{
                return{
                    date:_parseDate(img.created_at),
                    image:resp.value.img_path+'/'+img.image,
                    title:img.description,
                    id:img.id
                }
            })
            this.setState({allImages:resp,gridRows:this._setImages(resp),loading:false})
        }
    }
    _setImages=(images)=>{
        let copyOfImages=JSON.parse(JSON.stringify(images));
        let temp=1;
        let gridRows=[{key:1,data:[]},{key:2,data:[]},{key:3,data:[]}]
        for(let i=1;i<=images.length;i++){
            let img=copyOfImages.shift();
            gridRows[(temp-1)].data.push(img);
            temp++;
            if (temp==4) temp=1;
        }
        return gridRows;
    }
    _showImgZoom=(img)=>{
        let currentEventImages=this.state.allImages.map((v)=>{return v.image});
        let currentImgPressesIndex=currentEventImages.findIndex((v)=>{return v==img});
        this.setState({
            showZoomCont:true,
            index:currentImgPressesIndex,
            currentEventImages
        });
    }
    _closeZoomCont=()=>{
        this.setState({showZoomCont:false});
    }
    _previewImg=()=>{
        const {index}=this.state;
        this.setState({index:index-1});
    }
    _nextImg=()=>{
        const {index}=this.state;
        this.setState({index:index+1});

    }
    _renderImg=({item})=>{
        return(
            <ImageComponent item={item} onPress={()=>{this._showImgZoom(item.image)}}/>
        )
    }
    render(){
        const {loading,gridRows,currentEventImages,showZoomCont,index,downloadLoader}=this.state;
        if(loading){
            return(
                <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
                    <AppLoading/>
                </View>
            )
        }else return(
            <View style={css.wrapper}>
                {
                    showZoomCont?
                    <TouchableWithoutFeedback disabled={true} >
                        <View style={css.zoomCont}>
                            <View style={css.cancelIconCont}>
                                <TouchableOpacity style={{flexWrap:'wrap'}} onPress={this._closeZoomCont} >
                                    <CustomText style={css.cancelIocn} name={'close'}/>
                                </TouchableOpacity>
                            </View>
                            <View style={[css.imgMoverCont,{marginTop:50}]}>
                                <ImageLoader style={css.zoomImg} height={winHeight/2} enableZoom={true} source={{uri:currentEventImages[index]}}/>
                                <View style={css.buttonCont}>
                                    <TouchableOpacity onPress={this._previewImg} style={[css.prevBtn,index==0?{opacity:0.5}:{}]} disabled={index==0}>
                                        <Image style={[css.btnImg]} source={require('./images/activarrow.png')}/>
                                        <CustomText style={css.btnText} name={'preview'}/>
                                    </TouchableOpacity>
                                    {downloadLoader?
                                        <AppLoading size={'small'}/>
                                        :Platform.OS=='web'?
                                        // <WebView
                                        // style={{height:10,width:20,backgroundColor:'red'}}
                                        //  //originWhitelist={['*']}
                                        //  source={{ html: '<h1>Hello world</h1>' }}
                                        // />
                                        <TouchableOpacity onPress={()=>this._saveImage(currentEventImages[index])} style={{marginTop:'auto',marginBottom:2}}>
                                            <Image style={[css.btnImg]} source={require('./images/downlaod.png')}/>
                                        </TouchableOpacity>
                                        :<TouchableOpacity onPress={()=>this._saveImage(currentEventImages[index])} style={{marginTop:'auto',marginBottom:2}}>
                                            <Image style={[css.btnImg]} source={require('./images/downlaod.png')}/>
                                        </TouchableOpacity>

                                    }
                                    {/* {Platform.OS=='web'||Platform.OS=='web'&&downloadLoader?
                                        <AppLoading size={'small'}/>
                                         
                                    }
                                    {Platform.OS=='web'&&downloadLoader?
                                        <AppLoading size={'small'}/>
                                        :<TouchableOpacity onPress={()=>this._saveImage(currentEventImages[index])} style={{marginTop:'auto',marginBottom:2}}>
                                            <Image style={[css.btnImg]} source={require('./images/downlaod.png')}/>
                                        </TouchableOpacity> 
                                    } */}
                                    <TouchableOpacity onPress={this._nextImg} style={[css.nxtBtn,index==currentEventImages.length-1?{opacity:0.5}:{}]} disabled={index==currentEventImages.length-1}>
                                        <Image style={[css.btnImg,css.nxtImg]} source={require('./images/activarrow.png')}/>
                                        <CustomText style={css.btnText} name={'next'} />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                    :null
                }
                <FlatList
                    contentContainerStyle={css.container}
                    data={gridRows}
                    renderItem={({item})=>{
                        //const val=item;
                        return(
                            <FlatList
                                //scrollEnabled={false}
                                //onEndReachedThreshold={0.01}
                                //onEndReached={val.key==1?this._loadMoreGalleries:null}
                                //ListFooterComponent={val.key==2?loading?<AppLoading />:null:null}
                                keyExtractor={item=>item.id.toString()}
                                style={css.list} 
                                data={item.data}
                                renderItem={this._renderImg}
                            />
                        )
                    }}
                    keyExtractor={item=>item.key.toString()}
                />
            </View>
        )
    }
}
class PhotoAlbumDetail extends Component{
    render(){
        if(Platform.OS!='web')
            return (<PhotoAlbumDetailView {...this.props}/>)
        else return (<Page {...this.props} DynamicComponent={PhotoAlbumDetailView}/>)
    }
}
export {PhotoAlbumDetail}
class ImageComponent extends Component{
    constructor(props){
        super(props);
        this.state = { source: { uri: this.props.item.image } };
    }
    componentDidMount(){
        Image.getSize(this.props.item.image, (width, height) => {
            let imgHeight;
            let winHeight=winHeight;
            if(height>=1000){
                imgHeight=height/4
            }else if(height>=1200){
                imgHeight=height/5
            }else if(height<=200){
                imgHeight=200
            }else{
                if((height/3)<200){
                    imgHeight=200;
                }else{
                    imgHeight=height/3;
                }
            }
            this.setState({ width: (winWidth-30)/3, height: imgHeight });
        },(error)=>{
            alert(t('image_load_error'));
        });
    }
    render(){
        const {source,height,width}=this.state;
        const {item,onPress}=this.props;
        return(
            <TouchableOpacity onPress={onPress}  style={{width:width,height:height,marginBottom:10}}>
                <ImageLoader  style={{width:'100%',height:'100%'}} source={source}/>
                <View style={css.imgDescCont}>
                    <CustomText style={css.imgDate} value={item.date}/>
                    <CustomText style={css.imgDesc} value={item.title}/>
                </View>
            </TouchableOpacity>
        )
    }
}