import React,{Component } from "react";
import { View,Text,Image,ScrollView,StyleSheet,Platform,TouchableOpacity,ImageBackground,TextInput,ActivityIndicator} from "react-native";
import globalCss,{winWidth,winHeight} from './styles/Styles';
import MobileNav from './components/mobileNav';
import { api, _checkStatus, _goto, localStore } from "./service";
import AppLoading from "./components/AppLoading";
import CustomText from "./components/CustomText";
import { t } from "./langs";
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
const css=StyleSheet.create({
    main:{
        flex:1
    },imageCont:{
        flex:2,
        justifyContent:'center',
        alignItems:'center'
    },contentCont:{
        flex:3,
        backgroundColor:'#EEEDF0',
    },card:{
        position:'absolute',
        bottom:0,
        height:((winHeight*3.5)/5),
        backgroundColor:'#fff',
        zIndex:2,
        left:15,
        width:winWidth-30,
        borderTopLeftRadius:50,
        borderTopRightRadius:50,
        ...globalCss.shadow,
        flex:1
    },cardItemCont:{
        flexGrow:1,
        paddingVertical:15
    },cardbisector:{
        flex:1,
        justifyContent:'center',
        alignItems:'center',
    },cardContentCard:{
        flex:1
    },forgot:{
        color:'#24255E',
        //fontWeight:'bold',
        ...globalCss.headingFontFamily,
        fontSize:22,
        textAlign:'center',
        marginTop:'auto'
    },forgotImg:{
        width:100,
        resizeMode:'contain',
        height:100,
        alignSelf:'center'
    },forgotDesc:{
        textAlign:'center',
        paddingHorizontal:30,
        //opacity:0.5,
        marginBottom:10,
        ...globalCss.bodyFont
    },inputBoxCont:{
        borderBottomColor:'#BFBCCA',
        borderBottomWidth:2,
        width:'80%',
        height:(((winHeight*3)/5)-50)/6,
        marginBottom:10
    },input:{
        ...globalCss.bodyFont,
        ...Platform.select({
            web: {
                outlineColor:"#ffffff00",
                marginTop:'auto',
                marginBottom:8,
            }
        }),
        
    },lable:{
        //color:'#BFBCCA',
        fontSize:14,
        ...globalCss.bodyFont
    },nxtBtn:{
        marginTop:10,
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#2A296A',
        width:'80%',
        height:(((winHeight*3)/5)-50)/7,
        //marginTop:20
    },navBar:{
        position:'absolute',
        top:0,
        left:0,
        flex:1
    }
})
class ForgotPasswordView extends Component{
    state={
        kocId:'',
        civilId:'',
        loading:false
    }
    _sendLinkToMail=()=>{
        const {kocId,civilId}=this.state;
        this.setState({loading:true},async()=>{
            let resp=await api.post('users/forgot_password',{koc_id:kocId,civil_id:civilId});
            console.log(resp);
            if(_checkStatus(resp)){
                resp=resp.value;
                this.setState({loading:false},()=>{
                    _goto(this.props,'resetpassword',undefined,{email:resp.email});
                })
            }else{
                this.setState({loading:false})
            }
        })
    }
    render(){
        const {kocId,civilId,loading}=this.state
        return(
            <View style={globalCss.parentCont}>
                <View style={globalCss.mainCont}>
                    <View style={css.main}>
                        <ImageBackground style={css.imageCont} source={require('./images/forgotpassword_bg2.jpg')}>
                            {Platform.OS!='web'?
                                <View style={css.navBar}>
                                    <MobileNav title={t('forgot_password')} color={'transparent'} style={{flex:1,paddingTop:30}} backButtonStyles={{marginHorizontal:20}} hideButton={true}/>
                                </View>
                                :null
                            }
                            <Image style={{width:100,height:100}} source={require('./images/logo.png')}/>
                        </ImageBackground>
                        <View style={css.contentCont}>
                            <View style={css.card}>
                                <ScrollView contentContainerStyle={css.cardItemCont}>
                                    <View style={css.cardbisector}>
                                        <CustomText style={css.forgot} name={'forgot_password'}/>
                                        <Image style={css.forgotImg} source={require('./images/forgotpassword_img.png')}/>
                                        <CustomText style={css.forgotDesc} name={'send_email_text_on_registered_emailid'}/>
                                    </View>
                                    <View style={css.cardbisector}>
                                        <View style={css.inputBoxCont}>
                                            <CustomText style={css.lable} name={'koc_id'}/>
                                            <TextInput
                                                value={kocId}
                                                onChangeText={(kocId)=>{this.setState({kocId})}}
                                                keyboardType={'numeric'}
                                                style={css.input}
                                            />
                                        </View>
                                        <View style={css.inputBoxCont}>
                                            <CustomText style={css.lable}name={'civil_id'}/>
                                            <TextInput
                                                value={civilId}
                                                onChangeText={(civilId)=>{this.setState({civilId})}}
                                                keyboardType={'numeric'}
                                                style={css.input}
                                            />
                                        </View>
                                        <TouchableOpacity onPress={this._sendLinkToMail} disabled={kocId==''||civilId==''} style={[css.nxtBtn,globalCss.shadow,kocId==''||civilId==''?{opacity:0.5}:{opacity:1}]}>
                                            {loading?
                                                <AppLoading color={'#fff'} />
                                                :<CustomText style={{color:'#fff',...globalCss.bodyFontFamily}} name={'send_code'}/>
                                            }
                                        </TouchableOpacity>
                                    </View>
                                </ScrollView>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}
export default class ForgotPassword extends Component{
    render(){
        return <ForgotPasswordView {...this.props}/>
        // if(Platform.OS!='web')
        //     return (<ForgotPasswordView {...this.props}/>)
        // else return (<Page {...this.props} DynamicComponent={ForgotPasswordView}/>)
    }
}