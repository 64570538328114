import React,{Component} from 'react';
import {
    Animated,View,ActivityIndicator,Image
} from 'react-native';
import ImageModal from 'react-native-image-modal';
export default class ImageLoader extends Component{
    render(){
        const {enableZoom,source,height}=this.props;
        const defaultImage=require('../images/imageLoaderLogo.png');
        if(enableZoom){
            return(
                <View style={{height:height}}>
                    <View style={{marginLeft:'auto',marginRight:'auto',marginBottom:'auto',marginTop:'auto'}}>
                        <ImageModal
                            resizeMode={'contain'}
                            imageBackgroundColor="transparent"
                            style={{
                                height:250,
                                width: 250,
                            }}
                            source={source}
                        />
                    </View>
                </View>
            )
        }else return(
            <Image
                {...this.props}
                defaultSource={defaultImage}
            />
        )
    }
}