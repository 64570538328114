import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  StyleSheet,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Button,
  I18nManager,
} from "react-native";
import ImageLoader from "./components/ImageLoader";
import globalCss, { winWidth, winHeight } from "./styles/Styles";
import * as ImagePicker from "expo-image-picker";
import Constants from "expo-constants";
import * as Permissions from "expo-permissions";
import AppLoading from "./components/AppLoading";
import {
  api,
  _checkStatus,
  localStore,
  _logout,
  _goto,
  gotoRoute,
} from "./service";
import CustomText from "./components/CustomText";
import { t } from "./langs";
import { _appCurrentLang } from "./constants";
if (Platform.OS == "web") {
  var Page = require("./web/PageWithHeaderFooter").Page;
}
const css = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: "#EEEDF0",
  },
  top: {
    flex: 1.5,
    justifyContent: "center",
    alignItems: "center",
  },
  bottom: {
    flex: 2,
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  scroll: {
    backgroundColor: "#fff",
    borderRadius: 20,
    flexGrow: 1,
    padding: Platform.select({ web: 20, default: 20 }),
  },
  imgCont: {
    width: Platform.select({ web: 150, default: 100 }),
    height: Platform.select({ web: 150, default: 100 }),
    justifyContent: "center",
    alignItems: "center",
  },
  imgBack: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    ...(I18nManager.isRTL && { transform: [{ rotate: "180deg" }] }),
    //justifyContent:'center',alignItems:'center'
  },
  mainImg: {
    position: "absolute",
    top: Platform.select({ web: 13, default: 10 }),
    left: 0,
    //right:10,
    bottom: 0,
    width: "82%",
    height: "82%",
    zIndex: 2,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor:'red'
  },
  backImg: {
    width: "100%",
    height: "100%",
    //resizeMode:'contain'
  },
  profileIcon: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    //resizeMode:'contain',
    borderRadius: Platform.select({ web: 70, default: 41 }),
  },
  itemCont: {
    flex: 1,
    height: Platform.select({ web: 50, default: 60 }),
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  itemIcon: {
    width: 20,
    height: 20,
    resizeMode: "contain",
    flex: 1,
  },
  itemLabel: {
    flex: 5,
    paddingLeft: 10,
    color: "#24255E",
    ...globalCss.bodyFontFamily,
  },
  name: {
    fontWeight: "bold",
    margin: 0,
    fontSize: 16,
    color: "#24255E",
    ...globalCss.headingFontFamily,
  },
  desig: {
    margin: 0,
    color: "#24255E",
    ...globalCss.bodyFontFamily,
  },
});
class ProfileView extends Component {
  state = {
    user: {},
    profileImageUploader: false,
    permissions: false,
  };
  _allItems = [
    {
      icon: require("./images/ompanyid.png"),
      label: t("digital_card"),
      press: () => {
        Platform.OS == "ios" || Platform.OS == "android"
          ? gotoRoute({ scene: "idcard" })
          : _goto(this.props, "idcard");
      },
    },
    {
      icon: require("./images/Date-of-Joining.png"),
      label: t("work_calender"),
      press: () => {
        Platform.OS == "ios" || Platform.OS == "android"
          ? gotoRoute({ scene: "calender" })
          : _goto(this.props, "calender");
      },
    },
    {
      icon: require("./images/ntification.png"),
      label: t("notifications"),
      press: () => {
        Platform.OS == "ios" || Platform.OS == "android"
          ? gotoRoute({ scene: "notifications" })
          : _goto(this.props, "notifications");
      },
    },
    {
      icon: require("./images/loginpassword.png"),
      label: t("reset_password"),
      press: () => {
        Platform.OS == "ios" || Platform.OS == "android"
          ? gotoRoute({ scene: "resetpassword" })
          : _goto(this.props, "resetpassword");
      },
    },
    {
      icon: require("./images/logout_2.png"),
      label: t("logout"),
      press: () => {
        _logout(null, this.props);
      },
    },
  ];
  async componentDidMount() {
    this._getOwnUser();
    this.getPermissionAsync();
  }
  getPermissionAsync = async () => {
    if (Constants.platform.ios || Constants.platform.android) {
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
      this.setState({ permission: status === "granted" });
      if (status !== "granted") {
        alert(t("sorry_camera_permission"));
      }
    }
  };
  _updateUserProfilePicture = async () => {
    const { image } = this.state;
    let formData;
    if (Platform.OS == "web") {
      formData = this._creatFormData(image, { _method: "PUT" });
    } else {
      formData = this._creteMobFormData(image, { _method: "PUT" });
    }
    this.setState({ profileImageUploader: true });
    let userId = await localStore.get("userId");
    if (_checkStatus(userId)) {
      userId = userId.value;
      let result = await api.formdata("users/" + userId, formData, true);
      if (_checkStatus(result)) {
        alert(t("profile_picture_updated"));
        const { user } = this.state;
        user.image = image.uri;
        this.setState({ user, profileImageUploader: false });
        //this._getOwnUser();
      }
    }
  };
  _creteMobFormData(result, body) {
    let localUri = result.uri;
    let filename = localUri.split("/").pop();

    // Infer the type of the image
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;

    // Upload the image using the fetch and FormData APIs
    let formData = new FormData();
    // Assume "photo" is the name of the form field the server expects
    formData.append("image", { uri: localUri, name: filename, type });
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    return formData;
  }
  _creatFormData = (file, body) => {
    const data = new FormData();
    if (Object.keys(file).length >= 4) {
      console.log(file, "image file");
      const mimeType = file.uri.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      data.append(
        "image",
        this.b64toBlob(
          file.uri.replace(`data:${mimeType};base64,`, ""),
          mimeType
        )
      );
    }
    Object.keys(body).forEach((key) => {
      data.append(key, body[key]);
    });
    return data;
  };
  b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  _pickImage = async () => {
    try {
      debugger;
      if (this.state.permission || Platform.OS == "web") {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
        if (!result.cancelled) {
          if (Platform.OS != "web" && result.fileSize / 1024 > 2048) {
            alert(t("image_size"));
          } else if (Platform.OS == "web") {
            let image = result.uri;
            //console.log(this.image);
            let base64Str = image.split(",");
            if (this._calculateImageSize(base64Str[1]) > 2048) {
              alert(t("image_size"));
            } else {
              this.setState({ image: result });
            }
          } else {
            this.setState({ image: result });
          }
          this._updateUserProfilePicture();
        }

        console.log(result);
      } else {
        debugger;
        this.getPermissionAsync();
      }
    } catch (E) {
      console.log(E);
    }
  };
  _calculateImageSize(base64String) {
    let padding, inBytes, base64StringLength;
    if (base64String.endsWith("==")) padding = 2;
    else if (base64String.endsWith("=")) padding = 1;
    else padding = 0;

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    console.log(inBytes);
    this.kbytes = inBytes / 1000;
    return this.kbytes;
  }
  _getOwnUser = async () => {
    let userId = await localStore.get("userId");
    console.log(userId);
    if (_checkStatus(userId)) {
      userId = userId.value;
      let user = await api.get("users/" + userId, true);
      console.log(JSON.parse(JSON.stringify(user)));
      if (_checkStatus(user)) {
        user = user.value.user;
        console.log(JSON.parse(JSON.stringify(user)));
        user = {
          title: _appCurrentLang == "ar" ? user.arabic_name : user.name,
          image:
            user.image ||
            "https://upload.wikimedia.org/wikipedia/commons/4/48/Outdoors-man-portrait_%28cropped%29.jpg",
          designation:
            _appCurrentLang == "ar"
              ? user.arabic_designation
              : user.designation,
        };
        console.log(user);
        this.setState({ user });
      }
    }
  };
  _item = (item, index) => {
    return (
      <View key={index} style={css.itemCont}>
        <Image style={css.itemIcon} source={item.icon} />
        <CustomText style={css.itemLabel} value={item.label} />
        <TouchableOpacity
          disabled={typeof item.press != "function"}
          onPress={item.press}
        >
          <Image
            style={[css.itemIcon, { transform: [{ rotate: "180deg" }] }]}
            source={require("./images/arrow.png")}
          />
        </TouchableOpacity>
      </View>
    );
  };
  imageLoadError = (e) => {
    console.log(e, "image load error");
  };
  render() {
    const Items = this._allItems;
    const { user, profileImageUploader } = this.state;
    return (
      <View style={css.main}>
        <View style={css.top}>
          <View style={css.imgCont}>
            <View style={css.imgBack}>
              <Image
                style={css.backImg}
                source={require("./images/half-circle_white.png")}
              />
            </View>
            {profileImageUploader ? (
              <AppLoading />
            ) : (
              <TouchableOpacity onPress={this._pickImage} style={css.mainImg}>
                {user.image == undefined || user.image == "" ? (
                  <Image
                    style={css.profileIcon}
                    source={require("./images/upload_photo_defulticon.png")}
                  />
                ) : (
                  <ImageLoader
                    onError={this.imageLoadError}
                    resizeMode={"cover"}
                    style={css.profileIcon}
                    source={{ uri: `${user.image}` }}
                  />
                )}
              </TouchableOpacity>
            )}
          </View>
          <CustomText style={css.name} value={user.title} />
          <CustomText style={css.desig} value={user.designation} />
        </View>
        <View style={css.bottom}>
          <ScrollView
            contentContainerStyle={css.scroll}
            showsVerticalScrollIndicator={false}
          >
            {Items.map((item, index) => {
              return this._item(item, index);
            })}
          </ScrollView>
        </View>
      </View>
    );
  }
}
export default class Profile extends Component {
  render() {
    if (Platform.OS != "web") return <ProfileView {...this.props} />;
    else return <Page {...this.props} DynamicComponent={ProfileView} />;
  }
}
