// import I18n from 'ex-react-native-i18n';
import i18n from 'i18n-js';
import * as Localization from 'expo-localization';
import { I18nManager } from "react-native";
//import {Restart} from 'fiction-expo-restart';
//import RNRestart from 'react-native-restart';
import * as Updates from 'expo-updates';

import ar from "./ar.json";
import en from "./en.json"; 
import { localStore, _checkStatus } from '../service/index.js';
import { Platform } from '@unimodules/core';
import { _setAppCurrentLang } from '../constants/index.js';

i18n.translations={
    en,
    ar
}
//i18n.locale=Localization.locale.split('-')[0];
const languageRestart = async () => {
    //changing language based on what was chosen;
    //if(Platform.OS=='android'||Platform.OS=='ios'){
        let lang=await localStore.get('@appLang');
        console.log(lang,'lang restart');
        debugger;
        if(_checkStatus(lang)){
            if(lang.value=='en'){
                await localStore.set('@appLang','ar');
                await I18nManager.forceRTL(true);
            }else{
                await localStore.set('@appLang','en');
                await I18nManager.forceRTL(false);
                
            }
        }else{
            await localStore.set('@appLang','ar');
            await I18nManager.forceRTL(true);
        }
        console.log('updated Called');
        if(Platform.OS=='android'||Platform.OS=='ios'){
            Updates.reloadAsync();
        }else{
            //i18n.locale
           window.location.reload(true);
           

        }
        //Updates.reloadAsync();
    //}
};
const _setLocaleForApp=async(isWeb)=>{
    try{
        let lang=await localStore.get('@appLang');
        if(_checkStatus(lang)){
            i18n.locale=lang.value;
        }else{
            let temp=Localization.locale.split('-')[0];
            if(temp=='en'||temp=='ar'){
                i18n.locale=temp;
            }else{
                //alert('default language selection to english');
                i18n.locale='en';
            }
        }
        _setAppCurrentLang(i18n.locale);
        await localStore.set('@appLang',i18n.locale);
        isWeb===true&&I18nManager.forceRTL(i18n.locale=='ar');
        i18n.fallbacks = true;
    }catch(e){
        console.log('Unable to get locale');
    }
}
Platform.OS=='web'&&_setLocaleForApp(true);
// const _getLangauge=async()=>{
//     try{
//         let lang=await localStore.get('@appLang');
//         if(_checkStatus(lang)){
//             i18n.locale=lang.value;
//         }else{
//             i18n.locale='ar';
//         }
//         i18n.fallbacks = true;
//     }catch(e){
//         console.log('Unable to get locale');
//     }
// }
// _getLangauge();

function t(name){
    return i18n.t(name);
}
export {
    t,
    languageRestart,
    _setLocaleForApp
}