import React,{Component}from 'react';
import { 
  StyleSheet,
  View,
  ScrollView,
  Platform,  
} from 'react-native';
if(Platform.OS=='web'){
    var Page=require('./web/PageWithHeaderFooter').Page;
}
export default class ContactUs extends Component{
    render(){
      if(Platform.OS=='web'){
        return(<Page {...this.props} DynamicComponent={ContactUsView}/>)
      }else return(<ContactUsView/>)
    }
}
class ContactUsView extends Component{
    render(){
        return(
            <View>
            
            </View>
        )
    }
}