import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { Actions } from "react-native-router-flux";
import {
  NavRightButtonForDirectors,
  NavRightButtonForCalender,
} from "../DrawerMenu";
import globalCss from "../styles/Styles";
import CustomText from "./CustomText";
import { Platform } from "@unimodules/core";
export default function MobileNav(props) {
  return (
    <View
      style={[
        {
          paddingTop: 10,
          height: 80,
          backgroundColor: props.color || "#24255E",
          flexDirection: "row",
          justifyContent: "space-between",
        },
        props.style || {},
      ]}
    >
      <TouchableOpacity
        onPress={Actions.pop}
        style={[{ marginLeft: 20 }, props.backButtonStyles || {}]}
      >
        <Image
          style={{
            height: 25,
            width: 22,
            resizeMode: "contain",
          }}
          source={
            props.icon
              ? props.icon
              : Platform.OS == "ios"
              ? require("../images/ios_back.png")
              : require("../images/back.png")
          }
        />
      </TouchableOpacity>
      <CustomText
        style={{
          fontSize: 19,
          color: "#ffffff",
          ...globalCss.headingFontFamily,
        }}
        value={props.title}
      />
      {props.hideButton ? null : (
        <View style={{ marginRight: 0 }}>
          {props.calender ? (
            <NavRightButtonForCalender />
          ) : (
            <NavRightButtonForDirectors />
          )}
        </View>
      )}
    </View>
  );
}
