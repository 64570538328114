import AsyncStorage from "@react-native-community/async-storage";
import { Platform, BackHandler, ToastAndroid, Alert } from "react-native";
import {
  baseUrl,
  _testImage,
  authRequiredScenes,
  _appCurrentLang,
  _storagePermission,
  _setStoragePermission,
} from "../constants";
import { Actions, ActionConst } from "react-native-router-flux";
import * as MediaLibrary from "expo-media-library";
import * as FileSystem from "expo-file-system";
import * as Permissions from "expo-permissions";
import { t } from "../langs";
const localStore = {
  set: async (key, value) => {
    try {
      await AsyncStorage.setItem(key, value);
      return { status: true, value: {} };
    } catch (e) {
      return { status: false, value: { error: e } };
    }
  },
  get: async (key) => {
    try {
      const val = await AsyncStorage.getItem(key);
      if (val != null) {
        return { status: true, value: val };
      } else {
        return { status: false, value: { error: val } };
      }
    } catch (e) {
      return { status: false, value: { error: e } };
    }
  },
  delete: async (key) => {
    try {
      await AsyncStorage.removeItem(key);
      return { status: true, value: {} };
    } catch (e) {
      return { status: false, value: { error: e } };
    }
  },
};
const api = {
  get: async (route, isAuthenticationRequired) => {
    try {
      if (isAuthenticationRequired === true) {
        var Token = await localStore.get("accessToken");
        if (_checkStatus(Token)) {
          Token = Token.value;
        } else {
          return {
            status: false,
            value: { error: e, message: "Unauthenticated" },
          };
        }
      }
      let result = await fetch(baseUrl + "/" + route, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...(isAuthenticationRequired && {
            Authorization: `${"Bearer " + Token}`,
          }),
        },
      });
      result = await result.json();
      return { status: true, value: result };
    } catch (e) {
      console.log(e);
      return { status: false, value: { error: e } };
    }
  },
  post: async (route, body, isAuthenticationRequired) => {
    try {
      if (isAuthenticationRequired === true) {
        var Token = await localStore.get("accessToken");
        if (_checkStatus(Token)) {
          Token = Token.value;
        } else {
          return {
            status: false,
            value: { error: e, message: "Unauthenticated" },
          };
        }
      }
      let result = await fetch(baseUrl + "/" + route, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...(isAuthenticationRequired && {
            Authorization: `${"Bearer " + Token}`,
          }),
        },
        body: JSON.stringify(body),
      });
      result = await result.json();
      return { status: true, value: result };
    } catch (e) {
      return { status: false, value: { error: e } };
    }
  },
  formdata: async (route, body, isAuthenticationRequired) => {
    if (isAuthenticationRequired === true) {
      var Token = await localStore.get("accessToken");
      if (_checkStatus(Token)) {
        Token = Token.value;
      } else {
        return {
          status: false,
          value: { error: e, message: "Unauthenticated" },
        };
      }
    }
    let apiObj = {};
    apiObj.method = "POST";
    apiObj.body = body;
    apiObj.headers = {
      ...(isAuthenticationRequired && {
        Authorization: `${"Bearer " + Token}`,
      }),
    };
    try {
      let result = await fetch(baseUrl + "/" + route, apiObj);
      //console.log(result,apiObj,body);
      result = await result.json();
      return { status: true, value: result };
    } catch (e) {
      console.log(e, apiObj, body);
      return { status: false, value: { error: e } };
    }
  },
};
const _checkStatus = (obj) => {
  if (obj.status) {
    if (Object.keys(obj.value).indexOf("error") == -1) {
      return true;
    } else {
      if (Object.keys(obj.value).indexOf("message") != -1) {
        alert(obj.value.message);
      } else if (
        Object.keys(obj.value).indexOf("error") != -1 &&
        typeof obj.value.error == "string"
      ) {
        if (Object.keys(obj.value).indexOf("message") != -1) {
          alert(obj.value.message);
        } else {
          alert(obj.value.error);
        }
      }
      return false;
    }
  } else {
    if (Object.keys(obj.value).indexOf("error") != -1) {
      if (Object.keys(obj.value).indexOf("message") != -1) {
        alert(obj.value.message);
      } else if (obj.value.error != null) alert(obj.value.error);
    }
    return false;
  }
};
const _getWeekDay = (date) => {
  let number;
  if (typeof date == "string") {
    number = new Date(date.replace(" ", "T")).getUTCDay();
  } else {
    number = new Date(date).getUTCDay();
  }
  //let number=new Date(date.replace(' ', 'T')).getUTCDay();
  return [
    t("sunday"),
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
  ][number];
};
const numberConversion = (str) => {
  if (_appCurrentLang == "ar") {
    str = str + "";
    var id = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    return str.replace(/[0-9]/g, function (w) {
      return id[+w];
    });
  } else return str;
};
const _parseDate = (date) => {
  //console.log(date);
  let temp;
  if (typeof date == "string") {
    temp = new Date(date.replace(" ", "T"));
  } else {
    temp = new Date(date);
  }
  return (
    numberConversion(temp.getDate()) +
    " " +
    _getMonth(date) +
    " " +
    numberConversion(temp.getFullYear())
  );
};
const _getMonth = (date) => {
  let number;
  if (typeof date == "string") {
    number = new Date(date.replace(" ", "T")).getMonth();
  } else {
    number = new Date(date).getMonth();
  }
  return [
    Platform.OS == "web" ? t("January") : t("Jan"),
    Platform.OS == "web" ? t("February") : t("Feb"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    Platform.OS == "web" ? t("September") : t("Sep"),
    Platform.OS == "web" ? t("October") : t("Oct"),
    Platform.OS == "web" ? t("November") : t("Nov"),
    Platform.OS == "web" ? t("December") : t("Dec"),
  ][number];
};
const _getTime = (date) => {
  if (typeof date == "string") {
    return _formatAMPM(new Date(date.replace(" ", "T")));
  } else {
    return _formatAMPM(new Date(date));
  }
};
const _formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
const _parseHtml = (html) => {
  html = html.replace(/<style([\s\S]*?)<\/style>/gi, "");
  html = html.replace(/<script([\s\S]*?)<\/script>/gi, "");
  html = html.replace(/<\/div>/gi, "\n");
  html = html.replace(/<\/li>/gi, "\n");
  html = html.replace(/<li>/gi, "  *  ");
  html = html.replace(/<\/ul>/gi, "\n");
  html = html.replace(/<\/p>/gi, "\n");
  html = html.replace(/<br\s*[\/]?>/gi, "\n");
  html = html.replace(/<[^>]+>/gi, "");
  return html;
};
const _goto = (props, scene, type, sceneProps) => {
  if (Platform.OS == "web") {
    let state = "/" + scene;
    if (props.history.location.pathname.split("/")[1] != scene) {
      if (sceneProps != undefined && typeof sceneProps == "object") {
        Object.keys(sceneProps).forEach((key) => {
          state = state + "/" + sceneProps[key];
        });
      }
      if (type != undefined) {
        props.history[type](state);
      } else props.history.push(state);
    }
  } else {
    Actions.drawermenu({ type: ActionConst.RESET });
    if (sceneProps != undefined) {
      Actions.push(scene, sceneProps);
    } else Actions.push(scene);
  }
};
const _goBack = (props) => {
  if (Platform.OS == "android" || Platform.OS == "ios") {
    Actions.pop();
  } else {
    props.history.back();
  }
};
var backPress = 0;
const _handleBackPress = (tabItems, item, renderNextScreen) => {
  var promise = new Promise(function (resolve, reject) {
    let updatedTabs;
    let prevScene = Actions.currentScene;
    setTimeout(() => {
      let currentScene = Actions.currentScene;
      if (currentScene.includes("details")) {
        currentScene = currentScene.slice(0, -7);
      }
      let currentSceneObj = tabItems.find((v) => {
        return v.scene == currentScene;
      });
      if (currentSceneObj) {
        if (
          (prevScene == "home" || prevScene == "drawermenu") &&
          prevScene == currentScene
        ) {
          if (backPress > 0) {
            BackHandler.exitApp();
            backPress = 0;
          } else {
            backPress++;
            ToastAndroid.show("Press again to exit", ToastAndroid.SHORT);
            setTimeout(() => {
              backPress = 0;
            }, 1000);
          }
        }
        updatedTabs = _iconClicked(tabItems, currentSceneObj, renderNextScreen);
        resolve(updatedTabs);
      }
    }, 5);
  });
  return promise;
};
const _iconClicked = (tabItems, item, renderNextScreen) => {
  for (let index = 0; index < tabItems.length; index++) {
    if (tabItems[index].scene == item.scene) {
      tabItems[index].active = true;
    } else {
      tabItems[index].active = false;
    }
  }
  if (renderNextScreen) {
    gotoRoute(item);
  }
  return tabItems;
};
const gotoRoute = (item) => {
  if (
    Actions.currentScene == item.scene ||
    (Actions.currentScene == "_home" && item.scene == "drawermenu")
  ) {
    return null;
  } else {
    if (item.scene == "home") {
      Actions.reset(item.scene);
      //return null;
    } else item.scene != "" && Actions.push(item.scene);
  }
};
const _logout = (callback, props) => {
  if (Platform.OS == "android" || Platform.OS == "ios") {
    Alert.alert("Logout", "Are you sure you want to logout?", [
      {
        text: "Cancel",
        onPress: () => console.log("Cancel Pressed"),
        style: "cancel",
      },
      { text: "OK", onPress: () => _logoutConfirm(callback) },
    ]);
  } else {
    if (confirm("Are you sure you want to logout?") == true) {
      _logoutConfirm(callback, props);
    }
  }
};
const _logoutConfirm = async (callback, props) => {
  let resp = await api.post("users/logout", {}, true);
  if (_checkStatus(resp)) {
    let removeToken = await localStore.delete("accessToken");
    let userId = await localStore.delete("userId");
    if (_checkStatus(removeToken) && _checkStatus(userId)) {
      if (typeof callback == "function") {
        callback();
      }
      _checkUserCurrentScene(props);
    }
  } else {
    let removeToken = await localStore.delete("accessToken");
    let userId = await localStore.delete("userId");
    if (_checkStatus(removeToken) && _checkStatus(userId)) {
      if (typeof callback == "function") {
        callback();
      }
      _checkUserCurrentScene(props);
    }
  }
};
const _checkUserCurrentScene = (props) => {
  if (Platform.OS == "android" || Platform.OS == "ios") {
    let currentScene = Actions.currentScene;
    if (authRequiredScenes.includes(currentScene)) {
      _goto("home");
    }
  } else {
    console.log(props);
    let currentScene = props.history.location.pathname.split("/")[1];
    if (authRequiredScenes.includes(currentScene)) {
      _goto(props, "home", "replace");
    }
  }
};
const _setStyle = (phone, web) => {
  return Platform.select({ android: phone, ios: phone, default: web });
};
const _downloadFileInMobile = async (fileUrl, fileName) => {
  if (_storagePermission === true) {
    const file = await FileSystem.downloadAsync(
      fileUrl,
      FileSystem.documentDirectory + (fileName || "KOC" + Date.now() + ".jpg")
    );
    try {
      const assetLink = await MediaLibrary.createAssetAsync(file.uri);
      console.log(file, assetLink);
      alert(t("file_downloaded"));
      return true;
    } catch (e) {
      console.log(e, "while create image file");
    }
  } else {
    let resp = await _getCameraRollPermission();
    console.log(resp, "camera permission response");
    if (resp) {
      _setStoragePermission(resp);
      const file = await FileSystem.downloadAsync(
        fileUrl,
        FileSystem.documentDirectory + (fileName || "KOC" + Date.now() + ".jpg")
      );
      console.log(file, "file before downloading");
      try {
        const assetLink = await MediaLibrary.createAssetAsync(file.uri);
        console.log(file, assetLink);
        alert(t("file_downloaded"));
        return true;
      } catch (e) {
        console.log(e, "while create image file");
      }
    } else {
      alert(t("storage_permission"));
      return false;
    }
  }
};
const _getCameraRollPermission = async () => {
  let { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
  console.log(status, "camera roll permission");
  return status === "granted";
};
export {
  localStore,
  api,
  _checkStatus,
  _getWeekDay,
  _testImage,
  _parseDate,
  _parseHtml,
  _goto,
  _handleBackPress,
  _iconClicked,
  _getTime,
  _logout,
  gotoRoute,
  _goBack,
  _setStyle,
  _downloadFileInMobile,
};
