import React,{Component}from 'react';
import { 
  StyleSheet,
  View,
  Platform
} from 'react-native';
import {WebFooter} from "./webFooter";
import {WebHeader} from "./WebHeader";
import {DrawerMenu} from "../DrawerMenu";
import globalCss,{winHeight} from "../styles/Styles";
const css=StyleSheet.create({
    centerCont:{
        flex:8,
        backgroundColor:'#EEEDF0',
    },textStyle:{
        fontSize:10,
        color:'#fff',
        fontWeight:'bold',
        ...globalCss.headingFontFamily
    },drawerCont:{
        position:'absolute',
        top:0,
        right:0,
        zIndex:2,
    }
})
class Page extends Component{
    state={
        showMenu:false
    }
    changeVisibility=()=>{
        this.setState({showMenu:!this.state.showMenu});
    }
    render(){
        const {DynamicComponent}=this.props;
        return(
            <View style={[globalCss.parentCont,{height:winHeight*1.5}]}>
                <View style={globalCss.mainCont}>
                    {
                        Platform.OS=='web'&&this.state.showMenu?
                        <View style={css.drawerCont}>
                            <DrawerMenu {...this.props} visibility={this.changeVisibility}/>
                        </View>
                        :null
                    }
                    <WebHeader {...this.props} height={winHeight*1.5} changeVisibility={this.changeVisibility}/>
                    <View style={css.centerCont}>
                       <DynamicComponent {...this.props}/>
                    </View>
                    <WebFooter textStyle={css.textStyle}/>
                </View>
            </View>
        )
    }
}
export {Page}